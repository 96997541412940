import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Popover } from "@mui/material";
import { RiArrowDownSLine, RiSearchLine } from "react-icons/ri";
import { RxCalendar } from "react-icons/rx";
import { BsImage } from "react-icons/bs";
import { MdArrowBack } from "react-icons/md";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { convertUTCDatetoLocalDateWithDay, SignUserOut } from "../utils";
import { InvestigationsAPI } from "../middleware";
import { Investigations } from "../utils/types";
import MobileSearchbar from "../components/MobileSearchbar";
import Loader from "../components/Loader";
import { useQuery } from "@tanstack/react-query";

const fetchInvestigations: any = (access_token: string, healthFacilityId: string, patientId: string) =>
  InvestigationsAPI.getInvestigations(access_token, healthFacilityId, patientId);

const LabInvestigations = () => {
  const { isSignedIn, user } = useReduxSelector((state: any) => state.user);
  const { access_token, corporateHealthFacility } = user;
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const pathname = window.location.pathname;
  const [view, setView] = React.useState<string>("Lab Investigations");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [filteredInvestigations, setFilteredInvestigations] = React.useState<Investigations[]>([]);
  const [allInvestigations, setAllInvestigations] = React.useState<Investigations[]>([]);
  const [labResults, setLabResults] = React.useState<Investigations[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const patientId = user.user.patient.patientId;
  const healthFacilityId = corporateHealthFacility.id;

  const { data, isLoading } = useQuery({
    queryKey: ["investigations", patientId],
    queryFn: () => fetchInvestigations(access_token, healthFacilityId, patientId),
  });
  useEffect(() => {
    if (data) {
      console.log({ data });
      setAllInvestigations(data?.data?.data); // Set the fetched data to allInvestigations state
    }
  }, [data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function ascendingOrder(arr: Investigations[]) {
    arr.sort((a, b) => a.labTestName.toLowerCase().localeCompare(b.labTestName.toLowerCase()));
    if (view === "Lab Investigations") {
      setAllInvestigations(arr);
    }
    return arr;
  }

  function descendingOrder(arr: Investigations[]) {
    arr.sort((a, b) => b.labTestName.toLowerCase().localeCompare(a.labTestName.toLowerCase()));
    if (view === "Lab Investigations") {
      setAllInvestigations(arr);
    }
    return arr;
  }

  const search = (investigations: Investigations[], query: string): Investigations[] => {
    setSearchTerm(query);
    return investigations.filter(
      (investigation) =>
        investigation.labTestName.toLowerCase().includes(query) ||
        investigation.specialistId.toLowerCase().includes(query) ||
        investigation.labTestId.toLowerCase().includes(query)
    );
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const lowerCaseValue = value.toLowerCase();
    setSearchTerm(lowerCaseValue);
    const filtered = allInvestigations.filter((investigation) => {
      return (
        investigation.labTestName.toLowerCase().includes(lowerCaseValue) ||
        investigation.specialistId.toLowerCase().includes(lowerCaseValue) ||
        investigation.labTestId.toLowerCase().includes(lowerCaseValue)
      );
    });
    setFilteredInvestigations(filtered);
  };

  return (
    <>
      {isSignedIn === true && pathname === "/lab-investigations" ? (
        <Layout
          pathname={pathname}
          search={search}
          view={view}
          searchTerm={searchTerm}
          allInvestigations={allInvestigations}
          setFilteredInvestigations={setFilteredInvestigations}
        >
          <div>
            <MobileSearchbar searchTerm={searchTerm} view={view} handleSearch={handleSearch} />

            <div className="flex w-full justify-end">
              <div className="flex items-center space-x-3">
                <button className="flex space-x-3 items-center" onClick={handleClick}>
                  <img src="/assets/filter.svg" alt="filter" width={30} />
                  <p className="underline text-primary-100 text-sm hidden md:block">Sort by</p>
                  <RiArrowDownSLine className="hidden md:block" />
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="flex flex-col rounded-md shadow">
                    <button
                      onClick={() => {
                        if (view === "Lab Investigations") {
                          ascendingOrder(allInvestigations);
                        }
                        handleClose();
                      }}
                      className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
                    >
                      A-Z
                    </button>
                    <button
                      onClick={() => {
                        if (view === "Lab Investigations") {
                          descendingOrder(allInvestigations);
                        }
                        handleClose();
                      }}
                      className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
                    >
                      Z-A
                    </button>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          {view === "Lab Investigations" && (
            <div className="md:fixed h-[78%] mt-3 flex justify-center w-full">
              <div className="overflow-y-scroll relative max-h-[95%] flex flex-col w-full">
                <div className="flex flex-col flex-wrap md:grid items-center justify-center grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mb-3 md:mb-0">
                  {isLoading ? (
                    <div className="h-[70vh] flex flex-row justify-center items-center w-[80vw]">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {allInvestigations.length === 0 ? (
                        <div className="flex w-screen">
                          <p className="text-red-500 text-base">
                            No Investigations Found
                          </p>
                        </div>
                      ) : (
                        (searchTerm ? filteredInvestigations : allInvestigations).map((item, key) => (
                          <div
                            className="flex flex-col justify-center items-center bg-[#F2E4EC] rounded-3xl px-4 py-4 w-full h-[350px] md:w-[220px] md:h-[320px] xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95 text-[#98256B]"
                            key={key}
                          >
                            <img
                              src={"/assets/clipboard.svg"}
                              alt={"lab investigations"}
                              className="w-[150px] md:w-[120px] rounded-full"
                            />
                            <div className="flex flex-col items-start">
                              <p className="text-xl font-bold mt-2 tracking-wide">
                                {item.specialistId}
                              </p>
                              <p className="text-xs font-medium mt-1">
                                Test Name - {item.labTestName ?? "No Test Name"}
                              </p>
                              <p className="text-xs font-normal mt-1">
                                Lab Test ID - {item.labTestId}
                              </p>
                              <div className="flex items-center space-x-2 mt-1">
                                <RxCalendar className="text-sm" />
                                <p className="text-xs font-normal">
                                  {(item.requestDate) ?? "No Date Picked"}
                                </p>
                              </div>
                            </div>
                            <button
                              className="bg-[#98256B] text-white py-2 px-5 rounded-3xl mt-6 text-sm hover:opacity-80"
                              onClick={() => {
                                setLabResults([item]);
                                setView("Lab Results");
                              }}
                            >
                              View Results
                            </button>
                          </div>
                        ))
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {view === "Lab Results" && (
            <div className="flex justify-startw">
              <div className="flex flex-col items-start w-full space-y-4 ">
                <button
                  className="flex items-center space-x-2 "
                  onClick={() => setView("Lab Investigations")}
                >
                  <MdArrowBack className="text-2xl" />
                  <span className="text-lg">Back to Investigations</span>
                </button>
                {labResults.map((result, index) => (
                  <div
                    key={index}
                    className="bg-[#F2E4EC] rounded-2xl shadow-lg p-6 w-full text-[#98256B]"
                  >
                    <div className="flex flex-col space-y-4">
                      <p className="text-xl font-bold">{result.labTestName}</p>
                      <p>
                        <strong>Specialist ID:</strong> {result.specialistId}
                      </p>
                      <p>
                        <strong>Lab Test ID:</strong> {result.labTestId}
                      </p>
                      <p>
                        <strong>Lab Test ID:</strong> {result.requestStatusName}
                      </p>
                      <p>
                        <strong>Request Date:</strong> {(result.requestDate ?? "No Date Picked")}
                      </p>
                      {/* <div className="flex items-center space-x-2 mt-1">
                        <BsImage className="text-sm" />
                        <p className="text-xs font-normal">Lab Result Image Placeholder</p>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Layout>
      ) : null}
    </>
  );
};

export default LabInvestigations;
