import { RiSearchLine } from "react-icons/ri";

export default function MobileSearchbar({
  view,
  searchTerm,
  handleSearch,
}: any) {
  return (
    <>
      <div className="lg:hidden mb-3">
        <form className="w-full relative">
          <input
            placeholder={`Search ${
              view === "DoctorProfile" ? "Doctors" : view
            }`}
            className="border rounded-3xl w-full pl-4 pr-10 py-3 text-sm"
            type={"text"}
            value={searchTerm}
            onChange={handleSearch}
          />
          <RiSearchLine className="absolute text-lg text-[#8E8E8E] top-[14px] right-5" />
        </form>
      </div>
    </>
  );
}
