import React from 'react';
import { TiLocation } from 'react-icons/ti';

export default function LocationsTab(addressLocation: string, locationsArray: any, setAddressLocation: any, setSelectedLocation: any, dispatch: any, selectedLocation: any) {
  console.log({ locationsArray });
  return <div className="flex flex-col md:flex-row justify-center mt-4 items-center">
    <div className="w-full relative lg:flex flex-row ">
      {/* mobile view map */}
      <div className="bg-gray-50 w-full h-[300px] rounded-2xl lg:hidden block z-50">
        <iframe
          title="map"
          className="w-full h-[300px] border-none rounded-3xl"
          aria-hidden="false"
          src={`https://maps.google.com/maps?q=${encodeURI(
            addressLocation
          )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
          loading="eager"
          style={{
            filter: "grayscale(0) contrast(0.6) opacity(1)",
          }}
        ></iframe>
      </div>
      <div className="lg:w-8/12 h-[500px] overflow-y-scroll">
        <div className="overflow-y-scroll sm:h-[400px] md:h-auto space-y-3 lg:pb-0 absolute top-48 lg:top-0 lg:relative lg:z-50 z-10 lg:bg-none bg-transparent rounded-3xl w-full shadow lg:shadow-none lg:my-20 lg:py-10">
          {locationsArray?.map((item: any) => (
            <div
              key={item.id}
              className="flex items-center space-x-4 hover:bg-blue-300 focus:bg-primary-10 bg-white transition-colors duration-200 shadow-[#f0f0ff] shadow-2xl rounded-2xl py-3 px-4 cursor-pointer lg:mr-10"
              onClick={(e: any) => {
                e.preventDefault();
                setAddressLocation(`Trust Hospital ${item.name}`);

                // dispatch({
                //   type: "SET_SELECTED_LOCATION",
                //   payload: {
                //     id: item.id,
                //     name: item.name,
                //     address: item.address,
                //   },
                // });
                dispatch(
                  setSelectedLocation({
                    id: item.id,
                    name: item.name,
                    address: item.address,
                  })
                );

                console.log("SELECTED LOCATION", item);
              }}
            >
              <div className="flex space-x-4 items-center">
                <div className="bg-[#E5E5FE] rounded-full h-10 w-10 flex items-center justify-center">
                  <TiLocation className="text-2xl text-primary-100" />
                </div>
                <div>
                  <h3 className="text-primary-100">
                    {item.adress}
                  </h3>
                  <p className="text-black text-xs">
                    {item.name}
                  </p>
                </div>
              </div>
            </div>
          ))}

          {locationsArray?.length === 0 && (
            <div className="flex w-full px-3 items-center justify-center">
              <p className=" text-red-500 text-base mt-4">
                We can't find any locations
                matching that search input. Please
                try something else.
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-50 w-full h-[400px] rounded-2xl hidden lg:block lg:h-[70vh] lg:w-[100vw]">
        <iframe
          title="map"
          className=" border-none rounded-3xl w-full h-full"
          aria-hidden="false"
          src={`https://maps.google.com/maps?q=${encodeURI(
            addressLocation
          )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
          loading="eager"
        ></iframe>
      </div>
    </div>
    {/* Desktop view map */}

  </div>;
}