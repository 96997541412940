import React from "react";
import { GiPill } from "react-icons/gi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { RiSearchLine, RiLogoutCircleLine } from "react-icons/ri";
import { TbClipboardList, TbMicroscope, TbSettings } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { useReduxSelector } from "../redux/hooks";

export default function Sidebar({ pathname, handleOpen }: any) {
  const navigate = useNavigate();
  const { isSignedIn } = useReduxSelector((state) => state.user);

  return (
    <div className="border border-r border-[#E8E8E8] w-[25%] xl:w-[20%] 2xl:w-[15%] shadow-lg pt-6 flex-col items-center justify-between font-montserrat hidden lg:flex h-full">
      {/* Top side navbar content */}
      <div>
        <img
          src="/assets/trust-hospital-logo.png"
          alt="Trust hospital logo"
          width={180}
          className="mb-6 cursor-pointer"
          onClick={() => navigate("/")}
        />
        <div className="space-y-1">

          {isSignedIn && (
            <>

              <Link
                to={"/find-a-doctor"}
                className={`flex items-center space-x-3 transition-colors duration-200 ${pathname === "/find-a-doctor"
                  ? "bg-[#BBDAF7] hover:opacity-80"
                  : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                  } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
              >
                <RiSearchLine className="text-xl" />
                <p>Find a doctor</p>
              </Link>
              <Link
                to={"/visits-and-appointments"}
                className={`flex items-center space-x-3 transition-colors duration-200 ${pathname === "/visits-and-appointments"
                  ? "bg-[#BBDAF7] hover:opacity-80"
                  : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                  } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
              >
                <TbClipboardList className="text-xl" />
                <p>Visits & Appointments</p>
              </Link>
              <Link
                to={"/lab-investigations"}
                className={`flex items-center space-x-3 transition-colors duration-200 ${pathname === "/lab-investigations"
                  ? "bg-[#BBDAF7] hover:opacity-80"
                  : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                  } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
              >
                <TbMicroscope className="text-xl" />
                <p>Lab Investigations</p>
              </Link>
              <Link
                to={"/prescriptions"}
                className={`flex items-center space-x-3 transition-colors duration-200 ${pathname === "/prescriptions"
                  ? "bg-[#BBDAF7] hover:opacity-80"
                  : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                  } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
              >
                <GiPill className="text-xl" />
                <p>Prescriptions</p>
              </Link>
              {/* <Link to={"/announcements"}>
                <div
                  className={`flex items-center space-x-3 ${pathname === "/announcements"
                      ? "bg-[#BBDAF7] hover:opacity-80"
                      : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                    } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
                >
                  <HiOutlineSpeakerphone className="text-xl" />
                  <p>Announcements</p>
                </div>
              </Link> */}
            </>
          )}
        </div>
      </div>
      {/* Bottom side navbar content */}
      {/* {isSignedIn && ( */}
      <div className="w-full px-6 border border-t py-3">
        {/* <div className="flex items-center text-[#8E8E8E] space-x-3 p-3 rounded-3xl font-medium text-sm hover:cursor-pointer hover:bg-slate-50">
          <TbSettings className="text-2xl" />
          <p>Settings</p>
        </div> */}
        <button onClick={handleOpen} className="flex items-center text-[#8E8E8E] space-x-3 p-3 rounded-3xl font-medium text-sm hover:cursor-pointer hover:bg-[#BBDAF7] w-full transition-all duration-300">
          <RiLogoutCircleLine className="text-xl" />
          <div >Log Out</div>
        </button>
      </div>
      {/* )} */}
    </div>
  );
}
