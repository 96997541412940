export const Specialty = [
  {
    id: 1,
    name: "Neurology",
    image: "/assets/brain.svg",
    alt: "brain",
  },
  {
    id: 2,
    name: "Surgery",
    image: "/assets/surgery.png",
    alt: "surgery",
  },
  {
    id: 3,
    name: "Cardiologist",
    image: "/assets/heart.png",
    alt: "heart",
  },
  {
    id: 4,
    name: "Dermatology",
    image: "/assets/skin.png",
    alt: "skin",
  },
  {
    id: 5,
    name: "Dentistry",
    image: "/assets/tooth.png",
    alt: "tooth",
  },
  {
    id: 6,
    name: "Urologist",
    image: "/assets/kidneys.png",
    alt: "kidneys",
  },
  {
    id: 7,
    name: "Gynecologist",
    image: "/assets/female.png",
    alt: "female",
  },
  {
    id: 8,
    name: "Pediatrician",
    image: "/assets/child.png",
    alt: "child",
  },
  {
    id: 9,
    name: "Pediatrician",
    image: "/assets/child.png",
    alt: "child",
  },
  {
    id: 10,
    name: "Pediatrician",
    image: "/assets/child.png",
    alt: "child",
  },
  {
    id: 11,
    name: "Pediatrician",
    image: "/assets/child.png",
    alt: "child",
  },
];

export const defaultImage = "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"; // Monochrome profile image URL


// export const Doctors = [
//   {
//     id: 1,
//     name: "Klimisch J",
//     profession: "Dentist",
//     availability: "31st July 2022",
//     image: "/assets/dr1.svg",
//     alt: "Klimisch J",
//     about:
//       "Dr. Klimisch J is a top dentist at The Trust  Hospital Ghana. She has achieved several awards and recognition for is contribution and service in his own field. Shee is available for private consultation. ",
//   },
//   {
//     id: 2,
//     name: "Mensah T",
//     profession: "Dentist",
//     availability: "31st July 2022",
//     image: "/assets/dr2.svg",
//     alt: "Dr. Mensah T",
//     about:
//       "Dr. Mensah T is a top dentist at The Trust  Hospital Ghana. She has achieved several awards and recognition for is contribution and service in his own field. Shee is available for private consultation. ",
//   },
//   {
//     id: 3,
//     name: "Klimisch J",
//     profession: "Dentist",
//     availability: "31st July 2022",
//     image: "/assets/dr3.svg",
//     alt: "Dr. Klimisch J",
//     about:
//       "Dr. Klimisch J is a top dentist at The Trust  Hospital Ghana. She has achieved several awards and recognition for is contribution and service in his own field. Shee is available for private consultation. ",
//   },
//   {
//     id: 4,
//     name: "Martinez K",
//     profession: "Dentist",
//     availability: "31st July 2022",
//     image: "/assets/dr4.svg",
//     alt: "Dr. Martinez K",
//     about:
//       "Dr. Martinez K is a top dentist at The Trust  Hospital Ghana. She has achieved several awards and recognition for is contribution and service in his own field. Shee is available for private consultation. ",
//   },
//   {
//     id: 5,
//     name: "Klimisch J",
//     profession: "Dentist",
//     availability: "31st July 2022",
//     image: "/assets/dr1.svg",
//     alt: "Dr. Klimisch J",
//     about:
//       "Dr. Klimisch J is a top dentist at The Trust  Hospital Ghana. She has achieved several awards and recognition for is contribution and service in his own field. Shee is available for private consultation. ",
//   },
//   {
//     id: 6,
//     name: "Mensah T",
//     profession: "Dentist",
//     availability: "31st July 2022",
//     image: "/assets/dr2.svg",
//     alt: "Dr. Mensah T",
//     about:
//       "Dr. Mensah T is a top dentist at The Trust  Hospital Ghana. She has achieved several awards and recognition for is contribution and service in his own field. Shee is available for private consultation. ",
//   },
//   {
//     id: 7,
//     name: "Klimisch J",
//     profession: "Dentist",
//     availability: "31st July 2022",
//     image: "/assets/dr3.svg",
//     alt: "Dr. Klimisch J",
//     about:
//       "Dr. Klimisch J is a top dentist at The Trust  Hospital Ghana. She has achieved several awards and recognition for is contribution and service in his own field. Shee is available for private consultation. ",
//   },
//   {
//     id: 8,
//     name: "Martinez K",
//     profession: "Dentist",
//     availability: "31st July 2022",
//     image: "/assets/dr4.svg",
//     alt: "Dr. Martinez K",
//     about:
//       "Dr. Martinez K is a top dentist at The Trust  Hospital Ghana. She has achieved several awards and recognition for is contribution and service in his own field. Shee is available for private consultation. ",
//   },
// ];

// export const Locations = [
//   {
//     id: 1,
//     name: "Trust Hospital Osu",
//     address: "Oxford St, Accra, Ghana",
//   },
//   {
//     id: 2,
//     name: "Trust Clinic Adenta",
//     address: "Adenta Municipality, Ghana",
//   },
//   {
//     id: 3,
//     name: "Trust Specialist Hospital",
//     address: "Aosu kukuhill osu, Angola Road, Ghana",
//   },
//   {
//     id: 4,
//     name: "Trust Clinic Sakumono",
//     address: "Sakumono, Ghana",
//   },
//   // {
//   //   id: 5,
//   //   name: "Trust Mother and Child Hospital",
//   //   address: "HR4C+374, Troas Cres, Accra",
//   // },
//   // {
//   //   id: 6,
//   //   name: "Trust Hospital Premium Centre",
//   //   address: "31 Asafoanye A. Opeku Avenue, Accra",
//   // },
// ];

export const AvailableTimes = [
  {
    id: 1,
    time: "10:00 AM",
  },
  {
    id: 2,
    time: "11:30 AM",
  },
  {
    id: 3,
    time: "12:00 PM",
  },
  {
    id: 4,
    time: "01:30 PM",
  },
  {
    id: 5,
    time: "02:30 PM",
  },
  {
    id: 6,
    time: "03:00 PM",
  },
];

export const Completed = [
  {
    id: 1,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr5.svg",
    amount: 240,
  },
  {
    id: 2,
    name: "Bellamy N",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr1.svg",
    amount: 240,
  },
  {
    id: 3,
    name: "Mensah T",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr2.svg",
    amount: 240,
  },
  {
    id: 4,
    name: "Martinez K",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr3.svg",
    amount: 240,
  },
  {
    id: 5,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr5.svg",
    amount: 240,
  },
  {
    id: 6,
    name: "Bellamy N",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr1.svg",
    amount: 240,
  },
  {
    id: 7,
    name: "Mensah T",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr2.svg",
    amount: 240,
  },
  {
    id: 8,
    name: "Martinez K",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr3.svg",
    amount: 240,
  },
];

export const Appointments = [
  {
    id: 1,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr5.svg",
    status: "Pending",
  },
  {
    id: 2,
    name: "Bellamy N",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr1.svg",
    status: "Pending",
  },
  {
    id: 3,
    name: "Mensah T",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr2.svg",
    status: "Pending",
  },
  {
    id: 4,
    name: "Martinez K",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr3.svg",
    status: "Pending",
  },
  {
    id: 5,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr5.svg",
    status: "Pending",
  },
  {
    id: 6,
    name: "Bellamy N",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr1.svg",
    status: "Pending",
  },
  {
    id: 7,
    name: "Mensah T",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr2.svg",
    status: "Pending",
  },
  {
    id: 8,
    name: "Martinez K",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr3.svg",
    status: "Pending",
  },
];

export const isCancelled = [
  {
    id: 1,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr5.svg",
    status: "Cancelled",
  },
  {
    id: 2,
    name: "Bellamy N",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr1.svg",
    status: "Cancelled",
  },
  {
    id: 3,
    name: "Mensah T",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr2.svg",
    status: "Cancelled",
  },
  {
    id: 4,
    name: "Martinez K",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr3.svg",
    status: "Cancelled",
  },
  {
    id: 5,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr5.svg",
    status: "Cancelled",
  },
  {
    id: 6,
    name: "Bellamy N",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr1.svg",
    status: "Cancelled",
  },
  {
    id: 7,
    name: "Mensah T",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr2.svg",
    status: "Cancelled",
  },
  {
    id: 8,
    name: "Martinez K",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/dr3.svg",
    status: "Cancelled",
  },
];

export const Lab_Investigations = [
  {
    id: 1,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/clipboard.svg",
    buttonText: "View Results",
  },
  {
    id: 2,
    name: "Bellamy N",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/clipboard.svg",
    buttonText: "View Results",
  },
  {
    id: 3,
    name: "Mensah T",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/clipboard.svg",
    buttonText: "View Results",
  },
  {
    id: 4,
    name: "Martinez K",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/clipboard.svg",
    buttonText: "View Results",
  },
];

export const ActivePrescriptions = [
  {
    id: 1,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/pills-dark-blue.svg",
    buttonText: "View Prescription",
  },
  {
    id: 2,
    name: "Bellamy N",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/pills-dark-blue.svg",
    buttonText: "View Prescription",
  },
  {
    id: 3,
    name: "Mensah T",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/pills-dark-blue.svg",
    buttonText: "View Prescription",
  },
  {
    id: 4,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/pills-dark-blue.svg",
    buttonText: "View Prescription",
  },
];

export const PreviousPrescriptions = [
  {
    id: 1,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/pills-dark-blue.svg",
    buttonText: "View Prescription",
  },
  {
    id: 2,
    name: "Bellamy N",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/pills-dark-blue.svg",
    buttonText: "View Prescription",
  },
  {
    id: 3,
    name: "Mensah T",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/pills-dark-blue.svg",
    buttonText: "View Prescription",
  },
  {
    id: 4,
    name: "Klimisch J",
    patientName: "Appiah.K",
    visitNumber: 3546,
    visitDate: "Wed, 18 July",
    image: "/assets/pills-dark-blue.svg",
    buttonText: "View Prescription",
  },
];

export const AnnouncementsData = [
  {
    id: 1,
    title: "Blood Drive",
    img: "/assets/blooddrive.png",
    date: "20 Nov 2022",
  },
  {
    id: 2,
    title: "Free Dental Checkupdrwertwrwe",
    img: "/assets/dentalcheckup.png",
    date: "20 Nov 2022",
  },
  {
    id: 3,
    title: "Fun run fundraiser",
    img: "/assets/funrun.png",
    date: "20 Nov 2022",
  },
  {
    id: 4,
    title: "Fun run fundraiser",
    img: "/assets/funrun.png",
    date: "20 Nov 2022",
  },
  {
    id: 5,
    title: "Fun run fundraiser",
    img: "/assets/funrun.png",
    date: "20 Nov 2022",
  },
];
