import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Input } from "../components/Input";
import Label from "../components/Label";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { UserAPI } from "../middleware";
import { Alert } from "@mui/material";
import { LoadingModal } from "../components/Modal";
import { DatePicker, DayCalendarSkeleton, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { style } from '../utils/styling';
import {
  SignupFailed,
  SignupPending,
  SignupSuccessful,
} from "../redux/slice/SignupSlice";
import { BsChatLeftDotsFill } from "react-icons/bs";

type Inputs = {
  username: string;
  patientNumber: string;
  dateOfBirth: string;
};

type ErrorItem = {
  message: string;
  field?: string;
};

const SelectOTPPhone = () => {
  const { isSignedIn } = useReduxSelector((state) => state.user);
  const { isLoading } = useReduxSelector((state) => state.signup);
  const [timer, setTimer] = React.useState<any>(null);
  const id = React.useRef<any>(null);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<ErrorItem[]>([]);
  // const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedNumber, setSelectedNumber] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const location = useLocation();
  const { state } = location;
  const { signUpResponseData } = state;
  const patientContacts = signUpResponseData.data[0]?.patientContacts;

  const handleClose = () => setOpen(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async () => {
    setOpen(true);
    dispatch(SignupPending());

    try {
      console.log("SELECTED NUMBER!!!!: ", { selectedNumber });
      console.log("signUpResponseData!!!!!!!!: ", { signUpResponseData });
      console.log("signUpResponse.data[0].signUpToken !!!!: ", signUpResponseData.data[0].signUpToken);
      const response = await UserAPI.sendOTP(signUpResponseData, selectedNumber);
      setOpen(false);
      console.log("response NUMBER!!!!: ", response);

      if (response.status === 200) {
        console.log("SELECTED NUMBER!!!!: ", { response });
        // dispatch(SignupSuccessful(response));
        setOpen(false);
        navigate("/otp-input", {
          state: {
            patientContact: selectedNumber,
            signUpResponseData: signUpResponseData,
            // response: response
          }
        });
      } else {
        throw new Error('Failed to sign up');
      }
    } catch (error: any) {
      const errorMessage: ErrorItem[] = error?.response?.data?.errors || [{ message: "Unknown error" }];
      dispatch(SignupFailed(errorMessage));
      setOpen(false);
      setError(errorMessage);
    }
  };

  return (
    <>
      {!isSignedIn ? (
        <div className="py-8 md:py-0 px-8 md:px-14 h-screen grid grid-cols-1 md:grid-cols-2 gap-6 items-center relative overflow-x-hidden">
          <div className="flex flex-col justify-center z-10">
            <div className="flex justify-center items-center">
              <img
                src="/assets/trust-hospital-logo.png"
                alt="hospital logo"
                className="w-72 cursor-pointer"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6 max-w-xl">
              <h1 className="text-4xl font-medium text-center">One Time Passcode</h1>
              {error.length > 0 &&
                error.map((item, index) => (
                  <div key={index}>
                    <Alert severity="error" className="mt-2">
                      {item.message}
                    </Alert>
                  </div>
                ))}

              <img
                src="/assets/otppadlock.svg"
                alt="hospital logo"
                className="w-28 pt-8 cursor-pointer m-auto"
              />
              <div className="text-xl text-center font-light">
                Select which contact details should we use to Verify your account.
              </div>
              <div className="mt-8 w-full flex flex-col">
                {patientContacts
                  // .filter((contact: any) => contact.value) // Filter to only include contacts with a value
                  .map((contact: any, index: number) => (
                    <button
                      key={index}
                      className={`flex space-x-6 items-center border rounded-xl px-3 py-2 ${selectedNumber === contact.value ? 'border-4 border-blue-500' : 'border-primary-50'} focus:border-2 focus:outline-primary-50`}
                      value={contact.value}
                      onClick={() => setSelectedNumber(contact.value)}
                    >
                      <div className="bg-secondary-200 p-4 inline-flex rounded-full">
                        <BsChatLeftDotsFill className="text-primary-50" />
                      </div>
                      <div>
                        <p className="text-gray-500 text-sm md:text-base text-left">
                          via SMS:
                        </p>
                        <p className="font-medium text-sm md:text-base">
                          {contact.value}
                        </p>
                      </div>
                    </button>
                  ))}
                <button
                  className={`w-full mt-6 h-10 rounded-2xl font-medium text-md ${selectedNumber ? 'bg-primary-50 text-white hover:opacity-90' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                  onClick={selectedNumber ? handleSubmit(onSubmit) : undefined}
                  disabled={!selectedNumber}
                >
                  Continue
                </button>

              </div>
            </div>
          </div>
          <div className="h-full w-full ">
            <img
              src={"/assets/manwithbluebg.png"}
              alt="new user sign up"
              className="w-full h-full object-contain absolute left-[400px] 2xl:left-[500px] 3xl:left-[550px]"
            />
          </div>
          <LoadingModal
            open={open}
            handleClose={handleClose}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SelectOTPPhone;
