import React from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { Drawer } from "@mui/material";
import { MdPhoneInTalk } from "react-icons/md";
import { VscEllipsis } from "react-icons/vsc";
import { Specialty } from "../utils/dummydata";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
// import { setSelectedSpecialty } from "../redux/slice/UserSlice";
import axios from 'axios';
import { AppointmentsAPI, SpecialistAPI } from "../middleware";
import { useQuery } from "@tanstack/react-query";
import { setSelectedSpecialty, setSelectedDoctor, setSelectedLocation } from "../redux/slice/UserSlice";

const Home = () => {
  const [openNavBar, setOpenNavBar] = React.useState(false);
  const { isSignedIn, access_token, user, } = useReduxSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();

  


  const fetchSpecialties = async () => SpecialistAPI.getSpecialitiesWithImages(access_token);

  const specialtiesObject = useQuery({
    queryKey: ["specialties"],
    queryFn: fetchSpecialties,
  });
  const specialties: any = specialtiesObject.data;
  console.log("SPECIALTIES ON Find a doctor", specialtiesObject.data);


  const startDate = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).format(new Date()).replace(/, /g, ' ').replace(' at ', ' ');


  const fetchCurrentAppointments = async () =>
    AppointmentsAPI.getAppointments(
      access_token,
      1,
      user.user.patient.patientId,
      startDate,
      "01 Mar 2029 00:00:00",
    );


  console.log("FETCH CURRENT APPOINTMENTS", fetchCurrentAppointments);



  let currentAppointemntsObject = useQuery({
    queryKey: ["currentAppointments"],
    queryFn: fetchCurrentAppointments,
  });

  console.log(
    "CURRENT APPOINTMENTS",
    currentAppointemntsObject.data?.data.appointments,
  );





  // start mobile navbar content
  const getList = () => (
    <div className="w-[320px] flex-col h-full items-center bg-primary-100 py-8 px-4">
      <div className="flex justify-end">
        <AiOutlineClose
          className="text-white text-2xl"
          onClick={() => setOpenNavBar(false)}
        />
      </div>
      <div
        className="w-full flex-col items-center justify-center mt-24 text-white space-y-8"
        onClick={() => setOpenNavBar(false)}
      >
        <a href="#covid19">
          <h1 className="text-center text-xl font-bold mb-4">COVID-19</h1>
        </a>
        <a href="#appointments">
          <h1 className="text-center text-xl font-bold mb-4">Appointments</h1>
        </a>
        <a href="#prescriptions">
          <h1 className="text-center text-xl font-bold mb-4">Prescriptions</h1>
        </a>
        <a href="#investigations">
          <h1 className="text-center text-xl font-bold mb-6">Investigations</h1>
        </a>
        {!isSignedIn && (
          <Link to="/signup">
            <button className="bg-white rounded-2xl shadow-sm px-4 py-3 text-primary-100 text-sm lg:text-base w-full focus:opacity-95">
              Sign up
            </button>
          </Link>
        )}
        {isSignedIn && (
          <button
            className="py-5 lg:py-4 lg:px-8 px-10 rounded-2xl bg-primary-100 text-white shadow-md hover:opacity-95"
            onClick={() => {
              localStorage.setItem("view", "Specialty");

              dispatch(
                setSelectedSpecialty({ id: "", name: "" }),
              );
              dispatch(
                setSelectedDoctor({
                  id: "",
                  name: "",
                })
              );

              dispatch(setSelectedLocation({ id: "", name: "" }));
              navigate("/find-a-doctor");
            }}

          >
            Book an appointment
          </button>
        )}
      </div>
    </div>
  );
  // end mobile navbar content
  return (
    <div className="p-6 xl:px-16 xl:py-14">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <img
            src="/assets/trust-hospital-logo.png"
            alt="Trust hospital logo"
            className="w-20 lg:w-40"
          />
        </div>
        <div className="lg:flex items-center hidden space-x-6">
          <h1 className="hover:underline text-primary-300">
            <a href="#covid19">COVID-19</a>
          </h1>
          {/* <h1 className="hover:underline text-primary-300">
						<a href="#services">Services</a>
					</h1> */}
          <h1 className="hover:underline text-primary-300">
            <a href="#appointments">Appointments</a>
          </h1>
          <h1 className="hover:underline text-primary-300">
            <a href="#prescriptions">Prescriptions</a>
          </h1>
          <h1 className="hover:underline text-primary-300">
            <a href="#investigations">Investigations</a>
          </h1>
        </div>
        <div className="flex items-center space-x-3">
          {!isSignedIn && (
            <Link to="/signin">
              <button className="border border-primary-100 rounded-3xl shadow-sm px-8 py-2 text-xs lg:text-base">
                Sign in
              </button>
            </Link>
          )}
          {!isSignedIn && (
            <Link to="/signup">
              <button className="bg-primary-100 rounded-3xl shadow-sm px-8 py-2 text-sm lg:text-base text-white hidden lg:block">
                Sign up
              </button>
            </Link>
          )}
          {isSignedIn && (
            <button
              className="py-5 lg:py-4 lg:px-8 px-10 rounded-2xl bg-primary-100 text-white shadow-md hover:opacity-95"
              onClick={() => {
                localStorage.setItem("view", "Specialty");

                dispatch(
                  setSelectedSpecialty({ id: "", name: "" }),
                );
                dispatch(
                  setSelectedDoctor({
                    id: "",
                    name: "",
                  })
                );

                dispatch(setSelectedLocation({ id: "", name: "" }));
                navigate("/find-a-doctor");
              }}
            >
              Book an appointment

            </button>
          )}
          <div className=" bg-primary-100 flex justify-center px-2 py-1 rounded lg:hidden">
            <HiMenuAlt1
              className="text-2xl text-white "
              onClick={() => setOpenNavBar(true)}
            />
            <Drawer
              open={openNavBar}
              anchor={"right"}
              className="w-full"
              onClose={() => setOpenNavBar(false)}
            >
              {getList()}
            </Drawer>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div className="flex flex-col lg:flex-row lg:justify-between px-5 lg:px-10 py-8 lg:py-16 mt-6 rounded-3xl bg-primary-10 relative xl:h-[663px]">
        <div className="lg:w-6/12 w-full xl:mt-7 2xl:mt-16">
          <div className="flex flex-col items-center lg:items-start">
            <h1 className="text-4xl lg:text-6xl text-primary-300 tracking-tight font-medium lg:font-normal">
              YOUR HEALTH
            </h1>
            <h1 className="text-4xl lg:text-6xl text-primary-300 tracking-tight font-medium lg:font-normal mt-3 lg:mt-6">
              OUR PRIORITY
            </h1>
            <img
              className="lg:hidden block mt-6"
              src="/assets/three-doctors.png"
              alt="three doctors"
            />
            <p className="lg:text-left text-center mt-8 lg:mt-14 text-primary-300 text-base tracking-tight leading-7 w-11/12">
              The core vision for The Trust Hospital is to become a leading
              player in healthcare delivery service in Ghana and the Sub-region.
              From what started as a not-for-profit healthcare Centre to provide
              healthcare to the staff of SSNIT and their dependents, we have
              matured into a fully-fledged hospital, extending our services to
              the general public for the past 24 years…
            </p>
          </div>
          <div className="flex lg:w-11/12 lg:justify-start lg:space-x-6 justify-center mt-10">
            <button
              className="py-5 lg:py-4 lg:px-8 px-10 rounded-2xl bg-primary-100 text-white shadow-md hover:opacity-95"
              onClick={
                isSignedIn ? () => {
                  localStorage.setItem("view", "Specialty");
                  dispatch(
                    setSelectedSpecialty({ id: "", name: "" }),
                  );
                  dispatch(
                    setSelectedDoctor({
                      id: "",
                      name: "",
                    })
                  );

                  dispatch(setSelectedLocation({ id: "", name: "" }));
                  navigate("/find-a-doctor");
                } : () => navigate("/signin")
              }
            >

              Book an appointment
            </button>
            <div className="lg:flex items-center space-x-3 hidden">
              <div className="bg-secondary-300 p-3 rounded-xl block md:hidden xl:block">
                <div className="bg-white text-primary-100 p-3 rounded-xl">
                  <MdPhoneInTalk />
                </div>
              </div>
              <div>
                <p className="text-primary-100 text-xs opacity-80">HELPLINE</p>
                <p className="text-primary-300 text-sm">+233 302 761 975</p>
              </div>
            </div>
          </div>
        </div>
        <div className="items-end justify-end h-full lg:flex hidden">
          <img
            src="/assets/three-doctors.svg"
            alt="three doctors"
            className="lg:absolute bottom-0 w-6/12 right-0 xl:w-[761px] xl:h-[591px]"
          />
        </div>
        {/* {somecommponent()} */}

      </div>

      {/* Services Section */}
      <div id="services" className="mt-12">
        <div className="px-8 lg:px-10 py-8 lg:py-10 mt-6 rounded-3xl bg-primary-200 relative">
          <div className="relative">
            <h1 className="text-primary-100 tracking-widest text-xl">
              Services
            </h1>
            <p className="mt-4 text-3xl text-primary-300 w-11/12 lg:w-2/6 leading-[1.5]">
              Your Health at your Fingertips
            </p>
            <div className="absolute -bottom-3 right-0 w-2/5 hidden lg:block">
              <p className="text-primary-300 opacity-70 text-sm">
                The providers displayed on this site are associated with our
                healthcare system.
              </p>
            </div>
          </div>
          <div>
            <div className="flex md:flex-wrap flex-col md:flex-row justify-center items-center md:justify-around px-8 lg:mt-16 mt-10 space-y-4 lg:space-y-0">
              {/* Appointments */}
              <div
                className="bg-white flex flex-col justify-center items-center rounded-2xl w-[250px] lg:w-[290px] h-[300px] shadow-lg mb-5 lg:mb-0 cursor-pointer"
                id="appointments"
                onClick={() => navigate("/visits-and-appointments")}
              >
                <div className="bg-[#FADBE2] flex justify-center py-10 rounded-2xl -mt-8 w-[190px] lg:w-[250px] h-[145px]">
                  <img
                    src="/assets/nurse_icon_pink.png"
                    alt="nurse"
                    width={60}
                  />
                </div>
                <div className="w-4/5 mt-6 text-center">
                  <h1 className="text-primary-300">Appointments</h1>
                  <p className="text-primary-300 opacity-50 text-xs leading-5 mt-2">
                    Book an appointment at your convenience
                  </p>
                </div>
              </div>
              {/* Prescriptions */}
              <div
                className="bg-white flex flex-col justify-center items-center rounded-2xl w-[250px] lg:w-[290px] h-[300px] shadow-lg mb-5 lg:mb-0 z-50 cursor-pointer"
                id="prescriptions"
                onClick={() => navigate("/prescriptions")}
              >
                <div className="bg-tertiary-300 flex justify-center py-10 rounded-2xl -mt-8 w-[190px] lg:w-[250px] h-[145px]">
                  <img src="/assets/pills.png" alt="pills" width={60} />
                </div>
                <div className="w-4/5 mt-6 text-center">
                  <h1 className="text-primary-300">Prescriptions</h1>
                  <p className="text-primary-300 opacity-50 text-xs leading-5 mt-2">
                    Renew and View your current and past prescriptions
                  </p>
                </div>
              </div>
              {/* Investigations */}
              <div
                className="bg-white flex flex-col justify-center items-center rounded-2xl w-[250px] lg:w-[290px] h-[300px] shadow-lg mb-5 lg:mb-0 z-50 lg:mt-0 xl:mt-0 cursor-pointer"
                id="investigations"
                onClick={() => navigate("/lab-investigations")}
              >
                <div className="bg-[#F6DBFA] flex justify-center py-10 rounded-2xl -mt-8 w-[190px] lg:w-[250px] h-[145px]">
                  <img
                    src="/assets/clipboard.png"
                    alt="investigation list"
                    width={50}
                  />
                </div>
                <div className="w-4/5 mt-6 text-center">
                  <h1 className="text-primary-300">Investigations</h1>
                  <p className="text-primary-300 opacity-50 text-xs leading-5 mt-2">
                    Your medical investigations all in one tab
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute top-24 right-0 z-10 hidden lg:block">
              <img src="/assets/nurse_icon.png" alt="nurse" width={200} />
            </div>
          </div>
        </div>
      </div>

      {/* Appointments */}
      <div className="flex flex-col lg:flex-row mt-12 justify-between lg:space-x-8">
        <div className="bg-primary-200 lg:w-[600px] xl:w-full lg:h-[400px] flex flex-col lg:flex-row justify-center px-4 pt-8 rounded-3xl mb-8 lg:mb-0">
          <div className="flex lg:justify-start lg:items-end justify-center w-full">
            <img
              src="assets/smiling-nurse.svg"
              alt="smiling nurse"
              className="w-[150px] lg:w-[200px] xl:w-[250px]"
            />
          </div>
          <div className="mt-6 flex flex-col lg:block items-center justify-center">
            <h1 className="text-primary-300 text-2xl font-medium xl:text-[28px] lg:-ml-12 lg:text-start text-center">
              BOOK AN APPOINTMENT
            </h1>
            <div className="mt-4 xl:mt-10 flex flex-col lg:justify-start justify-center lg:items-start items-center">
              <p className="text-primary-300 text-normal text-xl xl:text-2xl leading-8 mt-2 lg:mt-5 w-4/5 lg:text-start text-center">
                Gentle, friendly treatment from our local practice.
              </p>
              <button
                className="py-5 lg:py-4 lg:px-5 xl:px-8 px-10 rounded-2xl bg-primary-100 text-white shadow-md mt-8 lg:mt-[80px] xl:mt-[55px] lg:mb-0 mb-12"
                onClick={
                  isSignedIn === true ? () => {
                    localStorage.setItem("view", "Specialty");
                    dispatch(
                      setSelectedSpecialty({ id: "", name: "" }),
                    );
                    dispatch(
                      setSelectedDoctor({
                        id: "",
                        name: "",
                      })
                    );
                    dispatch(setSelectedLocation({ id: "", name: "" }));
                    navigate("/find-a-doctor");
                  } : () => navigate("/signin")
                }
              >
                Book an appointment
              </button>
            </div>
          </div>
        </div>
        <div className="bg-primary-200 lg:w-[600px] xl:w-full lg:h-[400px] flex flex-col items-center py-8 px-5 lg:px-0 rounded-3xl">
          <div>
            <h1 className="text-primary-300 text-2xl font-medium xl:text-[28px] lg:text-start text-center mt-5">
              UPCOMING APPOINTMENTS
            </h1>
            {isSignedIn === true ? (

              currentAppointemntsObject.data?.data.appointments && currentAppointemntsObject.data?.data.appointments.length > 0 ? (

                currentAppointemntsObject.data?.data.appointments.slice(0, 2).map(

                  (appointment: any) => (

                    <div>
                      <div className="w-[300] h-[120px] bg-secondary-100 rounded-3xl px-4 py-4 flex justify-between mt-4">
                        <div className="flex space-x-4 items-center">
                          <div className="bg-secondary-400 w-[75px] h-[90px] rounded-3xl flex flex-col justify-center items-center text-white">
                            {appointment.appointmentDate ? (
                              <div className="bg-secondary-400 w-[75px] h-[90px] rounded-3xl flex flex-col justify-center items-center text-white">

                                <h3 className="">
                                  {new Date(appointment.appointmentDate).toLocaleString('en-US', { weekday: 'short' })}

                                </h3>
                                <h3 className="font-extrabold ">{new Date(appointment.appointmentDate).getDate()}</h3>
                                {/* <p>{new Date(appointment.appointmentDate).toLocaleString('en-US', { month: 'short' })}</p> */}
                              </div>
                            ) : null}
                          </div>
                          <div className="text-primary-100">
                            <p className="text-xs mb-2">{appointment.appointStartTimeName ?? "No picked time"}</p>
                            <h3 className="text-[15px] font-medium mb-2">
                              {appointment.specialistName ?? "No specialist"}
                            </h3>
                            <p className="text-sm">{appointment.branchName}</p>
                          </div>
                        </div>
                        <div>
                          <VscEllipsis className="text-white" />
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className="flex items-center justify-center mt-5">
                  <p>No Appointments found</p>
                </div>)
            ) : (
              <div className="flex items-center justify-center mt-5">
                <button
                  className="border border-primary-100 rounded-3xl shadow-sm px-8 py-2 text-xs lg:text-base"
                  onClick={() => navigate("/signin")}
                >
                  Sign in to view
                </button>
              </div>
            )
            }
          </div>
        </div>
      </div>

      {/* Select a specialty */}
      <div className="px-8 lg:px-10 py-8 lg:py-10 mt-12 rounded-3xl bg-primary-200 relative">
        <div className="flex justify-between md:items-center items-start">
          <h1 className="text-primary-100 tracking-widest text-lg md:text-xl w-3/5">
            SELECT A SPECIALTY
          </h1>
          <div className="flex items-center" onClick={() => {
            localStorage.setItem("view", "Specialty"); // Set the view to DoctorProfile
            navigate("/find-a-doctor");

          }}>
            <p className="underline text-[#6B779A]text-sm md:text-base">
              See all
            </p>
          </div>
        </div>
        <div className="flex md:flex-row flex-col flex-wrap flex-auto justify-around items-center xl:space-y-0 mt-6 gap-4">
          {isSignedIn === true ? (
            specialties && specialties.length > 0 ? (
              specialties.slice(0, 5).map((item: any) => (
                <div
                  key={item.id}
                  className="flex flex-col justify-center items-center bg-primary-400 rounded-3xl px-8 py-4 w-[220px] h-[220px] mt-4 xl:mt-0 cursor-pointer"
                  onClick={() => {
                    navigate("/find-a-doctor");
                    dispatch(
                      setSelectedSpecialty({ id: item.id, name: item.name }),
                    );
                  }}
                >
                  <img src={item.image} alt={item.alt} className="w-[100px]" />
                  <p className="text-primary-300 text-lg mt-2 tracking-wide">
                    {item.name}
                  </p>
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center mt-5">
                No specialties available
              </div>
            )
          ) : (
            <div className="flex items-center justify-center mt-5">
              <button
                className="border border-primary-100 rounded-3xl shadow-sm px-8 py-2 text-xs lg:text-base"
                onClick={() => navigate("/signin")}
              >
                Sign in to view
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
