import { UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import React from "react";
import Loader from "../../components/Loader";
import { AppointmentCard } from "./appointmentCard"; // Import the new component

export default function CurrentAppointmentsTab(
  currentAppointmentsObject: UseQueryResult<AxiosResponse<any, any>, Error>,
) {
  if (currentAppointmentsObject.isLoading)
    return (
      <div className="h-[70vh] flex flex-row justify-center items-center w-full">
        <Loader />
      </div>
    );
  if (currentAppointmentsObject.error)
    return <div>Error: {currentAppointmentsObject.error.message}</div>;
  if (!currentAppointmentsObject.data?.data) return <div>No data</div>;

  return (
    <div className="md:fixed mt-3 flex justify-center">
      <div className="relative w-full h-[70vh] overflow-y-scroll">
        <div className="justify-start flex flex-col flex-wrap md:grid items-start grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full relative max-h-[95%] mb-3 md:mb-0 overflow-y-scroll pb-2">
          {currentAppointmentsObject.data?.data.appointments
            .slice()
            .sort((a: any, b: any) => new Date(a.appointmentDate).getTime() - new Date(b.appointmentDate).getTime())
            .map((visit: any) =>
              visit.status === "assigned" && (
                <AppointmentCard
                  key={visit.appointmentId}
                  image={visit.image}
                  specialistName={visit.specialistName}
                  appointmentName={visit.appointmentName}
                  appointmentCategoryTypeName={visit.appointmentCategoryTypeName}
                  appointmentDate={visit.appointmentDate}
                  appointStartTimeName={visit.appointStartTimeName}
                  branchName={visit.branchName}
                  status={visit.status}
                />
              )
            )}
        </div>
        {currentAppointmentsObject.data?.data.appointments.length === 0 && (
          <div className="flex w-full">
            <p className="text-red-500 text-base">
              No current appointments available
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
