import { Button } from "@mui/material";
import { defaultImage } from "../utils/dummydata";
import { ImageComponent } from "./image_component";

export const DoctorCard = ({ doctor, onClick, selectedDoctor }: any) => {
  const { name, profession, id, availability } = doctor;
  const image = doctor.image && doctor.image !== "" ? doctor.image : defaultImage;

  return (
    <button
      key={id}
      className={`flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-4 py-4 w-full h-[250px] md:w-[220px] md:h-[220px] xl:w-[268px] mt-4 xl:mt-0 hover:bg-primary-50 transition-colors duration-200`}
      onClick={onClick}
    >
      <img
        src={image}
        onError={(e) => {
          e.currentTarget.src = defaultImage; // Fallback if error occurs
        }}
        alt={name}
        className="w-[150px] md:w-[100px] rounded-full"
      />
      <p className="text-primary-100 text-base font-semibold mt-2 tracking-wide">
        {name}
      </p>
      <p className="text-secondary-400 text-xs font-medium mt-1 profession">
        {profession}
      </p>
      {/* <p className="text-primary-100 text-sm font-normal mt-1">
        Available: {availability}
      </p> */}
    </button>
  );
};
