import React from "react";

type Props = {
  type: string;
  name: string;
  className?: string;
  id: string;
  placeholder?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  min?: string | number | undefined;
  max?: string | number | undefined;
  // defaultValue: string;
};

export type Ref = HTMLInputElement;

export const Input = React.forwardRef<Ref, Props>((props, ref) => {
  return (
    <input
      ref={ref}
      {...props}
      className="h-10 px-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-[11px] bg-white"
    />
  );
});

Input.displayName = "Input";
