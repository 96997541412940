import React, { useEffect, useMemo, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";

import "react-calendar/dist/Calendar.css";
import { Chip, Popover } from "@mui/material";
// import { TiLocation } from "react-icons/ti";
import Layout from "../../components/Layout";
import { useReduxDispatch, useReduxSelector } from "../../redux/hooks";
import { setSelectedSpecialty, setSelectedDoctor, setSelectedLocation } from "../../redux/slice/UserSlice";
import MobileSearchbar from "../../components/MobileSearchbar";
import DoctorListTab from "./DoctorsListTab";
import LocationsTab from "./LocationsTab";
import { useQuery } from "@tanstack/react-query";
import { LocationsAPI, SpecialistAPI } from "../../middleware";
import DoctorProfile from "./doctor_profile";
import SpecialtyTab from "./SpecialtyTab";
// import { motion } from "framer-motion";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

// type DoctorInfoType = {
//   id: number;
//   name: string;
//   profession: string;
//   availability: string;
//   image: string;
//   alt: string;
//   about: string;
// };

const FindADoctor = () => {
  const [view, setView] = useState(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("view") || "Specialty";
    }
    return "Specialty";
  });

  useEffect(() => {
    localStorage.setItem("view", view);
  }, [view]);


  const { isSignedIn, selectedSpecialty, selectedDoctor, selectedLocation, access_token, user } = useReduxSelector(
    (state) => state.user
  );
  const navigate = useNavigate();


  // fetch doctors using useQuery hook
  const fetchDoctors = async () => SpecialistAPI.getAllSpecialists(access_token, user.user.username
    , selectedSpecialty.id === 0 ? '' : selectedSpecialty.id ?? "",
  );
  // const fetchDoctors = async () => SpecialistAPI.getAllSpecialists(access_token, user.user.username, specialtyId);

  const doctorsObject = useQuery({
    queryKey: ["doctors", selectedSpecialty.id, selectedLocation.id],
    queryFn: fetchDoctors,
  });

  const fetchSpecialties = async () => SpecialistAPI.getSpecialitiesWithImages(access_token);

  const specialtiesObject = useQuery({
    queryKey: ["specialties"],
    queryFn: fetchSpecialties,
  });

  const uniqueSpecialties: any = useMemo(() => {
    const specialties = Array.isArray(specialtiesObject.data) ? specialtiesObject.data : [];
    const specialtiesSet = new Set(specialties.map((specialty: any) => JSON.stringify(specialty)));
    return Array.from(specialtiesSet).map((specialty: any) => JSON.parse(specialty));
  }, [specialtiesObject.data]);




  // get locations from the server
  const fetchLocations = async () => LocationsAPI.getAllLocations(
    access_token
  );

  const locationsObject = useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocations,
  });


  const locations: any = locationsObject.data;



  const dispatch = useReduxDispatch();
  // const [view, setView] = React.useState<string>("Specialty");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [doctorInfo, setDoctorInfo] = React.useState();
  const [value, onChange] = React.useState(new Date());
  const [specialtyArray, setSpecialtyArray] = React.useState([]);
  const [doctorsArray, setDoctorsArray] = React.useState([]);
  const [locationsArray, setLocationsArray] = React.useState([]);
  const [originalSpecialtyArray, setOriginalSpecialtyArray] = useState([]);
  const [originalDoctorsArray, setOriginalDoctorsArray] = useState([]);
  const [originalLocationsArray, setOriginalLocationsArray] = useState([]);

  const doctors: any = doctorsObject.data;
  console.log("Specialties Data:", specialtyArray);
  console.log("Doctors Data:", doctorsArray);
  console.log("Locations Data:", locationsArray);

  // useEffect(() => {
  //   if (specialtiesObject.data) {

  //     setSpecialtyArray(uniqueSpecialties);
  //     console.log("sssSSS", uniqueSpecialties);
  //     console.log("sssSSS array", specialtyArray);
  //     setDoctorsArray(doctors);
  //     setLocationsArray(locations);

  //     // console.log("selectedSpecialty", selectedSpecialty.name);


  //   }
  // }

  //   , [doctors, doctorsObject.data, locations, selectedSpecialty, uniqueSpecialties, specialtiesObject.data, specialtyArray]);

  const [addressLocation, setAddressLocation] =
    React.useState("Accra, Ghana");
  // const [selectedDoctor, setSelectedDoctor] = React.useState({
  //   id: 0,
  //   name: "",
  // });
  // const [selectedLocation, setSelectedLocation] = React.useState({
  //   id: 0,
  //   name: "",
  //   address: "",
  // });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    handleSearch(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (e: any) => {
    const query = e.target.value?.toLowerCase();
    setSearchQuery(query);

    if (view === "Specialty") {
      setSpecialtyArray(search(originalSpecialtyArray, query));
    }

    if (view === "Doctors") {
      setDoctorsArray(search(originalDoctorsArray, query));
    }

    if (view === "Location") {
      setLocationsArray(search(originalLocationsArray, query));
    }
  };




  const pathname = window.location.pathname;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function ascendingOrder(arr: any) {
    arr.sort((a: any, b: any) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    if (view === "Specialty") {
      setSpecialtyArray(arr);
    } else if (view === "Doctors") {
      setDoctorsArray(arr);
    } else if (view === "Location") {
      setLocationsArray(arr);
    }
    return arr;
  }

  function descendingOrder(arr: any) {
    arr.sort((a: any, b: any) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    });
    if (view === "Specialty") {
      setSpecialtyArray(arr);
    } else if (view === "Doctors") {
      setDoctorsArray(arr);
    } else if (view === "Location") {
      setLocationsArray(arr);
    }
    return arr;
  }

  function search<T extends { name: string; }>(array: T[], query: string): T[] {
    try {
      if (!query) {
        return array;
      }
      return array.filter(function (element: T) {
        return element.name.toLowerCase().includes(query.toLowerCase());
      });
    } catch (error) {
      console.error('Error occurred during search:', error);
      return [];
    }
  }



  // const [view, setView] = React.useState<string>(() => localStorage.getItem("view") || "Specialty");
  // const [searchQuery, setSearchQuery] = React.useState("");
  // const [doctorInfo, setDoctorInfo] = React.useState<DoctorInfoType | undefined>();
  // const [value, onChange] = React.useState(new Date());
  // const [specialtyArray, setSpecialtyArray] = React.useState([]);
  // const [doctorsArray, setDoctorsArray] = React.useState([]);
  // const [locationsArray, setLocationsArray] = React.useState([]);

  useEffect(() => {
    localStorage.setItem("view", view);
  }, [view]);


  useEffect(() => {
    const currentView = localStorage.getItem("view") ?? "Specialty";
    console.log({ currentView });
    if (currentView === "Doctors" || currentView === "Specialty" || currentView === "DoctorProfile" || currentView === "Location") {
      setView(currentView);
    } else {
      setView("Specialty");
    }
  }, [pathname]);


  // useEffect(() => {
  //   if (searchQuery === '') {
  //     // setSpecialtyArray(originalSpecialtyArray);
  //     // setDoctorsArray(originalDoctorsArray);
  //     // setLocationsArray(originalLocationsArray);
  //   } else {
  //     if (view === "Specialty") {
  //       setSpecialtyArray(search(specialtyArray, searchQuery));
  //     }
  //     if (view === "Doctors") {
  //       setDoctorsArray(search(doctorsArray, searchQuery));
  //     }
  //     if (view === "Location") {
  //       setLocationsArray(search(locationsArray, searchQuery));
  //     }
  //   }
  // }, [searchQuery, view, originalSpecialtyArray, originalDoctorsArray, originalLocationsArray, specialtyArray, doctorsArray, locationsArray]);


  useEffect(() => {
    if (specialtiesObject.data) {
      setOriginalSpecialtyArray(uniqueSpecialties);
      setOriginalDoctorsArray(doctors);
      setOriginalLocationsArray(locations);



      // if (view === "Specialty") {
      //   setSpecialtyArray(search(originalSpecialtyArray, query));
      // }

      setSpecialtyArray(originalSpecialtyArray);
      setDoctorsArray(originalDoctorsArray);
      setLocationsArray(originalLocationsArray);
    }
  }, [doctors, locations, uniqueSpecialties, specialtiesObject.data, originalSpecialtyArray, originalDoctorsArray, originalLocationsArray]);


  // const allLocations = locationsObject.data;
  // console.log("LOCATIONS", allLocations);


  return (
    <>
      {isSignedIn === true && pathname === "/find-a-doctor" ? (
        <Layout
          pathname={pathname}
          search={search}
          view={view}
          Specialty={originalSpecialtyArray}
          Doctors={originalDoctorsArray}
          Locations={originalLocationsArray}
          setSpecialtyArray={setSpecialtyArray}
          setDoctorsArray={setDoctorsArray}
          setLocationsArray={setLocationsArray}
        >
          <div>
            {/* Mobile search bar */}
            <MobileSearchbar
              searchTerm={searchQuery}
              view={view}
              handleSearch={handleSearch}
            />

            {/* Nav and sort area */}
            <div className="flex items-center justify-between">
              <div className="flex space-x-4 md:space-x-8 items-center text-sm">
                <div
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => setView("Specialty")}
                >
                  <h3
                    className={`${view === "Specialty"
                      ? "text-black"
                      : "text-[#888E99]"
                      }`}
                  >
                    Specialty
                  </h3>
                  <div
                    className={`h-[5px] w-[5px] ${view === "Specialty"
                      ? "bg-primary-50"
                      : "bg-white"
                      } rounded-full inline`}
                  ></div>
                </div>
                {isSignedIn && (
                  <div
                    className="flex flex-col items-center cursor-pointer"
                    onClick={() => setView("Doctors")}
                  >
                    <h3
                      className={`${view === "Doctors" ||
                        view === "DoctorProfile"
                        ? "text-black"
                        : "text-[#888E99]"
                        }`}
                    >
                      Doctors
                    </h3>
                    <div
                      className={`h-[5px] w-[5px] ${view === "Doctors" ||
                        view === "DoctorProfile"
                        ? "bg-primary-50"
                        : "bg-white"
                        } rounded-full inline`}
                    ></div>
                  </div>
                )}
                {isSignedIn && (
                  <div
                    className="flex flex-col items-center cursor-pointer"
                    onClick={() => setView("Location")}
                  >
                    <h3
                      className={`${view === "Location"
                        ? "text-black"
                        : "text-[#888E99]"
                        }`}
                    >
                      Location
                    </h3>
                    <div
                      className={`h-[5px] w-[5px] ${view === "Location"
                        ? "bg-primary-50"
                        : "bg-white"
                        } rounded-full inline`}
                    ></div>
                  </div>
                )}
              </div>
              <div className="flex items-center space-x-3">
                <button
                  className="flex space-x-3 items-center"
                  onClick={handleClick}
                >
                  <img
                    src="/assets/filter.svg"
                    alt="filter"
                    width={30}
                  />
                  <p className="underline text-primary-100 text-sm hidden md:block">
                    Sort by
                  </p>
                  <RiArrowDownSLine className="hidden md:block" />
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="flex flex-col rounded-md shadow">
                    <button
                      onClick={() => {
                        if (view === "Specialty") {
                          ascendingOrder(specialtyArray);
                        } else if (view === "Doctors") {
                          ascendingOrder(doctorsArray);
                        } else if (view === "Location") {
                          ascendingOrder(locationsArray);
                        }
                        handleClose();
                      }}
                      className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
                    >
                      A-Z
                    </button>
                    <button
                      onClick={() => {
                        if (view === "Specialty") {
                          descendingOrder(specialtyArray);
                        } else if (view === "Doctors") {
                          descendingOrder(doctorsArray);
                        } else if (view === "Location") {
                          descendingOrder(locationsArray);
                        }
                        handleClose();
                      }}
                      className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
                    >
                      Z-A
                    </button>
                  </div>
                </Popover>
              </div>
            </div>
            {/* selected items bar */}
            <div className="mt-3 space-x-3 flex flex-wrap">
              {selectedSpecialty?.name !== "" && (
                <Chip
                  label={selectedSpecialty?.name}
                  variant="outlined"
                  color="primary"
                  className="mr-2 font-montserrat"
                  onDelete={() => {
                    dispatch(
                      setSelectedSpecialty({ id: 0, name: "" })
                    );
                    // setView("Doctors");
                    window.location.reload();
                  }}
                />
              )}
              {/* {selectedDoctor.name !== "" && (
                <Chip
                  label={selectedDoctor.name}
                  variant="outlined"
                  color="primary"
                  className="mr-2 font-montserrat"
                  onDelete={() => {
                    // setSelectedDoctor({ id: 0, name: "" });
                    dispatch(
                      setSelectedDoctor({ id: 0, name: "", profession: "" })
                    );

                    setView("Doctors");

                  }}
                />
              )} */}
              {selectedLocation?.id > 0 && (
                <Chip
                  label={selectedLocation?.name}
                  variant="outlined"
                  color="primary"
                  className="mr-2 font-montserrat"
                  onDelete={() => {
                    dispatch(
                      setSelectedLocation({ id: 0, name: "", address: "" })
                    );
                    window.location.reload();

                    // setView("Doctors");
                  }}
                />
              )}
            </div>
          </div>

          {view === "DoctorProfile" && <DoctorProfile doctorInfo={doctorInfo} setView={setView} value={value} onChange={onChange} selectedDoctor={selectedDoctor} selectedLocation={selectedLocation} />}

          {view === "Doctors" && (
            DoctorListTab(
              doctorsObject,
              doctorsArray,
              setDoctorInfo,
              setView,
              setSelectedDoctor,
              isSignedIn,
              navigate,
              dispatch,
            )
          )}

          {view === "Location" && (
            LocationsTab(addressLocation, locationsArray, setAddressLocation, setSelectedLocation, dispatch, selectedLocation)
          )}

          {view === "Specialty" && (
            SpecialtyTab(specialtiesObject, specialtyArray, selectedSpecialty, setSelectedSpecialty, dispatch, isSignedIn, setView, searchQuery)
          )}
        </Layout>
      ) : (
        <Loader />
      )}
    </>
  );


};

export default FindADoctor;