// Payments.js
import { Box, CircularProgress, Modal } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { BsFillChatFill, BsCheck } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ModalComponent } from "../components/Modal";
import { PaymentsAPI } from "../middleware";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { getUserSuccessful } from "../redux/slice/UserSlice";

const Payments = () => {
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { isSignedIn } = useReduxSelector((state) => state.user);
  const navigate = useNavigate();

  const [view, setView] = useState("initial");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    registrationFees: 0,
    taxes: 0,
    total: 0,
    checkoutUrl: "",
  });

  const [referenceCode, setReferenceCode] = useState("");


  const signupResponse: any = useReduxSelector((state) => state.signup.user);
  // console.log(signupResponse.new_signup.data[0].signUpToken);
  // // Declare listenForPaymentUpdates function outside useEffect
  // const listenForPaymentUpdates = async (referenceCode: any) => {
  //   setLoading(true); // Set loading state when SSE starts
  //   const eventSource = await PaymentsAPI.listenForPaymentUpdates(
  //     referenceCode,
  //     (data: any) => {
  //       console.log("Payment update received:", data);
  //       if (data.status === "successful") {
  //         setView("successful");
  //         setLoading(false); // Stop loading when successful
  //         handleOpen(); // Open modal on success
  //       }
  //     },
  //     (error: any) => {
  //       console.error("Error in SSE connection:", error);
  //       setLoading(false); // Stop loading on error
  //       handleOpen(); // Open modal on error
  //     }
  //   );

  //   return () => {
  //     eventSource?.close(); // Clean up SSE connection on unmount
  //   };
  // };


  const listenForPaymentUpdates = useCallback(async (referenceCode: any) => {
    setLoading(true); // Set loading state when SSE starts
    const eventSource = await PaymentsAPI.listenForPaymentUpdates(
      referenceCode,
      (data: any) => {
        console.log("Payment update received:", data);
        if (data.status === "successful") {
          setView("successful");
          setLoading(false); // Stop loading when successful
          handleOpen(); // Open modal on success
        }
      },
      (error: any) => {
        console.error("Error in SSE connection:", error);
        setLoading(false); // Stop loading on error
        handleOpen(); // Open modal on error
      }
    );

    return () => {
      eventSource?.close(); // Clean up SSE connection on unmount
    };
  }, []);
  useEffect(() => {
    const createAndInitiateInvoice = async () => {
      setLoading(true);
      try {
        const createResponse: any = await PaymentsAPI.createInvoice(signupResponse.new_signup.data[0].signUpToken);
        console.log({ createResponse });
        console.log("Invoice created successfully not sure:", createResponse);
        console.log("Invoice created successfully not sure:", createResponse.data[0].invoice.referenceCode);
        console.log("invoiceDetailsinvoiceDetailsinvoiceDetailsinvoiceDetailsinvoiceDetails:", invoiceDetails);
        if (createResponse.status === 200) {
          setReferenceCode(createResponse.data[0].invoice.referenceCode);
          console.log("Invoice created successfully sure referenceCode!!!!:", createResponse.data[0].invoice.referenceCode);
          console.log("invoiceDetailsinvoiceDetailsinvoiceDetailsinvoiceDetailsinvoiceDetails:", invoiceDetails);
          await handleInitiateInvoice(createResponse.data[0].invoice.referenceCode);


          // Listen for payment updates
          listenForPaymentUpdates(createResponse.data[0].invoice.referenceCode);
        }
      } catch (error) {
        console.error("Error creating and initiating invoice:", error);
      }
      setLoading(false);
    };

    // const listenForPaymentUpdates = (referenceCode: any) => {
    //   PaymentsAPI.listenForPaymentUpdates(
    //     referenceCode,
    //     (data: any) => {
    //       console.log("Payment update received:", data);
    //       // Handle payment update here
    //       if (data.status === "successful") {
    //         setView("successful");
    //         setLoading(false);
    //       }
    //     },
    //     (error: any) => {
    //       console.error("Error in SSE connection:", error);
    //     }
    //   );
    // };

    createAndInitiateInvoice();
  }, [invoiceDetails, listenForPaymentUpdates, signupResponse.new_signup.data]);



  // Modify handleContinue function
  const handleContinue = () => {
    if (invoiceDetails.checkoutUrl) {
      window.open(invoiceDetails.checkoutUrl, "_blank");

      // Start listening for payment updates after clicking "Continue"
      listenForPaymentUpdates(referenceCode);
    }
  };



  const handleInitiateInvoice = async (referenceCode: any) => {
    try {
      console.log("BEGIN Response is handleInitiateInvoice!!!!!!!");
      const response = await PaymentsAPI.initiateInvoicePayment(referenceCode);
      console.log("handleInitiateInvoice Before call");
      console.log("handleInitiateInvoice Before call2", response);
      if (response.data[0]) {
        console.log("Response is handleInitiateInvoice!!!!!!!", response);

        const { invoice, webCheckout } = response.data[0];
        setInvoiceDetails({
          registrationFees: invoice.items[0]?.unitTotal || 0,
          taxes: invoice.taxedAmount,
          total: invoice.grossAmount,
          checkoutUrl: webCheckout.checkoutUrl,
        });
      }
    } catch (error) {
      console.error("Response is handleInitiateInvoice!!!!!!!", error);
      console.error("Error during invoice initiation:", error);
    }
  };



  // const handleContinue = () => {
  //   if (invoiceDetails.checkoutUrl) {
  //     window.open(invoiceDetails.checkoutUrl, "_blank");
  //   }
  //   // setView("authorize");
  // };


  // const listenForPaymentUpdates = (referenceCode: any) => {
  //   PaymentsAPI.listenForPaymentUpdates(
  //     referenceCode,
  //     (data: any) => {
  //       console.log("Payment update received:", data);
  //       // Handle payment update here
  //       if (data.status === "successful") {
  //         setView("successful");
  //         setLoading(false);
  //       }
  //     },
  //     (error: any) => {
  //       console.error("Error in SSE connection:", error);
  //     }
  //   );
  // };



  // const handleInitiateInvoice = async (referenceCode) => {
  //   try {
  //     const response = await PaymentsAPI.initiateInvoicePayment(referenceCode);
  //     if (response && response.length > 0) {
  //       console.error("Response is handleInitiateInvoice!!!!!!!", response);

  //       const { invoice, webCheckout } = response[0];
  //       setInvoiceDetails({
  //         registrationFees: invoice.items[0]?.unitTotal || 0,
  //         taxes: invoice.taxedAmount,
  //         total: invoice.grossAmount,
  //         checkoutUrl: webCheckout.checkoutUrl,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Response is handleInitiateInvoice!!!!!!!", error);
  //     console.error("Error during invoice initiation:", error);
  //   }
  // };

  // const handleContinue = () => {
  //   if (invoiceDetails.checkoutUrl) {
  //     window.open(invoiceDetails.checkoutUrl, "_blank");
  //   }
  // };

  return (
    <div>
      {view === "initial" && (
        <div className="py-8 px-8 xl:px-16 relative">
          {loading && (
            <div className="flex justify-center items-center">
              <CircularProgress />
            </div>
          )}
          <div>
            <img
              src="/assets/trust-hospital-logo.png"
              alt="Trust hospital logo"
              width={180}
            />
          </div>
          <div className="mt-8">
            <div>
              <h1 className="text-primary-50 text-xl">
                Kindly Make Payment Before Booking an Appointment
              </h1>
            </div>
            <div className="mt-12 md:w-2/5 xl:w-1/4 text-[#515151] font-medium">
              <div className="flex justify-between">
                <p>Registration Fees</p>
                <p className="text-primary-50 font-bold">
                  GHS {invoiceDetails.registrationFees.toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Taxes & Fees (10%)</p>
                <p className="text-primary-50 font-bold">
                  GHS {invoiceDetails.taxes.toFixed(2)}
                </p>
              </div>
              <div className="border-b border-gray-700"></div>
              <div className="flex justify-between mt-2">
                <p>Total</p>
                <p className="text-primary-50 font-bold">
                  GHS {invoiceDetails.total.toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-12 md:w-2/5 xl:w-1/4 font-medium text-[#515151]">
            <h1 className="font-medium text-lg">Select Payment Method</h1>
            <label
              className="flex mt-5 space-x-8 justify-between"
              htmlFor="payment"
            >
              <div className="flex items-center space-x-3">
                <img src="/assets/mtn_icon.png" alt="Mtn Icon" className="" />
                <p>MTN Wallet</p>
              </div>
              <input type="radio" name="payment" value="mtn" defaultChecked />
            </label>
          </div>
          <div className="flex justify-center w-full lg:justify-end mt-12 lg:mt-10">
            <button
              className="bg-primary-50 text-white px-20 py-3 rounded-3xl text-sm lg:mr-28 hover:opacity-95"
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>

          <div className="absolute lg:top-24 md:top-40 xl:right-40 right-16 hidden md:block">
            <img
              src="/assets/wallet.png"
              alt="wallet"
              className="md:w-[300px] lg:w-[400px]"
            />
          </div>
        </div>
      )}

      {view === "successful" && (
        <div className="py-8 px-8 xl:px-16 relative">
          <div>
            <img
              src="/assets/trust-hospital-logo.png"
              alt="Trust hospital logo"
              width={180}
            />
          </div>
          <div className="mt-8">
            <div>
              <h1 className="text-primary-50 text-xl">
                Payment Made Successfully
              </h1>
            </div>
            <div className="mt-12 md:w-2/5 xl:w-1/4 text-[#515151] font-medium">
              <div className="flex justify-center">
                <BsCheck className="text-[120px] text-green-500" />
              </div>
            </div>
          </div>

          <div className="flex w-full md:justify-end justify-center mt-12 lg:mt-10">
            <button
              className="bg-primary-50 text-white px-20 py-3 rounded-3xl text-sm lg:mr-28 hover:opacity-95"
              onClick={handleOpen}
            >
              Continue
            </button>
          </div>

          <div className="absolute lg:top-24 md:top-40 xl:right-40 right-16 hidden md:block">
            <img
              src="/assets/wallet.png"
              alt="wallet"
              className="md:w-[300px] lg:w-[400px]"
            />
          </div>
        </div>
      )}

      {/* <ModalComponent open={open} handleClose={handleClose}>
        <div className="bg-primary-50 flex justify-center items-center rounded-full w-20 h-20 mx-auto">
          <BsFillChatFill className="text-[50px] text-white" />
        </div>
        <h1 className="mt-4 text-xl font-semibold">
          Speak to a Doctor Online
        </h1>
        <p className="text-center mt-5 text-[#777777] px-8">
          Do you want to speak to a doctor online and get a consultation right
          now?
        </p>

        <div className="flex justify-center w-full mt-10">
          <button
            className="bg-primary-50 text-white px-20 py-3 rounded-3xl text-sm hover:opacity-95"
            onClick={handleClose}
          >
            Continue
          </button>
        </div>
      </ModalComponent> */}
    </div>
  );
};

export default Payments;

// PaymentsAPI.js
// export const PaymentsAPI = {
//   createInvoice: async (signUpToken) => {
//     try {
//       const response = await fetch("YOUR_API_ENDPOINT/createInvoice", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ signUpToken }),
//       });
//       return response.json();
//     } catch (error) {
//       console.error("Error creating invoice:", error);
//     }
//   },

//   initiateInvoicePayment: async (referenceCode) => {
//     try {
//       const response = await fetch("YOUR_API_ENDPOINT/initiatePayment", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ referenceCode }),
//       });
//       return response.json();
//     } catch (error) {
//       console.error("Error initiating payment:", error);
//     }
//   },

//   listenForPaymentUpdates: (referenceCode, onMessage, onError) => {
//     const eventSource = new EventSource(`YOUR_API_ENDPOINT/paymentUpdates?referenceCode=${referenceCode}`);

//     eventSource.onmessage = (event) => {
//       const data = JSON.parse(event.data);
//       onMessage(data);
//     };

//     eventSource.onerror = (error) => {
//       onError(error);
//     };

//     return () => {
//       eventSource.close();
//     };
//   },
// };
