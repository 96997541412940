import axios from "axios";
import { Buffer } from "buffer";
// import {storage} from "../utils/index";
import { resetSignin } from "../redux/slice/SigninSlice";
import { resetSignup } from "../redux/slice/SignupSlice";
import { resetUser } from "../redux/slice/UserSlice";
// import { useNavigate } from "react-router-dom";


const SPARK_BASE_URL =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_PROD_BASE_URL
    : process.env.REACT_APP_SANDBOX_BASE_URL;
const instance = axios.create({ baseURL: SPARK_BASE_URL });

const client_secret = process.env.REACT_APP_CLIENT_SECRET;
const client_code = process.env.REACT_APP_CLIENT_CODE;
const corsHeader = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

// instance.interceptors.response.use(
//   (response) => {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with the response data
//     return response;
//   },
//   (error) => {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with the response error
//     if (error.response.status === 401) {
//       // dispatch logout action
//       dispatch(SignUserOut());
//     }
//     return Promise.reject(error);
//   }
// );

// Add the interceptor to automatically log out if a 401 error occurs
export const setupInterceptors = (dispatch, navigate) => {
  instance.interceptors.response.use(
    (response) => response, // Pass through any successful responses
    (error) => {
      if (error.response && error.response.status === 401) {
        // Automatically log out the user
        dispatch(resetUser());
        dispatch(resetSignin());
        dispatch(resetSignup());
        // Redirect to login page
        navigate("/signin");
      }
      return Promise.reject(error);
    }
  );
};

export const UserAPI = {
  gettoken: async () => {
    return instance.post(
      `/users/oauth2/token?grant_type=apiIntegration&client_id=tth-api-client&client_secret=${client_secret}&username=&password=&code=${client_code}`,
      {
        headers: corsHeader,
      }
    );
  },


  signup: async (payload, accessToken) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };

      console.log("signup: Sending signup request with payload:", payload);
      
      const response = await instance.post(`/users/v1/sign/up`, payload, {
        headers: headers,
      });

      console.log("signup: Signup response received:", response.data);
      
      return response;
    } catch (error) {
      console.error("signup: Error during signup:", error);
      throw error;
    }
  },

  signin: async (data) => {
    return instance.post(
      `/users/oauth2/token?grant_type=password&client_id=tth-api-client&client_secret=${client_secret}&username=${data.username}&password=${data.password}&code=${client_code}`,
      {
        headers: corsHeader,
      }
    );
  },

  signUpExistingPatient: async (data) => {
    console.log('signUpExistingPatient: Request data:', data);
    const tokenResponse = await UserAPI.gettoken();
    console.log('signUpExistingPatient: Token response:', tokenResponse);
    const token = tokenResponse.data.access_token;

    const response = await instance.post('/users/v1/sign/up', data, {
      headers: {
        ...corsHeader,
        "Authorization": `Bearer ${token}`,
      },
    });
    console.log('signUpExistingPatient: Response:', response.status);
    return response;
  },
  sendOTP: async (signUpResponse, contact) => {
    console.log("sendOTP: Function called");
  
    try {
      const signupt = signUpResponse.data[0].signUpToken;
      console.log({signupt})
      const tokenResponse = await UserAPI.gettoken();
      const token = tokenResponse.data.access_token;
      console.log("BRUH");
  
      console.log('sendOTP: TOKEN passed to send otp function:', token);
      console.log('sendOTP: Request signUpResponse passed to send otp function:', signUpResponse);
      console.log('sendOTP: Request contact passed to send otp function:', contact);
      console.log('sendOTP: signUpResponse.data.data[0].signUpToken passed to send otp function:', signUpResponse.data[0].signUpToken);
  
      const response = await instance.post(`/users/v1/sign/up/${signupt}/verification/code`, {
        patientContact: { type: "mobile", value: contact },
        token: signUpResponse.data[0].signUpToken,
        usageType: "signUp",
        requestedBy: signUpResponse.data[0].id,
        appClientToken: "8b23a10e-badc-483b-9699-32a5b1d0ec08",
      }, {
        headers: {
          ...corsHeader,
          "Authorization": `Bearer ${token}`
        },
      });
  
      console.log('sendOTP: Response in send otp function:', response);
      return response;
    } catch (error) {
      console.error('sendOTP: Error in send otp function:', error);
      throw error;
    }
  },

  verifyOTP: async (signUpResponse, otp) => {
    // console.log('verifyOTP: Request signUpResponse:', signUpResponse);
    // console.log('verifyOTP: Request OTP:', otp);

    // const response = await instance.patch(`/v1/sign/up/${signUpResponse.signUpToken}/verify/code/${otp}`, {}, {
    //   headers: corsHeader,
    // });

    // console.log('verifyOTP: Response:', response);
    // return response;

    console.log("verifyOTP: Function called");
  
    try {
      const signupt = signUpResponse.data[0].signUpToken;
      console.log({signupt})
      const tokenResponse = await UserAPI.gettoken();
      const token = tokenResponse.data.access_token;
      console.log("BRUH");
  
      console.log('verifyOTP: TOKEN passed to send otp function:', token);
      console.log('verifyOTP: Request signUpResponse passed to send otp function:', signUpResponse);
      console.log('verifyOTP: Request otp passed to send otp function:', otp);
      console.log('verifyOTP: signUpResponse.data.data[0].signUpToken passed to send otp function:', signUpResponse.data[0].signUpToken);
  
     const response = await instance.patch(`/users/v1/sign/up/${signupt}/verify/code/${otp}`, {}, {
        headers: {
          ...corsHeader,
          "Authorization": `Bearer ${token}`
        },
      });
  
      console.log('verifyOTP: Response in send otp function:', response);
      return response;
    } catch (error) {
      console.error('verifyOTP: Error in send otp function:', error);
      throw error;
    }
  },

  resetPasswordAfterOTP: async (signUpResponse, otp, user, password, confirmedPassword) => {
    console.log("resetPasswordAfterOTP: Function called");

    try {
      const signupt = signUpResponse.data[0].signUpToken;
      console.log({ signupt });

      const tokenResponse = await UserAPI.gettoken();
      const token = tokenResponse.data.access_token;
      console.log("BRUH");

      console.log('resetPasswordAfterOTP: TOKEN passed to reset password function:', token);
      console.log('resetPasswordAfterOTP: Request signUpResponse passed to reset password function:', signUpResponse);
      console.log('resetPasswordAfterOTP: Request otp passed to reset password function:', otp);
      console.log('resetPasswordAfterOTP: Request user passed to reset password function:', user);

      const response = await instance.patch('/users/v1/user/password/reset', {
        otpValue: otp,
        token: signUpResponse.data[0].signUpToken,
        activity: "onSignUpReset",
        userPassword: {
          userId: user.id,
          username: user.username,
          password: password,
          confirmedPassword: confirmedPassword
        }
      }, {
        headers: {
          ...corsHeader,
          "Authorization": `Bearer ${token}`
        },
      });

      console.log('resetPasswordAfterOTP: Response in reset password function:', response);
      return response;
    } catch (error) {
      console.error('resetPasswordAfterOTP: Error in reset password function:', error);
      throw error;
    }
  },

  ForgotpasswordInitiate: async (username) => {
    console.log("ForgotpasswordInitiate: Function called");

    try {
      const tokenResponse = await UserAPI.gettoken();

      const token = tokenResponse.data.access_token;
      console.log("ForgotpasswordInitiate: TOKEN received:", token);
      console.log("ForgotpasswordInitiate: TOKEN received:", tokenResponse);

      const response = await instance.post(
        '/users/v1/user/password/forgotten/initiate',
        {
          username,
          patientNumber: username,
          dateOfBirth: null,
        },
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Cookie": "JSESSIONID=2115AA2E8D03D0C69D14D612697C2C83",
          },
        }
      );

      console.log('ForgotpasswordInitiate: Response:', response);
      return response.data;
    } catch (error) {
      console.error('ForgotpasswordInitiate: Error:', error);
      throw error;
    }
  },

  ForgotpasswordSendOTP: async (contact, patientNumber) => {
    console.log("ForgotpasswordSendOTP: Function called");

    try {
      const tokenResponse = await UserAPI.gettoken();
      const token = tokenResponse.data.access_token;
      console.log("ForgotpasswordSendOTP: TOKEN received:", token);

      const response = await instance.post(
        '/users/v1/sign/up/C7191A4BC85A82B6E643C5A588AD9768/verification/code',
        {
          patientContact: { type: "mobile", value: contact },
          token: null,
          usageType: "passwordReset",
          requestedBy: patientNumber,
          appClientToken: "8b23a10e-badc-483b-9699-32a5b1d0ec08"
        },
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Cookie": "JSESSIONID=DBC66584352BE25AC8F2AD868BB040C5",
          },
        }
      );

      console.log('ForgotpasswordSendOTP: Response:', response);
      return response.data;
    } catch (error) {
      console.error('ForgotpasswordSendOTP: Error:', error);
      throw error;
    }
  },

  ForgotpasswordValidateOTP: async (otp) => {
    console.log("ForgotpasswordValidateOTP: Function called");

    try {
      const tokenResponse = await UserAPI.gettoken();
      const token = tokenResponse.data.access_token;
      console.log("ForgotpasswordValidateOTP: TOKEN received:", token);

      const response = await instance.patch(
        `/users/v1/sign/up/C7191A4BC85A82B6E643C5A588AD9768/verify/code/${otp}`,
        {},
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log('ForgotpasswordValidateOTP: Response:', response);
      return response.data;
    } catch (error) {
      console.error('ForgotpasswordValidateOTP: Error:', error);
      throw error;
    }
  },
  
  ForgotpasswordUpdate: async (otp, username, userId, password, confirmedPassword) => {
    console.log("ForgotpasswordUpdate: Function called");
    console.log("Parameters:", { otp, username, userId, password, confirmedPassword });
  
    try {
      const tokenResponse = await UserAPI.gettoken();
      const token = tokenResponse.data.access_token;
      console.log("ForgotpasswordUpdate: TOKEN received:", token);
  
      const response = await instance.patch(
        '/users/v1/user/password/forgotten',
        {
          otpValue: otp,
          token: null,
          activity: "userInitatedReset",
          userPassword: {
            userId,
            username,
            password,
            confirmedPassword,
          }
        },
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Cookie": "JSESSIONID=B6E92CE1CC913AA9D01C6FEDCAB2972C",
          },
        }
      );
  
      console.log('ForgotpasswordUpdate: Response:', response);
      return response.data;
    } catch (error) {
      console.error('ForgotpasswordUpdate: Error:', error);
      throw error;
    }
  },
  




  refreshToken: async (refreshToken) => {
    const refreshTokenEndpoint = '/users/oauth2/token';
    const clientId = 'tth-api-client';
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
    const grantType = 'refresh_token';

    const data = {
      grant_type: grantType,
      client_id: clientId,
      client_secret: clientSecret,
      refresh_token: refreshToken,
    };

    try {
      const response = await instance.post(refreshTokenEndpoint, data, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to refresh token');
      }

      return response.data; // Assuming the response contains the new access token and other relevant data
    } catch (error) {
      throw new Error('Error refreshing token: ' + error.message);
    }
  },

};

export const AppointmentsAPI = {
  getAppointments: async (access_token, healthFacilityId, patientId, startDate, endDate) => {
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
  
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/appointments?startDate=${encodedStartDate}&endDate=${encodedEndDate}&pageSize=100`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        // params: {
        //   startDate: encodedStartDate,
        //   endDate: encodedEndDate,
        // },
      },
    );
  },
  
  getAppointmentByID: async (access_token, healthFacilityId, patientId, appointmentId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/appointments/${appointmentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },

  getPatientSpecificSpecialistAppointment: async (access_token, healthFacilityId, patientId, appointmentId, specialistId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/appointments/${appointmentId}/specialist/${specialistId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },

  getPatientSpecialistAppointment: async (access_token, healthFacilityId, patientId, specialistId, startDate, endDate) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/appointments/specialist/${specialistId}`,
      {
        params: {
          startDate,
          endDate,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },

  getPatientAppointmentSlotsForSpecialist: async (access_token, healthFacilityId, patientId, medicalStaffId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/appointments/specialist/slots`,
      {
        params: {
          medicalStaffId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },

  getAppointmentCategories: async (access_token, healthFacilityId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/appointments/categories`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },

  getAvailableDatesAndTimes: async (access_token, healthFacilityId, patientId, specialistId, appointmentId, appointmentDay, startTime) => {
    return axios.get(
      `https://healthpro-api.thetrusthospital.com/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/specialists/availability/slots`,
      {
        params: {
          specialistId,
          appointmentId,
          appointmentDay,
          startTime,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },

  createAppointment: async (access_token, healthFacilityId, patientId, appointmentBody) => {
    return instance.post(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/appointments`,
      appointmentBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },
};

export const VisitationAPI = {
  getPatientVisitations: async (access_token, healthFacilityId, patientId, page, size) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/visitations?page=${page}&size=${size}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },

  getVisitationByID: async (access_token, healthFacilityId, patientId, visitationId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/visitations/${visitationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },
};


export const InvestigationsAPI = {
  getInvestigations: async (access_token, healthFacilityId, patientId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/investigations`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },
  getByID: async (
    access_token,
    healthFacilityId,
    patientId,
    investigationId,
  ) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/investigations/${investigationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },
};

export const PrescriptionAPI = {
  getPrescription: async (access_token, healthFacilityId, patientId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/prescriptions`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },
  getByID: async (access_token, healthFacilityId, patientId, visitationId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/prescriptions/${visitationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  },
};

export const DependantsAPI = {
   getPatientDependants: async (patientId, page, size, healthFacilityId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/dependants`,
      {
        params: {
          page,
          size,
        },
      }
    );
  },

   getDependantVisitations: async (patientId, specialistId, name, healthFacilityId) => {
    return instance.get(
      `/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/dependants/specialist/${specialistId}`,
      {
        params: {
          name,
        },
      }
    );
  }
}



export const SpecialistAPI = {

  getSpecialistsForSpeciality: async (access_token, specialityId, patientId) => {
    try {
      const response = axios.get({
        baseURL: `https://healthpro-api.thetrusthospital.com/ext-services/v1/corporate/health/facility/1/patients/${patientId}/specialists/for/speciality?specialistId=&specialityId=${specialityId}&branchId=001&page=1&size=100`,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Authorization': 'Bearer ' + access_token,
        },
      });


      if (response.status !== 200) {
        throw new Error('Error fetching specialists for speciality: ' + response.status);
      }

      const specialists = response.data.data.map((item) => {
        return {
          id: Math.random().toString(36).substr(2, 9),
          name: item.specialistName,
          profession: item.specialityName ?? "General Practitioner",
          image: item.specialistImage.value,
          availability: item.numberOfAppointments === 0 ? "Available" : "Not Available",
          alt: item.specialistName,
          about: item.specialistName,
        };
      });

      return specialists;
    } catch (error) {
      throw new Error('Error fetching specialists for speciality: ' + error.message);
    }
  },


  getAllSpecialists: async (access_token, patientId, specialityId) => {
    const formatName = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
    };

    try {
      const response = await axios.get(`https://healthpro-api.thetrusthospital.com/ext-services/v1/corporate/health/facility/1/patients/${patientId}/specialists/for/speciality?specialistId=&specialityId=${specialityId}&branchId=&page=1&size=100`, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Authorization': 'Bearer ' + access_token,
        },
      });

      console.log("SPECIALISTS RESPONSE IS..........", response.config.url);
      // https://healthpro-api.thetrusthospital.com/ext-services/v1/corporate/health/facility/1/patients/0296208/specialists/for/speciality?specialistId=&specialityId=&branchId=&page=1&size=100
      // https://healthpro-api.thetrusthospital.com/ext-services/v1/corporate/health/facility/1/patients/0296208/specialists/for/speciality?specialistId=&specialityId=&branchId=&page=1&size=100
      console.log("SPECIALISTS RESPONSE IS..........", response);
      if (response.status !== 200) {
        console.log("SPECIALISTS RESPONSE IS..........", response);
        throw new Error('Error fetching specialities: ' + response.status);
      }
      
      console.log("SPECIALISTS RESPONSE IS..........", response);
      const specialists = response.data.data.map((item) => {
        // format name before returning
        const formattedName = formatName(item.specialistName);

        return {
          id: item.specialistId,
          name: formattedName,
          profession: item.specialistStatusName,
          image: item.specialistImage == null ? null : item.specialistImage.value,
          // availability: item.specialistStatusName === 'active' ? "Available" : "Not Available",
          // alt: formattedName,
          // about: formattedName,
        };
      });

      return specialists;
    } catch (error) {
      throw new Error('Error fetching specialists: ' + error.message);
    }
  },

  getBioData: async (access_token, specialistId) => {
    try {
      const response = await axios.get(`https://healthpro-api.thetrusthospital.com/v1/corporate/health/facility/1/patients/0296208/specialist/bio-data/${specialistId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Authorization': 'Bearer ' + access_token,
        },
        // params: {
        //   specialistId: specialistId // Pass the specialistId as a parameter
        // }
      });

      if (response.status !== 200) {
        console.log(response.statusText);
        throw new Error('Error fetching specialist bio-data: ' + response.status + ' ' + response.statusText);
      }

      // Assuming the bio-data is directly available in response.data
      const bioData = response.data;

      return bioData;
    } catch (error) {
      throw new Error('Error fetching specialist bio-data: ' + error.message);
    }
  },

  getSpecialistAvailabilitySlots: async (access_token, corporateHealthFacilityId, patientId, specialistId) => {
    try {
      const response = await axios.get(`https://healthpro-api.thetrusthospital.com/v1/corporate/health/facility/${corporateHealthFacilityId}/patients/${patientId}/specialists/availability/slots`, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Authorization': 'Bearer ' + access_token,
        },
        params: {
          specialistId: specialistId // Pass the specialistId as a parameter
        }
      });

      if (response.status !== 200) {
        throw new Error('Error fetching specialist availability slots: ' + response.status);
      }

      // Assuming the availability slots are directly available in response.data
      const availabilitySlots = response.data;

      return availabilitySlots;
    } catch (error) {
      throw new Error('Error fetching specialist availability slots: ' + error.message);
    }
  },

  getSpecialitiesWithImages: async (accessToken) => {
    try {
      const response = await axios.get(`https://healthpro-api.thetrusthospital.com/ext-services/v1/corporate/health/facility/1/specialities?page=1&size=100`, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          // 'Authorization': 'Bearer ' + accessToken,
        },
      });

      if (response.status !== 200) {
        throw new Error('Error fetching specialities: ' + response.status);
      }
      console.log("SPECIALITIES RESPONSE IS..........", response);

      const specialities = await Promise.all(response.data.data.map(async (speciality) => {
        const { specialityId, specialityName, specialityImageUrl } = speciality;
        try {
          const imageResponse = await axios.get(specialityImageUrl, {
            headers: {
              'Authorization': 'Bearer ' + accessToken,
            },
            responseType: 'arraybuffer',
          });

          const base64Image = Buffer.from(imageResponse.data, 'binary').toString('base64');

          console.log('Image for speciality', specialityName, 'fetched successfully');

          return {
            id: specialityId,
            name: specialityName,
            image: `data:image/jpeg;base64,${base64Image}`,
            alt: specialityName,
          };
        } catch (imageError) {
          console.error('Error fetching image for speciality', specialityId, ':', imageError.message);
          return {
            id: specialityId,
            name: specialityName,
            image: '', // Placeholder or default image
            alt: specialityName,
          };
        }
        // console.log("Name is: ", specialityName);
      }));

      return specialities;
    } catch (error) {
      throw new Error('Error fetching specialities: ' + error.message);
    }
  },

};

export const LocationsAPI = {
  getAllLocations: async (accessToken) => {
    const corporateHealthFacilityId = 1; // Replace with the actual corporateHealthFacilityId
    try {
      const response = await axios.get(`https://healthpro-api.thetrusthospital.com/users/v1/corporate/health/facilities/${corporateHealthFacilityId}/centers`, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Authorization': 'Bearer ' + accessToken,
        },
      });
      console.log("Locations API response: ", response);
      console.log("Locations API response: ", response);
      console.log("Locations API response: ", response);
      console.log("Locations API response: ",{ response});

      // console log curl command with headers and bearer token
      // console.log(`curl -X GET "https://healthpro-api.thetrusthospital.com/ext-services/v1/corporate/health/facility/1/branches" -H "Content-Type: application/json" -H "Cache-Control : no-cache "Bearer ${accessToken}"`);

      if (response.status !== 200) {
        throw new Error('Error fetching locations: ' + response.status);
      }

      const locations = response.data.data.map((location) => {
        return {
          id: location.id,
          name: location.name,
          type: location.type,
          adress: location.address.streetAddress,
          externalReferenceId: location.externalReferenceId,
          // alt: location.locationName,
        };
      });

      return locations;
    }
    catch (error) {
      throw new Error('Error fetching locations: ' + error.message);
    }
  },


};

// PaymentsAPI.js
// import axios from 'axios';

export const PaymentsAPI = {
  createInvoice: async (signUpToken) => {
    try {
      const token = await UserAPI.gettoken();
      console.log('Create Invoice TOKEN:', token.data.access_token);
      console.log('Create Invoice hahahaa', signUpToken);

      if (token) {
        const response = await axios.post(
          `https://healthpro-api.thetrusthospital.com/users/v1/sign/up/patient/bill/${signUpToken}/invoice`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token.data.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log({response});

        // if (response.status !== 200) {

          
          // console.log('Create Invoice Response:', response.errors[0].message);
        //   return  response.errors[0];
        //   // throw new Error('Error creating invoice: ' + response.errors[0].message);
        // }



        return response.data;
      } else {
        console.error('Connection Error: Unable to create invoice');
        return null;
      }
    } catch (error) {
      console.error('Create Invoice Error:', error);
      return null;
    }
  },

  initiateInvoicePayment: async (invoiceReferenceCode) => {
    try {
      const token = await UserAPI.gettoken();

      if (token) {
        const response = await axios.post(
          `https://healthpro-api.thetrusthospital.com/users/v1/payments/invoice/${invoiceReferenceCode}/initiate`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token.data.access_token}`,
              'Content-Type': 'application/json',
              'Cookie': 'JSESSIONID=1F0838821FDB39338BBBD5EC4AC11E2F',
            },
          }
        );
        console.log('Initiate Invoice Response:', response.data);
        return response.data;
      } else {
        console.error('Connection Error: Unable to fetch token');
        return null;
      }
    } catch (error) {
      console.error('Initiate Invoice Error:', error);
      return null;
    }
  },

  listenForPaymentUpdates: async (invoiceReferenceCode, onMessageCallback, onErrorCallback) => {
    try {
      const token = await UserAPI.gettoken();
  
      if (token) {
        const eventSourceUrl = `https://healthpro-api.thetrusthospital.com/users/v1/payments/invoice/${invoiceReferenceCode}/updates?token=${token.data.access_token}`;
        const eventSource = new EventSource(eventSourceUrl);
  
        eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log('SSE Message:', data);
          onMessageCallback(data);
        };
  
        eventSource.onerror = (error) => {
          console.error('SSE Error:', error);
          onErrorCallback(error);
          eventSource.close();
        };
  
        return eventSource;
      } else {
        console.error('Connection Error: Unable to fetch token');
        return null;
      }
    } catch (error) {
      console.error('SSE Connection Error:', error);
      return null;
    }
  },
  
};





// ebo.bentil+super20@gmail.com
// f00b@r
// http://localhost:3000/signin
// get appointments curl
// curl -v --user "TrustAPp:trustapp" -XGET "http://192.168.98.21/HealthProApi/healthpro//GetPatientAppointment?patientid=0296208&startdate=2024-02-01%2000:00:00&enddate=2024-02-29%2023:59:59"