import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import Label from "../components/Label";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { UserAPI } from "../middleware";
import { Alert } from "@mui/material";
import { LoadingModal } from "../components/Modal";
import { DatePicker, DayCalendarSkeleton, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { style } from '../utils/styling';
import {
  SignupFailed,
  SignupPending,
  SignupSuccessful,
} from "../redux/slice/SignupSlice";

type Inputs = {
  username: string;
  patientNumber: string;
  dateOfBirth: string;
};

type ErrorItem = {
  message: string;
  field?: string;
};

const VerifyOTP = () => {
  const { isSignedIn } = useReduxSelector((state) => state.user);
  const { isLoading } = useReduxSelector((state) => state.signup);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<ErrorItem[]>([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    isSignedIn && window.location.assign("/find-a-doctor");
  }, [isSignedIn, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setOpen(true);
    dispatch(SignupPending());
    let { patientNumber } = data;
    let username = patientNumber;  // Set username to patientNumber

    // Ensure dateOfBirth is formatted as 'YYYY-MM-DD'
    const formattedDateOfBirth = selectedDate.format('YYYY-MM-DD');

    let payload = {
      username,
      patientNumber,
      dateOfBirth: formattedDateOfBirth,
      appClientToken: process.env.REACT_APP_CLIENT_CODE,
      signUpType: "existingPatient",
      address: null,
      contacts: null,
      firstName: null,
      lastName: null,
      otherName: null,
      gender: null,
    };

    console.log("PAYLOAD", payload);

    try {
      const signUpResponse = await UserAPI.signUpExistingPatient(payload);
      console.log(signUpResponse, "signUpResponse");

      if (signUpResponse.status === 200) {
        dispatch(SignupSuccessful(signUpResponse));
        setOpen(false);
        navigate("/verify-otp", { state: { signUpToken: signUpResponse.data.signUpToken } });
      } else {
        throw new Error('Failed to sign up');
      }
    } catch (error: any) {
      const errorMessage: ErrorItem[] = error?.response?.data?.errors || [{ message: "Unknown error" }];
      dispatch(SignupFailed(errorMessage));
      setOpen(false);
      setError(errorMessage);
    }
  };

  return (
    <>
      {!isSignedIn ? (
        <div className="py-8 md:py-0 px-8 md:px-14 h-screen grid grid-cols-1 md:grid-cols-2 gap-6 items-center relative overflow-x-hidden">
          {/* form */}
          <div className="flex flex-col justify-center z-10">
            <div className="flex justify-center items-center">
              <img
                src="/assets/trust-hospital-logo.png"
                alt="hospital logo"
                className="w-40 cursor-pointer"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
              <h1 className="text-4xl font-medium text-center">Sign Up</h1>
              {error.length > 0 &&
                error.map((item, index) => (
                  <div key={index}>
                    <Alert severity="error" className="mt-2">
                      {item.message}
                    </Alert>
                  </div>
                ))}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-6 w-full flex flex-col"
              >
                <div className="mt-4">
                  <Label label="Patient Number" />
                  <div className="mt-3 rounded-3xl">
                    <Input
                      type="text"

                      className="rounded-[26px]"
                      id="patientNumber"
                      {...register("patientNumber", { required: true })}
                      aria-invalid={errors.patientNumber ? "true" : "false"}
                    />
                  </div>
                  {errors.patientNumber && (
                    <p className="text-xs text-red-500 mt-1">
                      *Your patient number is required
                    </p>
                  )}
                </div>

                <div className="mt-4">
                  <Label label="Date of Birth" />
                  <div className="mt-3 w-full">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className="">
                        <DatePicker

                          slotProps={{
                            textField: {
                              // size: 'small', // don't need this anymore so feel free to remove
                              sx: {
                                '> .MuiOutlinedInput-root': {
                                  height: 45,
                                  borderRadius: '12px',
                                }
                              }
                            }
                          }}

                          // sx={{
                          //   // borderRadius: '16px',
                          //   // borderWidth: '1px',
                          //   // // borderColor: '#2196f3',
                          //   // border: '1px',
                          //   // borderColor: '#B6B6B6',
                          //   // borderStyle: 'solid',
                          //   '&.MuiPickersPopper-root': {
                          //     border: '4px solid red',
                          //     borderRadius: '16px',
                          //   }

                          // }}
                          className="border-none shadow-none w-full p-4"
                          disablePast={false}
                          disableFuture={true}
                          format="DD/MM/YYYY"
                          showDaysOutsideCurrentMonth
                          fixedWeekNumber={5}
                          renderLoading={() => <DayCalendarSkeleton />}
                          value={selectedDate}
                          onChange={(date: any) => setSelectedDate(date)}
                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                  {errors.dateOfBirth && (
                    <p className="text-xs text-red-500 mt-1">
                      *Your date of birth is required
                    </p>
                  )}
                </div>

                <button className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md">
                  Sign Up
                </button>
              </form>
              <p className="text-sm text-center mt-6">
                <span className="text-primary-50 underline">
                  <Link to="/newsignup">Sign Up</Link>
                </span>{" "}
                as a new patient?
              </p>
            </div>
            <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
              <p className="text-lg text-center ">
                If you do not have your patient number, kindly call <br /> us on{" "}
                <a href="tel:+233302761975">
                  <span className="text-primary-50 underline">
                    +233 302 761 975{" "}
                  </span>
                </a>{" "}
                before you proceed
              </p>
            </div>
            <div>
              <p className="text-lg text-center my-4">
                Already Have an Account?{" "}
                <span className="text-primary-50">
                  <Link to="/signin">Sign In</Link>
                </span>
              </p>
            </div>
          </div>
          {/* image */}
          <div className="h-full w-full ">
            <img
              src={"/assets/newuser-signup.png"}
              alt="new user sign up"
              className="w-full h-full object-contain absolute left-60"
            />
          </div>
          <LoadingModal
            open={open}
            handleClose={handleClose}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default VerifyOTP;
