import React from "react";
import Loader from "../../components/Loader";
import { AppointmentCard } from "./appointmentCard";

export default function CompletedTab(visitationObject: any) {
  if (visitationObject.isLoading)
    return (
      <div className="h-[70vh] flex flex-row justify-center items-center w-full">
        <Loader />
      </div>
    );
  if (visitationObject.error)
    return <div>Error: {visitationObject.error.message}</div>;
  if (!visitationObject.data?.data) return <div>No data</div>;

  return (
    <div className="md:fixed mt-3 flex justify-center">
      <div className="relative w-full h-[70vh] overflow-y-scroll">
        <div className="justify-start flex flex-col flex-wrap md:grid items-start grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full relative max-h-[95%] mb-3 md:mb-0 overflow-y-scroll pb-2">
          {visitationObject.data?.data.visitations.map((visit: any) => (
            <AppointmentCard
              key={visit.visitationId}
              image={visit.image}
              specialistName={visit.visitationName}
              appointmentName={`Outcome: ${visit.medicalOutcomeName}`}
              appointmentCategoryTypeName={visit.visitationId}
              appointmentDate={visit.visitDate}
            />
          ))}
        </div>
        {visitationObject.data?.data.visitations.length === 0 && (
          <div className="flex w-full">
            <p className="text-red-500 text-base">
              No completed appointments available
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
