import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import Label from "../components/Label";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { UserAPI } from "../middleware";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import {
  signinFailed,
  signinPending,
  signinSuccessful,
} from "../redux/slice/SigninSlice";
import {
  getAccessToken,
  getFirstName,
  getLastName,
  getUserSuccessful,
} from "../redux/slice/UserSlice";
import { storage } from "../utils";
import { Alert } from "@mui/material";
import { LoadingModal } from "../components/Modal";

type Inputs = {
  username: string;
  password: string;
};

export function SignIn() {
  const { isSignedIn } = useReduxSelector((state) => state.user);
  const { isLoading } = useReduxSelector((state) => state.signin);
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<[] | null>(null);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    isSignedIn && window.location.assign("/find-a-doctor");
  }, [isSignedIn]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handlePasswordToggle = () => {
    setShowPassword(showPassword ? false : true);
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data, "data");

    dispatch(signinPending());
    setOpen(true);
    try {
      const request = await UserAPI.signin(data);
      const response = request.data;
      // console.log(response);
      dispatch(signinSuccessful(response));
      dispatch(getUserSuccessful(response));
      dispatch(getFirstName(response.user.firstName));
      dispatch(getLastName(response.user.lastName));
      dispatch(getAccessToken(response.access_token));
      storage.local.set("@user-session", {
        status: "resolved",
        user: response.user,
        token: response.access_token,
        error: null,
      });
      setOpen(false);
      navigate("/find-a-doctor");
    } catch (error: any) {
      setOpen(false);
      const response = error.response.data;
      dispatch(signinFailed(response));
      // console.log(response);
      setError(response?.errors);
    }
  };
  return (
    <>

      <div className="py-8 md:py-0 px-8 md:px-14 h-full">
        <div className="flex w-full justify-between items-center">
          <div className="flex w-full flex-col justify-center">
            <div className="flex w-full  flex-col justify-center items-center">
              <img
                src="/assets/trust-hospital-logo.png"
                alt="hospital logo"
                className="w-40 cursor-pointer"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
              <h1 className="text-2xl font-medium text-center">Sign In</h1>
              <p className="text-xs mt-2 text-gray-700 ">
                Welcome back! Please complete the form to continue into your
                dashboard for consultation.
              </p>
              {error &&
                error.map((item: any) => (
                  <div key={item?.field}>
                    <Alert severity="error" className="mt-2">
                      {item?.message}
                    </Alert>
                  </div>
                ))}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-8 w-full  flex flex-col"
              >
                <div>
                  <Label label="Username" />
                  <div className="mt-3">
                    <Input
                      autoFocus
                      type="text"
                      id="username"
                      placeholder="Your phone number is your username"
                      {...register("username", { required: true })}
                      aria-invalid={errors.username ? "true" : "false"}
                    />
                  </div>
                  {errors.username && (
                    <p className="text-xs text-red-500 mt-1">
                      *Your patient number is required
                    </p>
                  )}
                </div>

                <div className="mt-4">
                  <div className="flex justify-between">
                    <Label label="Your Password" />
                  </div>
                  <div className="mt-3 w-full relative items-center">
                    <input
                      autoComplete="off"
                      type={!showPassword ? "password" : "text"}
                      id="password"
                      aria-invalid={errors.password ? "true" : "false"}
                      className="h-10 pl-2 pr-10 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-md bg-white"
                      {...register("password", {
                        required: "*Password required",
                      })}
                    />
                    <AiOutlineEyeInvisible
                      className={`absolute text-xl top-2
									right-4 eye cursor-pointer
									${showPassword ? "hidden" : "visible"}`}
                      onClick={handlePasswordToggle}
                    />
                    <AiOutlineEye
                      className={`absolute text-xl top-3
									right-4 eye cursor-pointer
									${showPassword ? "visible" : "hidden"}`}
                      onClick={handlePasswordToggle}
                    />
                  </div>
                  <p className="my-2 text-sm text-red-600">
                    {errors.password && errors.password.message}
                  </p>
                </div>

                <button className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md">
                  {"Sign In"}
                </button>
              </form>
              <p className="text-xs text-center mt-6 underline">
                <Link to="/forgot_password">Forgot your password?</Link>
              </p>
            </div>
            <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
              <p className="text-xs text-center">
                Don’t have an account?{" "}
                <span className="underline">
                  <Link to="/signup">Sign up</Link>
                </span>
              </p>
            </div>
          </div>
          <div className="w-full flex-col hidden md:flex items-center">
            <img
              src={"/assets/newuser-signup.svg"}
              alt="new user sign up"
              width={400}
            />
          </div>
        </div>
        <LoadingModal
          open={open}
          handleClose={handleClose}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}
