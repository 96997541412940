import { Popover } from "@mui/material";
import React from "react";
import { BsImage } from "react-icons/bs";
import { MdArrowBack } from "react-icons/md";
import { RiArrowDownSLine, RiSearchLine } from "react-icons/ri";
import { RxCalendar } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { PrescriptionAPI } from "../middleware";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { convertUTCDatetoLocalDateWithDay, SignUserOut } from "../utils";
import { PreviousPrescriptions } from "../utils/dummydata";
import { Prescription } from "../utils/types";
import MobileSearchbar from "../components/MobileSearchbar";
import Loader from "../components/Loader";

const Prescriptions: React.FC = () => {
  const pathname = window.location.pathname;
  const { isSignedIn, first_name, user } = useReduxSelector(
    (state) => state.user,
  );
  const { access_token, corporateHealthFacility } = user;
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const [view, setView] = React.useState<string>("Active Prescriptions");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [filteredPrescriptions, setFilteredPrescriptions] = React.useState<
    Prescription[]
  >([]);
  const [activePrescriptions, setActivePrescriptions] = React.useState<
    Prescription[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [prescriptionInfo, setPrescriptionInfo] = React.useState<Prescription>({
    prescriptionDate: "",
    prescriptionId: "",
    prescriptionName: "",
    quantity: 0,
    specialistId: "",
    status: "",
    unitCost: 0,
    visitationId: "",
  });
  const [previousPrescriptionsArray, setPreviousPrescriptionsArray] =
    React.useState<Prescription[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const patientId = user.user.patient.patientId;
  const healthFacilityId = corporateHealthFacility.id;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function ascendingOrder(arr: any) {
    arr.sort((a: any, b: any) => {
      let fa = a.specialistId.toLowerCase(),
        fb = b.specialistId.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    if (view === "Active Prescriptions") {
      setFilteredPrescriptions(arr);
    } else if (view === "Previous Prescriptions") {
      setPreviousPrescriptionsArray(arr);
    }
    return arr;
  }

  function descendingOrder(arr: any) {
    arr.sort((a: any, b: any) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    });
    if (view === "Active Prescriptions") {
      setFilteredPrescriptions(arr);
    } else if (view === "Previous Prescriptions") {
      setPreviousPrescriptionsArray(arr);
    }
    return arr;
  }


  React.useLayoutEffect(() => {
    const getAllPrescriptions = async () => {
      setIsLoading(true);
      const patientId = user.user.patient.patientId;
      const healthFacilityId = corporateHealthFacility.id;
      try {
        const request = await PrescriptionAPI.getPrescription(
          access_token,
          healthFacilityId,
          patientId,
        );
        const response = request.data.data as Prescription[];

        // Sort the prescriptions by date
        const sortedPrescriptions = response.sort((a, b) => {
          const dateA = new Date(a.prescriptionDate);
          const dateB = new Date(b.prescriptionDate);
          return dateA.getTime() - dateB.getTime();
        });

        // Separate active and previous prescriptions
        console.log(sortedPrescriptions);
        const currentDate = new Date();
        const activePrescriptions = sortedPrescriptions.filter(prescription => new Date(prescription.prescriptionDate) >= currentDate);
        const previousPrescriptions = sortedPrescriptions.filter(prescription => new Date(prescription.prescriptionDate) < currentDate);

        // Set the active and previous prescriptions in state
        setActivePrescriptions(activePrescriptions);
        setPreviousPrescriptionsArray(previousPrescriptions);

        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        // Handle errors
        if (
          error.response.data.errors.some(
            (e: any) => e.message === "expired or invalid authorization token passed."
          )
        ) {
          SignUserOut(dispatch, navigate, "/signin");
        }
      }
    };
    getAllPrescriptions();
  }, [access_token, corporateHealthFacility.id, dispatch, navigate, user.user.patient.patientId]);

  const search = (
    prescriptions: Prescription[],
    query: string,
  ): Prescription[] => {
    setSearchTerm(query);
    const filtered = prescriptions.filter(
      (prescription: Prescription) =>
        prescription.prescriptionName.toLowerCase().includes(query) ||
        prescription.specialistId.toLowerCase().includes(query) ||
        prescription.visitationId.toLowerCase().includes(query) ||
        prescription.prescriptionId.toLowerCase().includes(query),
    );
    // console.log(filtered, "filtered");
    return filtered;
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const lowerCaseValue = value.toLowerCase();
    setSearchTerm(lowerCaseValue);
    if (view === "Active Prescriptions") {
      const filtered = activePrescriptions.filter((prescription) => {
        return (
          prescription.prescriptionName
            .toLowerCase()
            .includes(lowerCaseValue) ||
          prescription.specialistId.toLowerCase().includes(lowerCaseValue) ||
          prescription.visitationId.toLowerCase().includes(lowerCaseValue) ||
          prescription.prescriptionId.toLowerCase().includes(lowerCaseValue)
        );
      });
      setFilteredPrescriptions(filtered);
    } else {
      const filtered = previousPrescriptionsArray.filter((prescription) => {
        return (
          prescription.prescriptionName
            .toLowerCase()
            .includes(lowerCaseValue) ||
          prescription.specialistId.toLowerCase().includes(lowerCaseValue) ||
          prescription.visitationId.toLowerCase().includes(lowerCaseValue) ||
          prescription.prescriptionId.toLowerCase().includes(lowerCaseValue)
        );
      });
      setFilteredPrescriptions(filtered);
    }
  };

  const getActivePrpsByID = async (investigationId: any) => {
    setIsLoading(true);
    try {
      const request = await PrescriptionAPI.getByID(
        access_token,
        investigationId,
        healthFacilityId,
        patientId,
      );
      const response = request.data.data as Prescription;
      // console.log(response, "response by Id");
      // setPrescriptionInfo(response);
      setIsLoading(false);
      // setView("Prescription Info");
    } catch (error: any) {
      setIsLoading(false);
      // console.log(error.response.data);
    }
  };

  // const date = prescriptionInfo?.requestDate as string;
  // const requestDate = prescriptionInfo?.prescriptionDate;
  // console.log(prescriptionInfo, "info");
  // const givenDate = new Date(requestDate).getMonth();
  // console.log(givenDate, "givenDate");

  // const today = new Date().getMonth();
  // console.log(today, "today");
  // const monthDiff = today - givenDate;
  // console.log(monthDiff);

  return (
    <>
      {isSignedIn === true && pathname === "/prescriptions" ? (
        <Layout
          pathname={pathname}
          view={view}
          search={search}
          searchTerm={searchTerm}
          activePrescriptions={activePrescriptions}
          PreviousPrescriptions={PreviousPrescriptions}
          setFilteredPrescriptions={setFilteredPrescriptions}
          setPreviousPrescriptionsArray={setPreviousPrescriptionsArray}
        >
          <div>
            {/* Mobile search bar */}
            <MobileSearchbar
              searchTerm={searchTerm}
              view={view}
              handleSearch={handleSearch}
            />
            {/* Nav and sort area */}
            <div className="flex items-center justify-between">
              <div className="flex space-x-4 md:space-x-8 items-center text-sm">
                <div
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => setView("Active Prescriptions")}
                >
                  <h3
                    className={`${view === "Active Prescriptions"
                      ? "text-black"
                      : "text-[#888E99]"
                      }`}
                  >
                    Active
                  </h3>
                  <div
                    className={`h-[5px] w-[5px] ${view === "Active Prescriptions"
                      ? "bg-primary-50"
                      : "bg-white"
                      } rounded-full inline`}
                  ></div>
                </div>
                <div
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => setView("Previous Prescriptions")}
                >
                  <h3
                    className={`${view === "Previous Prescriptions"
                      ? "text-black"
                      : "text-[#888E99]"
                      }`}
                  >
                    History
                  </h3>
                  <div
                    className={`h-[5px] w-[5px] ${view === "Previous Prescriptions"
                      ? "bg-primary-50"
                      : "bg-white"
                      } rounded-full inline`}
                  ></div>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <button
                  className="flex space-x-3 items-center"
                  onClick={handleClick}
                >
                  <img src="/assets/filter.svg" alt="filter" width={30} />
                  <p className="underline text-primary-100 text-sm hidden md:block">
                    Sort by
                  </p>
                  <RiArrowDownSLine className="hidden md:block" />
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="flex flex-col rounded-md shadow">
                    <button
                      onClick={() => {
                        if (view === "Active Prescriptions") {
                          ascendingOrder(activePrescriptions);
                        } else if (view === "Previous Prescriptions") {
                          ascendingOrder(PreviousPrescriptions);
                        }
                        handleClose();
                      }}
                      className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
                    >
                      A-Z
                    </button>
                    <button
                      onClick={() => {
                        if (view === "Active Prescriptions") {
                          descendingOrder(activePrescriptions);
                        } else if (view === "Previous Prescriptions") {
                          descendingOrder(PreviousPrescriptions);
                        }
                        handleClose();
                      }}
                      className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
                    >
                      Z-A
                    </button>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          {view === "Active Prescriptions" && (
            <div className="md:fixed h-[78%] mt-3 flex justify-center">
              <div className="overflow-y-scroll relative max-h-[95%] flex flex-col w-full">
                <div className="flex flex-col flex-wrap md:grid items-center justify-center grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mb-3 md:mb-0">
                  {isLoading ? (
                    <div className="h-[70vh] flex flex-row justify-center items-center w-[80vw]">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {searchTerm
                        ? filteredPrescriptions.map((item: any) => (
                          <div
                            className="flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-4 py-4 w-full h-[350px] md:w-[220px] md:h-[320px] xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95"
                            key={item.prescriptionId}
                          >
                            <img
                              src={`/assets/pills-dark-blue.svg`}
                              alt={"prescriptions"}
                              className="w-[150px] md:w-[120px] rounded-full"
                            />
                            <div className="flex flex-col items-start">
                              <p className="text-primary-100 text-xl font-bold mt-2 tracking-wide">
                                {item.specialistId}
                              </p>
                              <p className="text-primary-100 text-xs font-medium mt-1">
                                Patient Name -{" "}
                                {first_name === null ? "N/A" : first_name}
                              </p>
                              <p className="text-primary-100 text-xs font-normal mt-1">
                                Visit Number - {item.visitationId}
                              </p>
                              <div className="text-primary-100 flex items-center space-x-2 mt-1">
                                <RxCalendar className="text-sm" />
                                <p className="text-xs font-normal">
                                  {convertUTCDatetoLocalDateWithDay(
                                    item.prescriptionDate,
                                  )}
                                </p>
                              </div>
                            </div>
                            <button
                              className="bg-primary-100 text-white py-2 px-5 rounded-3xl mt-6 text-sm"
                              onClick={() => {
                                // getActivePrpsByID(item.prescriptionId)
                                setPrescriptionInfo(item);
                                setView("Prescription Info");
                              }}
                            >
                              View Prescription
                            </button>
                          </div>
                        ))
                        : activePrescriptions.length === 0 ? (
                          <div className="flex w-screen">
                            <p className="text-red-500 text-base">
                              No active prescriptions.
                            </p>
                          </div>
                        ) : (
                          activePrescriptions.map((item: any) => (
                            <div
                              className="flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-4 py-4 w-full h-[350px] md:w-[220px] md:h-[320px] xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95"
                              key={item.prescriptionId}
                            >
                              <img
                                src={`/assets/pills-dark-blue.svg`}
                                alt={"prescriptions"}
                                className="w-[150px] md:w-[120px] rounded-full"
                              />
                              <div className="flex flex-col items-start">
                                <p className="text-primary-100 text-xl font-bold mt-2 tracking-wide">
                                  {item.specialistId}
                                </p>
                                <p className="text-primary-100 text-xs font-medium mt-1">
                                  Patient Name - {first_name === null ? "N/A" : first_name}
                                </p>
                                <p className="text-primary-100 text-xs font-normal mt-1">
                                  Visit Number - {item.visitationId}
                                </p>
                                <div className="text-primary-100 flex items-center space-x-2 mt-1">
                                  <RxCalendar className="text-sm" />
                                  <p className="text-xs font-normal">
                                    {convertUTCDatetoLocalDateWithDay(item.prescriptionDate)}
                                  </p>
                                </div>
                              </div>
                              <button
                                className="bg-primary-100 text-white py-2 px-5 rounded-3xl mt-6 text-sm"
                                onClick={() => {
                                  // getActivePrpsByID(item.prescriptionId);
                                  setPrescriptionInfo(item);
                                  setView("Prescription Info");
                                }}
                              >
                                View Prescription
                              </button>
                            </div>
                          ))
                        )}
                    </>
                  )}
                </div>
                {/* {isLoading === false &&
									filteredPrescriptions.length === 0 && (
										<div className="flex w-full">
											<p className="text-red-500 text-base">
												Sorry! We can't find any active prescriptions matching that search input. Please
												try something else.
											</p>
										</div>
									)} */}
              </div>
            </div>
          )}

          {view === "Previous Prescriptions" && (
            <div className="md:fixed h-[78%] mt-3 flex justify-center">
              <div className="overflow-y-scroll relative max-h-[95%] flex flex-col w-full">
                <div className="flex flex-col flex-wrap md:grid items-center justify-center grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mb-3 md:mb-0">
                  {previousPrescriptionsArray.length > 0 &&
                    previousPrescriptionsArray.map((item: any) => (
                      <div
                        className="flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-4 py-4 w-full h-[350px] md:w-[220px] md:h-[320px] xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95"
                        key={item.prescriptionId}
                      >
                        <img
                          src={`/assets/pills-dark-blue.svg`}
                          alt={"prescriptions"}
                          className="w-[150px] md:w-[120px] rounded-full"
                        />
                        <div className="flex flex-col items-start">
                          <p className="text-primary-100 text-xl font-bold mt-2 tracking-wide">
                            {item.specialistId}
                          </p>
                          <p className="text-primary-100 text-xs font-medium mt-1">
                            Patient Name -{" "}
                            {first_name === null ? "N/A" : first_name}
                          </p>
                          <p className="text-primary-100 text-xs font-normal mt-1">
                            Visit Number - {item.visitationId}
                          </p>
                          <div className="text-primary-100 flex items-center space-x-2 mt-1">
                            <RxCalendar className="text-sm" />
                            <p className="text-xs font-normal">
                              {convertUTCDatetoLocalDateWithDay(
                                item.prescriptionDate,
                              )}
                            </p>
                          </div>
                        </div>
                        <button
                          className="bg-primary-100 text-white py-2 px-5 rounded-3xl mt-6 text-sm"
                          onClick={() => {
                            // getActivePrpsByID(item.prescriptionId);
                            setPrescriptionInfo(item);
                            setView("Prescription Info");
                          }}
                        >
                          View Prescription
                        </button>
                      </div>
                    ))}
                </div>
                {previousPrescriptionsArray.length === 0 && (
                  <div className="flex w-full">
                    <p className="text-red-500 text-base">
                      No Previous Prescriptions
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          {view === "Prescription Info" && prescriptionInfo && (
            <div className="text-[#98256B] bg-[#E5C9DA80] rounded-3xl px-5 py-6 mt-3">
              <div className="flex space-x-4 items-center">
                <MdArrowBack
                  className="text-2xl cursor-pointer"
                  onClick={() => setView("Active Prescriptions")}
                />
                <h1 className="text-3xl font-bold">
                  {prescriptionInfo?.prescriptionName}
                </h1>
              </div>
              <p className="mt-6 font-medium">
                Doctor Name -{" "}
                <span className="underline">
                  {prescriptionInfo?.specialistId}
                </span>
              </p>
              <p className="my-2">
                Prescription ID -{prescriptionInfo?.prescriptionId}
              </p>
              <p className="my-2">
                Visitation ID -{prescriptionInfo?.visitationId}
              </p>
              <p className="my-2">Quantity - {prescriptionInfo?.quantity}</p>
              <p className="my-2">Unit Cost -{prescriptionInfo?.unitCost} </p>
              <div className="flex items-center space-x-2">
                <RxCalendar className="" />
                <p className="font-normal">
                  {convertUTCDatetoLocalDateWithDay(
                    prescriptionInfo?.prescriptionDate,
                  )}
                </p>
              </div>

            </div>
          )}
        </Layout>
      ) : (
        <div className="flex flex-col justify-center items-center m-auto w-full h-screen">
          <p>Please sign in to continue</p>
          <button
            className="bg-primary-300 text-white py-3 px-8 mt-4 text-sm rounded-3xl shadow-2xl"
            onClick={() => navigate("/signin")}
          >
            Sign In
          </button>
        </div>
      )}
    </>
  );
};


export default Prescriptions;
