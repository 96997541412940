import React from "react";
import { Box, Modal } from "@mui/material";
import { style } from "../utils/styling";
import { CircleLoader } from "react-spinners";

export const ModalComponent = ({ open, handleClose, children }: any) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style }} className="md:w-[400px] w-10/12">
        {children}
      </Box>
    </Modal>
  );
};

export const LoadingModal = ({ open, handleClose, isLoading }: any) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="flex justify-center items-center h-screen w-full">
        <CircleLoader
          color={"#DBEFFA"}
          loading={isLoading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </Modal>
  );
};
