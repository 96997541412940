import { UseQueryResult } from '@tanstack/react-query';
// import { is } from 'date-fns/locale';
import { DoctorCard } from '../../components/DoctorCard';
import Loader from '../../components/Loader';

export default function DoctorListTab(doctorsObject: UseQueryResult<any, Error>, doctorsArray: any, setDoctorInfo: any, setView: any, setSelectedDoctor: any, isSignedIn: boolean, navigate: any, dispatch: any) {

  // set doctors array to empty array


  if (doctorsObject.isLoading) return <div className="h-[70vh] flex flex-row justify-center items-center w-full">
    <Loader />
  </div>;
  if (!doctorsArray) return <div className="h-[70vh] flex flex-row justify-center items-center w-full">
    <Loader />
  </div>;

  if (doctorsObject.error) return <div>Error: {doctorsObject.error.message}</div>;
  if (!doctorsObject.data) return <div>No data</div>;
  if (doctorsObject.data.length === 0) return <div>No doctors found</div>;
  console.log("DOCTORS ARRAY", doctorsArray);
  console.log("DOCTORS OBJECT", doctorsObject.data);




  return <div className="md:fixed h-[78%] mt-3 flex justify-center">
    <div className="overflow-y-scroll relative max-h-[95%] flex flex-col w-full">
      <div className="flex flex-col flex-wrap md:grid items-center justify-center grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mb-3 md:mb-0">
        {doctorsArray.length > 0 &&
          doctorsArray.map((doctor: any) => (
            <DoctorCard
              doctor={doctor}
              onClick={isSignedIn ? () => {
                setDoctorInfo(doctor);
                dispatch(setSelectedDoctor({
                  id: doctor?.id,
                  name: doctor?.name,
                  profession: doctor?.profession,
                  image: doctor?.image,
                }));
                setView("DoctorProfile");
                // window.location.reload();
              } : () => navigate("signin")}


              selectedDoctor={doctor}
            />

          ))}
        {/* {doctorsArray.length === 0 && (
        <div className="flex w-full md:mt-8">
          <p className="md:mt-2 text-red-500 text-base">
            Sorry! We can't find any doctors matching
            that search input. Please try something
            else.
          </p>
        </div>
      )} */}
      </div>
    </div>
  </div>;
};