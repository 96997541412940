import React from "react";
import { HiLocationMarker } from "react-icons/hi";

type AppointmentCardProps = {
  image?: string;
  specialistName?: string;
  appointmentName?: string;
  appointmentCategoryTypeName?: string;
  appointmentDate?: Date;
  appointStartTimeName?: string;
  branchName?: string;
  status?: string;
};

export const AppointmentCard: React.FC<AppointmentCardProps> = ({
  image,
  specialistName,
  appointmentName,
  appointmentCategoryTypeName,
  appointmentDate,
  appointStartTimeName,
  branchName,
  status,
}) => {
  const formatDate = (date: Date) => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    if (date.toDateString() === tomorrow.toDateString()) {
      return 'Tomorrow';
    }

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const daySuffix = (d: number) => {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    return `${day}${daySuffix(day)} ${month}, ${year}`;
  };

  return (
    <div className="flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-4 py-4 w-full md:w-[220px] xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95 min-h-[250px]">
      {image && <img src={image} alt={appointmentName} className="w-[150px] md:w-[100px] rounded-full" />}
      <div className="flex flex-col visits-start">
        {specialistName && <p className="text-primary-100 text-lg font-bold mt-2 tracking-wide">{specialistName}</p>}
        {appointmentName && <p className="text-primary-100 text-xs font-medium mt-1">{appointmentName}</p>}
        {appointmentCategoryTypeName && (
          <p className="text-primary-100 text-xs font-medium mt-1 underline">
            {appointmentCategoryTypeName}
          </p>
        )}
        {appointmentDate && (
          <div className="text-primary-100 flex items-center space-x-2 mt-1">
            <p className="text-xs font-normal">
              {formatDate(new Date(appointmentDate))}
              {appointStartTimeName && ` at ${appointStartTimeName}`}
            </p>
          </div>
        )}
        <div className="flex flex-row pt-2">
          {branchName && (
            <div className="text-primary-100 flex items-center space-x-1 mt-1 w-full">
              <HiLocationMarker className="text-sm" />
              <p className="text-xs font-normal">{branchName}</p>
            </div>
          )}
          <div className="flex flex-col gap-1 whitespace-nowrap">
            {appointStartTimeName && <button className="bg-blue-900 px-3 py-1 rounded-xl text-white">{appointStartTimeName}</button>}
            {status && <button className="bg-green-900 px-3 py-1 rounded-xl text-white">{status === "assigned" ? "Pending" : "Canceled"}</button>}
          </div>
        </div>
      </div>
    </div>
  );
};
