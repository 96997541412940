import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Input } from "../components/Input";
import Label from "../components/Label";
import { Link, useNavigate } from "react-router-dom";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import {
  NewSignupSuccessful,
  SignupFailed,
  SignupPending,
  SignupSuccessful,
} from "../redux/slice/SignupSlice";
import { UserAPI } from "../middleware";
import { LoadingModal } from "../components/Modal";
import { Alert } from "@mui/material";
import { Inputs } from "../utils/types";

const NewSignUp = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const { isSignedIn } = useReduxSelector((state) => state.user);
  const { isLoading } = useReduxSelector((state) => state.signup);

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<[] | null>(null);

  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    isSignedIn && window.location.assign("/find-a-doctor");
  }, [isSignedIn]);
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setOpen(true);
    dispatch(SignupPending());

    // Destructure the fields from the data
    const {
      streetAddress,
      dateOfBirth,
      email,
      firstName,
      gender,
      lastName,
      username,
      otherName,
      city,
      country,
      digitalAddress,
      regionOrState,
    } = data;

    // Construct the payload
    const payload = {
      address: {
        id: null,
        city: city || null,
        country: country || null,
        digitalAddress: digitalAddress || null,
        latitude: null,
        longitude: null,
        patient: null,
        postalAddress: null,
        regionOrState: regionOrState || null,
        streetAddress: streetAddress || null,
      },
      appClientToken: "8b23a10e-badc-483b-9699-32a5b1d0ec08", // or process.env.REACT_APP_CLIENT_CODE
      contacts: [
        {
          value: username || null,
          type: "mobile",
        },
        {
          value: email || null,
          type: "email",
        },
      ],
      dateOfBirth: dateOfBirth || null,
      firstName: firstName || null,
      lastName: lastName || null,
      otherName: otherName || null,
      patientNumber: null,
      signUpType: "newPatient",
      username: username || null,
      gender: gender || null,
    };

    try {
      const tokenResponse = await UserAPI.gettoken();
      const access_token = tokenResponse.data.access_token;

      console.log("onSubmit: Sending signup request with payload:", payload);

      const request: any = await UserAPI.signup(payload, access_token);
      if (request.status === 200) {
        const response = request.data;

        console.log("onSubmit: Signup response received:", response);
        setOpen(false);
        dispatch(NewSignupSuccessful(response));
        navigate("/payments");
      } else {
        console.log(`onSubmit: NOT 200: ${request}`);
        console.log('Create Invoice Response:', request.errors[0].message);
        setError(request.errors[0].message);
        return request.errors[0];

        // setError("Signup failed. Please try again.");
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors || "Signup failed";
      dispatch(SignupFailed(errorMessage));
      setOpen(false);
      console.error("onSubmit: Error during signup:", errorMessage);
      setError(errorMessage);
    }
  };

  // const [formData, setFormData] = useState({
  //   streetAddress: '123 Test St',
  //   dateOfBirth: '1990-01-01',
  //   email: 'test@example.com',
  //   firstName: 'John',
  //   lastName: 'Doe',
  //   gender: 'Male',
  //   username: '1234567890',
  //   otherName: '',
  //   city: 'Test City',
  //   country: 'Test Country',
  //   digitalAddress: 'Test Digital Address',
  //   regionOrState: 'Test Region',
  // });


  return (
    <>
      {!isSignedIn ? (
        <div className="py-8 md:py-0 px-8 md:px-14 h-full">
          <div className="flex w-full justify-between items-center">
            <div className="flex w-full flex-col justify-center">
              <div className="flex w-full md:w-10/12 flex-col justify-center items-center">
                <img
                  src="/assets/trust-hospital-logo.png"
                  alt="hospital logo"
                  className="w-40 cursor-pointer"
                  onClick={() => navigate("/")}
                />
                <h1 className="text-2xl font-medium mt-4">Sign Up</h1>
              </div>
              <p className="text-xs mt-2 text-gray-700 md:w-10/12">
                New to the hospital? Kindly sign up by filling the form below
                with your correct details and submit. Thanks!
              </p>
              {error &&
                error.map((item: any) => (
                  <div key={item?.field}>
                    <Alert severity="error" className="mt-2">
                      {item?.message}
                    </Alert>
                  </div>
                ))}
              {error && (
                <div>
                  {Array.isArray(error) ? (
                    error.map((item: any, index: any) => (
                      <Alert key={index} severity="error" className="mt-2">
                        {item?.message || 'An error occurred'}
                      </Alert>
                    ))
                  ) : (
                    <Alert severity="error" className="mt-2">
                      {error}
                    </Alert>
                  )}
                </div>
              )}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-8 w-full md:w-10/12 flex flex-col"
              >
                <div>
                  <Label label="First Name" />
                  <div className="mt-3">
                    <Input
                      autoFocus
                      type="text"
                      id="firstName"
                      autoComplete="given-name"
                      {...register("firstName", { required: true })}
                      aria-invalid={errors.firstName ? "true" : "false"}
                    />
                  </div>
                  {errors.firstName && (
                    <p className="text-xs text-red-500 mt-1">
                      *Your first name is required
                    </p>
                  )}
                </div>
                {/* <div className="mt-4">
                  <Label label="Other Name" />
                  <div className="mt-3">
                    <Input
                      type="text"
                      id="otherName"
                      autoComplete="given-name"
                      {...register("otherName")}
                    />
                  </div>
                </div> */}
                <div className="mt-4">
                  <Label label="Last Name" />
                  <div className="mt-3">
                    <Input
                      type="text"
                      id="lastName"
                      autoComplete="given-name"
                      {...register("lastName", { required: true })}
                      aria-invalid={errors.lastName ? "true" : "false"}
                    />
                  </div>
                  {errors.lastName && (
                    <p className="text-xs text-red-500 mt-1">
                      *Your last name is required
                    </p>
                  )}
                </div>
                <div className="mt-4">
                  <Label label="Gender" />
                  <div className="mt-3">
                    <Input
                      type="text"
                      id="gender"
                      {...register("gender", { required: true })}
                      aria-invalid={errors.gender ? "true" : "false"}
                    />
                  </div>
                  {errors.gender && (
                    <p className="text-xs text-red-500 mt-1">
                      *Your gender is required
                    </p>
                  )}
                </div>
                <div className="mt-4">
                  <Label label="Date of Birth" />
                  <div className="mt-3 w-full">
                    <input
                      type="date"
                      id="dateOfBirth"
                      max={new Date().toLocaleDateString("en-ca")}
                      className="h-10 10 px-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 flex w-full sm:text-sm border border-tertiary-200 rounded-md bg-white class-input"
                      {...register("dateOfBirth", { required: true })}
                      aria-invalid={errors.dateOfBirth ? "true" : "false"}
                    />
                  </div>

                </div>
                <div className="mt-4">
                  <Label label="Phone Number" />
                  <div className="mt-3">
                    <Input
                      type="tel"
                      id="phone"
                      {...register("username", { required: true })}
                      aria-invalid={errors.username ? "true" : "false"}
                    />
                  </div>
                  {errors.username && (
                    <p className="text-xs text-red-500 mt-1">
                      *Your phone number is required
                    </p>
                  )}
                </div>
                <div className="mt-4 flex space-x-3 w-full items-start">
                  <div className="w-full">
                    <Label label="Street Address" />
                    <div className="mt-3 w-full">
                      <Input
                        type="text"
                        id="streetAddress"
                        {...register("streetAddress", { required: true })}
                        aria-invalid={errors.streetAddress ? "true" : "false"}
                      />
                    </div>
                    {errors.streetAddress && (
                      <p className="text-xs text-red-500 mt-1">
                        *Your street address is required
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <Label label="Digital Address" />
                    <div className="mt-3 w-full">
                      <Input
                        type="text"
                        id="digitalAddress"
                        placeholder="Postal/Digital"
                        {...register("digitalAddress", { required: true })}
                        aria-invalid={errors.digitalAddress ? "true" : "false"}
                      />
                    </div>
                    {errors.digitalAddress && (
                      <p className="text-xs text-red-500 mt-1">
                        *Your digital address is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4 flex space-x-3 w-full items-start">
                  <div className="w-full">
                    <Label label="Region/State" />
                    <div className="mt-3 w-full">
                      <Input
                        type="text"
                        id="regionOrState"
                        {...register("regionOrState", { required: true })}
                        aria-invalid={errors.regionOrState ? "true" : "false"}
                      />
                    </div>
                    {errors.regionOrState && (
                      <p className="text-xs text-red-500 mt-1">
                        *Your region/state is required
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <Label label="City" />
                    <div className="mt-3 w-full">
                      <Input
                        type="text"
                        id="city"
                        {...register("city", { required: true })}
                        aria-invalid={errors.city ? "true" : "false"}
                      />
                    </div>
                    {errors.city && (
                      <p className="text-xs text-red-500 mt-1">
                        *Your city is required
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <Label label="Country" />
                    <div className="mt-3 w-full">
                      <Input
                        type="text"
                        id="country"
                        {...register("country", { required: true })}
                        aria-invalid={errors.country ? "true" : "false"}
                      />
                    </div>
                    {errors.country && (
                      <p className="text-xs text-red-500 mt-1">
                        *Your country is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <Label label="Email" />
                  <div className="mt-3">
                    <Input
                      type="text"
                      id="email"
                      // value=""
                      aria-invalid={errors.email ? "true" : "false"}
                      {...register("email", {
                        required: "*Your email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                  </div>
                  <p className="text-xs text-red-500 mt-1">
                    {errors.email && errors.email.message}
                  </p>
                </div>
                <button className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md">
                  Sign Up
                </button>
              </form>
              <div className="md:w-10/12">
                <p className="text-xs text-center mt-6">
                  <span className="text-primary-50 underline">
                    <Link to="/signup">Sign Up</Link>
                  </span>{" "}
                  as Existing Patient
                </p>
                <p className="text-xs text-center my-4">
                  Already Have an Account?{" "}
                  <span className="text-primary-50 underline">
                    <Link to="/signin">Sign In</Link>
                  </span>
                </p>
              </div>
            </div>
            <div className="w-full flex-col hidden md:flex">
              <img src={"/assets/newuser-signup.svg"} alt="new user sign up" />
            </div>
          </div>
          <LoadingModal
            open={open}
            handleClose={handleClose}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default NewSignUp;
