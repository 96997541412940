import React, { useEffect } from "react";
import { TbMicroscope, TbClipboardList } from "react-icons/tb";
import { RiSearchLine, RiLogoutCircleLine } from "react-icons/ri";
import { GiPill } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { HiMenuAlt1 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { Drawer } from "@mui/material";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { ModalComponent } from "./Modal";
import { resetSignin } from "../redux/slice/SigninSlice";
import { resetUser } from "../redux/slice/UserSlice";
import { storage } from "../utils";
import { resetSignup } from "../redux/slice/SignupSlice";
import Sidebar from "./Sidebar";

const Layout = ({
  children,
  view,
  search,
  Specialty,
  Doctors,
  Locations,
  Completed,
  allAppointments,
  isCancelled,
  setCompletedArray,
  setFilteredAllAppnts,
  setIsCancelledArray,
  setSpecialtyArray,
  setDoctorsArray,
  setLocationsArray,
  pathname,
  allInvestigations,
  setFilteredInvestigations,
  activePrescriptions,
  setFilteredPrescriptions,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openNavBar, setOpenNavBar] = React.useState(false);
  const { isSignedIn } = useReduxSelector((state) => state.user);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSignOut = () => {
    storage.local.remove("@user-session");
    dispatch(resetUser());
    dispatch(resetSignin());
    dispatch(resetSignup());

    navigate("/");
  };

  useEffect(() => {
    setSearchQuery("");
  }, [view]);

  // start mobile navbar content
  const getList = () => (
    <div className="bg-white w-[250px] pt-2 flex flex-col h-full justify-between">
      {/* Top side navbar content */}
      <div>
        <div className="flex items-center justify-between mb-4">
          <img
            src="/assets/trust-hospital-logo.png"
            alt="Trust hospital logo"
            width={180}
            className="cursor-pointer"
            onClick={() => navigate("/")}
          />
          <AiOutlineClose
            className="text-primary-100 text-2xl mr-2"
            onClick={() => setOpenNavBar(false)}
          />
        </div>
        <div className="space-y-5">
          <Link to={"/find-a-doctor"} onClick={() => {
            localStorage.setItem("view", "Doctors"); // 
          }}>
            <div
              className={`flex items-center space-x-3 ${pathname === "/find-a-doctor"
                ? "bg-[#BBDAF7] hover:opacity-80"
                : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
            >
              <RiSearchLine className="text-xl" />
              <p>Find a doctor</p>
            </div>
          </Link>
          {isSignedIn && (
            <>
              <Link to={"/visits-and-appointments"}>
                <div
                  className={`flex items-center space-x-3 ${pathname === "/visits-and-appointments"
                    ? "bg-[#BBDAF7] hover:opacity-80"
                    : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                    } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
                >
                  <TbClipboardList className="text-xl" />
                  <p>Visits & Appointments</p>
                </div>
              </Link>
              <Link to={"/lab-investigations"}>
                <div
                  className={`flex items-center space-x-3 ${pathname === "/lab-investigations"
                    ? "bg-[#BBDAF7] hover:opacity-80"
                    : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                    } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
                >
                  <TbMicroscope className="text-xl" />
                  <p>Lab Investigations</p>
                </div>
              </Link>
              <Link to={"/prescriptions"}>
                <div
                  className={`flex items-center space-x-3 ${pathname === "/prescriptions"
                    ? "bg-[#BBDAF7] hover:opacity-80"
                    : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                    } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
                >
                  <GiPill className="text-xl" />
                  <p>Prescriptions</p>
                </div>
              </Link>
              {/* <Link to={"/announcements"}>
                <div
                  className={`flex items-center space-x-3 ${pathname === "/announcements"
                    ? "bg-[#BBDAF7] hover:opacity-80"
                    : "hover:bg-slate-50 text-[#8E8E8E] bg-none"
                    } p-3 rounded-3xl font-medium text-sm hover:cursor-pointer`}
                >
                  <HiOutlineSpeakerphone className="text-xl" />
                  <p>Announcements</p>
                </div>
              </Link> */}
            </>
          )}
        </div>
      </div>
      {/* Bottom side navbar content */}
      {isSignedIn && (
        <div className="w-full border border-t py-3">
          {/* <div className="flex items-center text-[#8E8E8E] space-x-3 p-3 rounded-3xl font-medium text-sm hover:cursor-pointer hover:bg-slate-50">
            <TbSettings className="text-2xl" />
            <p>Settings</p>
          </div> */}
          <button onClick={handleOpen} className="flex items-center text-[#8E8E8E] space-x-3 p-3 rounded-3xl font-medium text-sm hover:cursor-pointer hover:bg-[#BBDAF7] w-full transition-colors duration-200">
            <RiLogoutCircleLine className="text-xl" />
            <div >Log Out</div>
          </button>
        </div>
      )}
    </div>
  );
  // end navbar content

  const handleSearch = (e: any) => {
    e.preventDefault();
    const query = e.target.value;
    // setSearchQuery(query);
    // const query = e.target.value;
    // console.log(query, "layout query");
    const lowerCaseValue = query.toLowerCase();
    // console.log(lowerCaseValue, "layout query");
    setSearchQuery(lowerCaseValue);

    switch (view) {
      case "Specialty":
        setSpecialtyArray(search(Specialty, query));
        console.log("SPECIALTY QUERY IN LAYOUT FILE: ", query);
        break;
      case "Doctors":
        setDoctorsArray(search(Doctors, query));
        console.log("DOCTORS QUERY IN LAYOUT FILE: ", query);
        break;
      case "Location":
        setLocationsArray(search(Locations, query));
        console.log("LOCATIONS QUERY IN LAYOUT FILE: ", query);
        break;
      case "Completed":
        setCompletedArray(search(Completed, query));
        break;
      case "Appointments":
        setFilteredAllAppnts(search(allAppointments, query));
        break;
      case "Cancelled":
        setIsCancelledArray(search(isCancelled, query));
        break;
      case "Lab Investigations":
        setFilteredInvestigations(search(allInvestigations, lowerCaseValue));
        break;
      case "Active Prescriptions":
        setFilteredPrescriptions(search(activePrescriptions, lowerCaseValue));
        break;
      // case "Previous Prescriptions":
      // 	setPreviousPrescriptionsArray(search(PreviousPrescriptions, query));
      // 	break;
      default:
        break;
    }
  };
  return (
    <div className="flex h-screen">
      {/* Side Navbar */}
      <Sidebar pathname={pathname} handleOpen={handleOpen} />
      <div className="py-6 md:pt-10 px-5 md:px-10 w-full h-screen overflow-hidden">
        {/* Second Navbar */}
        <div className="w-full flex items-center justify-between">
          <div className="bg-primary-100 flex justify-center px-2 py-1 rounded lg:hidden">
            <HiMenuAlt1
              className="text-2xl text-white "
              onClick={() => setOpenNavBar(true)}
            />
            <Drawer
              open={openNavBar}
              anchor={"left"}
              className="w-full"
              onClose={() => setOpenNavBar(false)}
            >
              {getList()}
            </Drawer>
          </div>

          <div className="lg:w-5/6 relative hidden lg:block">

            <input
              placeholder={`Search ${view === "DoctorProfile" ? "Doctors" : view
                }`}
              className="border rounded-3xl w-full pl-14 py-4 text-sm"
              type={"text"}
              value={searchQuery}
              onChange={handleSearch}
            // onKeyDown={handleSearch}
            />
            <RiSearchLine className="absolute text-lg text-[#8E8E8E] top-[18px] left-5" />
            <button
              className="bg-primary-100 text-white px-10 py-2.5 rounded-3xl text-sm hover:opacity-95 flex justify-center absolute top-[6.5px] right-2"
              // onClick={handleSearch}
              type="button"
            >
              Search
            </button>
          </div>
          <div className="flex items-center space-x-3 lg:w-2/6 md:w-full justify-end">
            {!isSignedIn && (
              <Link to="/signin">
                <button className="border border-primary-100 rounded-3xl shadow-sm px-5 lg:px-8 py-2 text-xs lg:text-sm">
                  Sign in
                </button>
              </Link>
            )}
            {!isSignedIn && (
              <Link to="/signup">
                <button className="bg-primary-100 rounded-3xl shadow-sm px-5 lg:px-8 py-2 text-xs lg:text-sm text-white">
                  Sign up
                </button>
              </Link>
            )}
          </div>
        </div>
        {/* Main content */}
        <div className="py-4 h-full ">{children}</div>
        <ModalComponent open={open} handleClose={handleClose}>
          <h3 className="text-xl font-medium">
            Are you sure you want to log out?
          </h3>
          <p className="text-xs mt-2">
            By logging out, you will be redirected to the home page.
          </p>
          <div className="flex justify-between mt-12 space-x-3">
            <button
              className="py-2 border border-primary-300 hover:bg-slate-100 rounded-md w-full"
              onClick={() => {
                handleClose();
                handleSignOut();
              }}
            >
              Yes
            </button>
            <button
              className="py-2 bg-primary-100 rounded-md w-full text-white hover:opacity-95"
              onClick={() => {
                handleClose();
              }}
            >
              No
            </button>
          </div>
        </ModalComponent>
      </div>
    </div>
  );
};

export default Layout;


// reach out to ui guy
// fix grid in 
// white space by the grids
// show lab imgs
// forgot password
// update sign up 
// forgot passwor on mobile is calling sign up
// forgot password disable link
// investigations on mobile remove hard coded details
// visits and appointemnts doctor name
// order prescriptions by future and past