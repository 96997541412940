import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Avatar, Popover } from "@mui/material";
import { RiArrowDownSLine, RiSearchLine } from "react-icons/ri";
import { Completed, isCancelled } from "../../utils/dummydata";
import { RxCalendar } from "react-icons/rx";
import { useReduxDispatch, useReduxSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { AppointmentsAPI, VisitationAPI } from "../../middleware";
import { Appointments } from "../../utils/types";
import { convertUTCDatetoLocalDateWithDay, SignUserOut } from "../../utils";
import MobileSearchbar from "../../components/MobileSearchbar";
import { ascendingOrder } from "../../utils/helper";
import CancelledAppointmentsTab from "./CancelledAppointmentsTab";
import CompletedTab from "./CompletedTab";
import CurrentAppointmentsTab from "./CurrentAppointmentsTab";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
// import CompletedTab from "./CompletedTab";
// import CancelledAppointmentsTab from "./CancelledAppointmentsTab";
// import CurrentAppointmentsTab from "./CurrentAppointmentsTab";
// import CompletedTab from "./CompletedTab";
// import CurrentAppointmentsTab from "./CurrentAppointmentsTab";
// import CancelledAppointmentsTab from "./CancelledAppointmentsTab";

const VisitsAndAppointments = () => {
  const { isSignedIn, user } = useReduxSelector((state) => state.user);
  const { access_token, corporateHealthFacility } = user;
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  // const [view, setView] = React.useState<string>("Completed");
  // const [view, setView] = useState<string>(() => {
  //   if (typeof window !== "undefined") {
  //     return localStorage.getItem("view") || "Completed";
  //   }
  //   return "Completed";
  // });
  const [view, setView] = useState<string>(() => localStorage.getItem("view") || "Completed");

  useEffect(() => {
    localStorage.setItem("view", view);
  }, [view]);

  useEffect(() => {
    const currentView = localStorage.getItem("view") ?? "Completed";
    console.log({ currentView });
    if (currentView === "Completed" || currentView === "Appointments" || currentView === "Cancelled") {
      setView(currentView);
    } else {
      setView("Completed");
    }
  }, [pathname]);


  const [completedArray, setCompletedArray] = React.useState([]);
  const [currentAppointmentsArray, setCurrentAppointmentsArray] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [filteredAllAppnts, setFilteredAllAppnts] = React.useState([]);
  // const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isCancelledArray, setIsCancelledArray] = React.useState(isCancelled);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const fetchVisitations = async () => VisitationAPI.getPatientVisitations(
    access_token,
    1,
    user.user.patient.patientId,
    1,
    100
  );

  const visitationObject = useQuery({
    queryKey: ["completedAppointments"],
    queryFn: fetchVisitations,

  });

  const { data: any, isLoading, error } = visitationObject;
  console.log("VISITATION OBJECT", visitationObject.data?.data.visitations);



  const startDate = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).format(new Date()).replace(/, /g, ' ').replace(' at ', ' ');


  const fetchCurrentAppointments = async () =>
    AppointmentsAPI.getAppointments(
      access_token,
      1,
      user.user.patient.patientId,
      startDate,
      "01 Mar 2029 00:00:00",
    );


  console.log("FETCH CURRENT APPOINTMENTS", fetchCurrentAppointments);



  let currentAppointemntsObject = useQuery({
    queryKey: ["currentAppointments", pathname],
    queryFn: fetchCurrentAppointments,
  });

  console.log(
    "CURRENT APPOINTMENTS",
    currentAppointemntsObject.data?.data.appointments,
  );

  // ...


  // function descendingOrder(arr: any) {
  //   arr.sort((a: any, b: any) => {
  //     let fa = a.name.toLowerCase(),
  //       fb = b.name.toLowerCase();

  //     if (fa > fb) {
  //       return -1;
  //     }
  //     if (fa < fb) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  //   if (view === "Completed") {
  //     setCompletedArray(arr);
  //   } else if (view === "Appointments") {
  //     setFilteredAllAppnts(arr);
  //   } else if (view === "Cancelled") {
  //     setIsCancelledArray(arr);
  //   }
  //   return arr;
  // }

  // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   const lowerCaseValue = value.toLowerCase();
  //   setSearchTerm(lowerCaseValue);
  //   if (view === "Appointments") {
  //     const filtered = allAppointments.filter((appointment) => {
  //       return appointment.appointmentName
  //         .toLowerCase()
  //         .includes(lowerCaseValue);
  //     });
  //     setFilteredAllAppnts(filtered);
  //   }
  // };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    switch (view) {
      case "Completed":
        // const filteredCompleted = currentAppointemntsObject.data?.data.appointments.filter((appointment: any) =>
        //   appointment.name.toLowerCase().includes(searchTerm)
        // );
        setCompletedArray(
          search(completedArray, searchTerm)
        );
        break;
      case "Appointments":
        // const filteredCurrent = currentAppointemntsObject.data?.data.appointments.filter((appointment: any) =>
        //   appointment.name.toLowerCase().includes(searchTerm)
        // );
        setCurrentAppointmentsArray(
          search(currentAppointmentsArray, searchTerm)
        );
        break;
      case "Cancelled":
        // const filteredCancelled = isCancelled.filter(appointment =>
        //   appointment.name.toLowerCase().includes(searchTerm)
        // );
        setIsCancelledArray(
          search(isCancelledArray, searchTerm)
        );
        break;
      default:
        break;
    }
  };

  function search<T extends { name: string; }>(array: T[], query: string): T[] {
    try {
      if (!query) {
        return array;
      }
      return array.filter(function (element: T) {
        return element.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    } catch (error) {
      console.error('Error occurred during search:', error);
      return [];
    }
  }

  return (
    <>
      {isSignedIn === true && pathname === "/visits-and-appointments" ? (
        <Layout
          pathname={pathname}
          search={search}
          view={view}
          // searchTerm={searchTerm}
          // search={handleSearch}
          Completed={completedArray}
          allAppointments={currentAppointmentsArray}
          // allAppointments={""}
          isCancelled={isCancelledArray}
          setCompletedArray={setCompletedArray}
          setFilteredAllAppnts={setFilteredAllAppnts}
          setIsCancelledArray={setIsCancelledArray}

        >
          <div>
            {/* Mobile search bar */}
            <MobileSearchbar
              searchTerm={searchTerm}
              view={view}
              handleSearch={handleSearch}
            />
            {/* Nav and sort area */}
            <div className="flex items-center justify-between">
              <div className="flex space-x-4 md:space-x-8 items-center text-sm">
                <div
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => setView("Completed")}
                >
                  <h3
                    className={`${view === "Completed" ? "text-black" : "text-[#888E99]"
                      }`}
                  >
                    Completed
                  </h3>
                  <div
                    className={`h-[5px] w-[5px] ${view === "Completed" ? "bg-primary-50" : "bg-white"
                      } rounded-full inline`}
                  ></div>
                </div>
                <div
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => setView("Appointments")}
                >
                  <h3
                    className={`${view === "Appointments" ? "text-black" : "text-[#888E99]"
                      }`}
                  >
                    Appointments
                  </h3>
                  <div
                    className={`h-[5px] w-[5px] ${view === "Appointments" ? "bg-primary-50" : "bg-white"
                      } rounded-full inline`}
                  ></div>
                </div>
                <div
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => setView("Cancelled")}
                >
                  <h3
                    className={`${view === "Cancelled" ? "text-black" : "text-[#888E99]"
                      }`}
                  >
                    Cancelled
                  </h3>
                  <div
                    className={`h-[5px] w-[5px] ${view === "Cancelled" ? "bg-primary-50" : "bg-white"
                      } rounded-full inline`}
                  ></div>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <button
                  className="flex space-x-3 items-center"
                  onClick={handleClick}
                >
                  <img src="/assets/filter.svg" alt="filter" width={30} />
                  <p className="underline text-primary-100 text-sm hidden md:block">
                    Sort by
                  </p>
                  <RiArrowDownSLine className="hidden md:block" />
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="flex flex-col rounded-md shadow">
                    <button
                      onClick={() => {

                        handleClose();
                      }}
                      className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
                    >
                      A-Z
                    </button>
                    <button
                      onClick={() => {
                        // if (view === "Completed") {
                        //   descendingOrder(Completed);
                        // } else if (view === "Appointments") {
                        //   descendingOrder(allAppointments);
                        // } else if (view === "Cancelled") {
                        //   descendingOrder(isCancelled);
                        // }
                        // handleClose();
                      }}
                      className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
                    >
                      Z-A
                    </button>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          <div>
            {view === "Completed" && (
              CompletedTab(
                visitationObject,
              )
            )}

            {view === "Appointments" && (
              CurrentAppointmentsTab(
                currentAppointemntsObject,
              )
              // <CurrentAppointmentsTab currentAppointemntsObject={currentAppointemntsObject} />
            )}

            {view === "Cancelled" && (
              // CancelledAppointmentsTab(
              //   access_token,
              //   user.user.patient.patientId
              // )
              // TODO: ITS SUPPOSED TO BE WHATS ON TOP OF THIS BUT ITS RENDERING MORE HOOKS THAN EXPECTED SO WE'LL HAVE TO MAKE THE CALL ON THIS PAGE DIRECTLY AND PASS THE DATA TO THIS COMPONENT
              CancelledAppointmentsTab(
                currentAppointemntsObject,

              )
            )}

          </div>
        </Layout>
      ) : (
        <div className="flex flex-col justify-center items-center m-auto w-full h-screen">
          <p>Please sign in to continue</p>
          <button
            className="bg-primary-300 text-white py-3 px-8 mt-4 text-sm rounded-3xl shadow-2xl"
            onClick={() => navigate("/signin")}
          >
            Sign In
          </button>
        </div>
      )}
    </>
  );
};

export default VisitsAndAppointments;
