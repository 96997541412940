import React from 'react';
import Loader from '../../components/Loader';

export default function SpecialtyTab(specialtiesObject: any, specialtyArray: any, selectedSpecialty: { id: number; name: string; }, setSelectedSpecialty: any, dispatch: any, isSignedIn: boolean, setView: React.Dispatch<React.SetStateAction<string>>, searchQuery: string) {

    function searchSpecialties(specialties: any[], query: string) {
        console.log("Current Query:", query);
        if (!query) return specialties;
        const result = specialties.filter(specialty => specialty.name.toLowerCase().includes(query.toLowerCase()));
        console.log("Filtered Results:", result);
        return result;
    }
    console.log("Specialties Data:",);
    console.log("Is Loading:", specialtiesObject.isLoading);
    console.log("Error:", specialtiesObject.error);
    const filteredSpecialties = searchSpecialties(specialtyArray, searchQuery);
    if (specialtiesObject.isLoading) return <div className="h-[70vh] flex flex-row justify-center items-center w-full">
        <Loader />
    </div>;
    if (specialtiesObject.error) return <div>Error: {specialtiesObject.error.message}</div>;
    if (!specialtiesObject.data) return <div>No data</div>;
    if (!specialtyArray) return <div>No data</div>;
    if (specialtiesObject.data.length === 0 || specialtiesObject.data == null) return <div className="h-[70vh] flex flex-row justify-center items-center w-full">
        <Loader />
    </div>;

    return <div>
        <div>
            <h1 className="text-lg mt-4 text-primary-100">
                Select a Specialty
            </h1>
        </div>
        <div className="md:fixed h-[70%] mt-3 flex justify-center">
            <div className="justify-start flex flex-col flex-wrap md:grid items-start grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full relative max-h-[95%] mb-3 md:mb-0 overflow-y-scroll pb-2">
                {filteredSpecialties.map((item: any) => (
                    <div
                        className={`flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-8 py-4 w-full h-[250px] md:w-[220px] md:h-[220px] xl:w-[268px] mt-4 xl:mt-0 cursor-pointer hover:bg-primary-50 transition-colors duration-200`}
                        key={item.id}
                        onClick={() => {
                            dispatch(setSelectedSpecialty({
                                id: item.id,
                                name: item.name,

                            }));
                            if (isSignedIn) {
                                setView("Doctors");
                            }
                        }}
                    >
                        <img src={item.image} alt={item.alt} className="w-[100px]" />
                        <p className="text-primary-100 text-lg mt-2 tracking-wide">{item.name}</p>
                    </div>
                ))}
            </div>
        </div>
        {specialtyArray.length === 0 && (
            <div className="flex w-full md:mt-8">
                <p className="md:mt-2 text-red-500 text-base">
                    Sorry! We can't find any specialties matching
                    that search input. Please try something else.
                </p>
            </div>
        )}
    </div>;
}
