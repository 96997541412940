import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../utils/types";
import { set } from 'lodash';

const initialState = {
  isLoading: false,
  isSignedIn: false,
  user: User,
  selectedSpecialty: {
    id: 0,
    name: "",
    image: "",
  },
  selectedDoctor: {
    id: 0,
    name: "",
    profession: "",
    image: "",

  },
  selectedLocation: {
    id: 0,
    name: "",
    address: "",
  },
  first_name: "",
  last_name: "",
  email: "",
  access_token: "",
  activePrescriptions: [],
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserPending: (state) => {
      state.isLoading = true;
    },
    getUserSuccessful: (state, { payload }) => {
      state.isLoading = false;
      state.user = payload;
      state.isSignedIn = true;
    },
    setSelectedSpecialty: (state, { payload }) => {
      state.selectedSpecialty = payload;
    },
    setSelectedDoctor: (state, { payload }) => {
      state.selectedDoctor = payload;
    },
    setSelectedLocation: (state, { payload }) => {
      state.selectedLocation = payload;
    },

    getFirstName: (state, { payload }) => {
      state.first_name = payload;
    },
    getLastName: (state, { payload }) => {
      state.last_name = payload;
    },
    getEmailAddress: (state, { payload }) => {
      state.email = payload;
    },
    getAccessToken: (state, { payload }) => {
      state.access_token = payload;
    },
    allActivePrescriptions: (state, { payload }) => {
      state.activePrescriptions = payload;
      state.isLoading = false;
    },
    resetUser: () => initialState,
  },
});

const { reducer, actions } = UserSlice;

export const {
  getUserPending,
  getUserSuccessful,
  setSelectedSpecialty,
  setSelectedDoctor,
  setSelectedLocation,
  getFirstName,
  getLastName,
  getEmailAddress,
  getAccessToken,
  allActivePrescriptions,
  resetUser,
} = actions;
export default reducer;
