import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import NewSignUp from "./pages/newsignup";
import SignUp from "./pages/signup";
import { SignIn } from "./pages/signin";
import ForgotPassword from "./pages/forgot_password";
import Payments from "./pages/payment";
import FindADoctor from "./pages/find-a-doctor/find-a-doctor";
import VisitsAndAppointments from "./pages/visits_and_appointments/visits_and_appointments";
import LabInvestigations from "./pages/lab_investigations";
import Prescriptions from "./pages/prescriptions";
import Announcements from "./pages/announcements";
import DoctorProfile from "./pages/find-a-doctor/doctor_profile";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import VerifyOTP from "./pages/verify-otp";
import SelectOTPPhone from "./pages/select-otp-phone-number";
import OTPInputScreen from "./pages/otp-input-screen";
import SetNewPasswordScreen from "./pages/set-new-password";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      refetchOnReconnect: "always",
      staleTime: Infinity,
    }
  },
});

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/newsignup" element={<NewSignUp />} />
          <Route path="/doctor-profile" element={<DoctorProfile />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/verify-otp" element={<VerifyOTP />} />
          <Route path="/select-otp-phone-number" element={<SelectOTPPhone />} />
          <Route path="/otp-input" element={<OTPInputScreen />} />
          <Route path="/set-new-password" element={<SetNewPasswordScreen />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/find-a-doctor" element={<FindADoctor />} />
          <Route
            path="/visits-and-appointments"
            element={<VisitsAndAppointments />}
          />
          <Route path="/lab-investigations" element={<LabInvestigations />} />
          <Route path="/prescriptions" element={<Prescriptions />} />
          <Route path="/announcements" element={<Announcements />} />
        </Routes>
        <ReactQueryDevtools initialIsOpen={true} position="bottom" />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;

// pip install aider-chat
// 
// 0296208
// f00b@r$$

// TODO
// forgot password is stuck on loading on error
// export ANTHROPIC_API_KEY=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
// Listen to SSE and show loading, error or success modal