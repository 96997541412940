import React, { useState, useRef, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BsChatLeftDotsFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";
import { SlLock } from "react-icons/sl";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { SignupFailed, SignupPending } from "../redux/slice/SignupSlice";
import { UserAPI } from "../middleware";
import { LoadingModal } from "../components/Modal";

type Inputs = {
  username?: string;
  userId?: string;
  password?: string;
  confirmPassword?: string;
  patientNumber?: string;
};

const ForgotPassword = () => {
  const { isSignedIn } = useReduxSelector((state) => state.user);
  const { isLoading } = useReduxSelector((state) => state.signup);
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();


  const [view, setView] = useState<string>("initial");
  const [value, setValue] = useState<number | null>(null);
  const [otp, setOtp] = useState<any>("");
  const [timer, setTimer] = useState<any>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [patientNumberInput, setPatientNumberInput] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  const [contacts, setContacts] = useState<Array<{ type: string; value: string; }>>([]);


  const id = useRef<any>(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<Inputs>();

  const startTimer = () => {
    setTimer(60);
  };

  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time: any) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  const handleChange = (e: any) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handlePatientNumberChange = (e: any) => {
    e.preventDefault();
    setPatientNumberInput(e.target.value);
  };

  const handleOtpChange = (otp: any) => {
    setOtp(otp);
  };

  const handleOtp = () => {
    // Handle OTP submission if necessary
  };

  const handlePasswordToggle = () => {
    setShowPassword(showPassword ? false : true);
  };

  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(showConfirmPassword ? false : true);
  };

  const initiateForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setOpen(true);
    dispatch(SignupPending());

    try {
      const response = await UserAPI.ForgotpasswordInitiate(patientNumberInput);
      console.log("ForgotpasswordInitiate response:", response);

      if (response.status === 200) {
        const contacts = response.data[0].contacts;
        setContacts(contacts);
        setView("phonenumber");
        setOpen(false);
      } else {
        throw new Error('Failed to initiate forgot password');
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors || [{ message: "Unknown error" }];
      dispatch(SignupFailed(errorMessage));
      setOpen(false);
      setError(true);
    }
  };


  const sendOtpForResetPassword = async () => {
    setOpen(true);
    dispatch(SignupPending());

    try {
      const response = await UserAPI.ForgotpasswordSendOTP(selectedContact, patientNumberInput);
      console.log("ForgotpasswordSendOTP response:", response);

      if (response.status === 200) {
        setView("otp");
        startTimer();
        setOpen(false);
      } else {
        throw new Error('Failed to send OTP');
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors || [{ message: "Unknown error" }];
      dispatch(SignupFailed(errorMessage));
      setOpen(false);
      setError(true);
    }
  };

  // const validateOtpForResetPassword = async () => {
  //   setOpen(true);
  //   dispatch(SignupPending());

  //   try {
  //     const response = await UserAPI.ForgotpasswordValidateOTP(otp);
  //     console.log("ForgotpasswordValidateOTP response:", response);

  //     if (response.status === 200) {
  //       const userId = response.data.data.user.id;
  //       setUserId(userId);
  //       setView("setpassword");
  //       setOpen(false);
  //     } else {
  //       throw new Error('Failed to validate OTP');
  //     }
  //   } catch (error: any) {
  //     const errorMessage = error?.response?.data?.errors || [{ message: "Unknown error" }];
  //     dispatch(SignupFailed(errorMessage));
  //     setOpen(false);
  //     setError(true);
  //   }
  // };


  const validateOtpForResetPassword = async () => {
    setOpen(true);
    dispatch(SignupPending());

    try {
      const response = await UserAPI.ForgotpasswordValidateOTP(otp);
      console.log("ForgotpasswordValidateOTP response:", response);

      if (response.status === 200) {
        const userId = response.data[0].user.id;
        setUserId(userId);
        setView("setpassword");
        setOpen(false);
      } else {
        throw new Error('Failed to validate OTP');
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors || [{ message: "Unknown error" }];
      dispatch(SignupFailed(errorMessage));
      setOpen(false);
      setError(true);
    }
  };


  const updatePasswordAfterOtp = async (data: Inputs) => {
    setOpen(true);
    dispatch(SignupPending());

    try {
      console.log("Update Password After OTP - Data:", data);
      const response = await UserAPI.ForgotpasswordUpdate(otp, patientNumberInput, userId, data.password, data.confirmPassword);
      console.log("ForgotpasswordUpdate response:", response);

      if (response.status === 200) {
        setOpen(false);
        navigate("/signin");
      } else {
        throw new Error('Failed to update password');
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors || [{ message: "Unknown error" }];
      dispatch(SignupFailed(errorMessage));
      setOpen(false);
      setError(true);
    }
  };


  const onSubmit: SubmitHandler<Inputs> = (data) => {
    updatePasswordAfterOtp(data);
  };

  return (
    <div className="py-8 md:py-0 px-5 md:px-14 h-full">
      <div className="flex w-full justify-between items-start">
        <div className="flex w-full flex-col justify-center items-center md:mt-6">
          <div className="flex w-full flex-col justify-center items-center">
            <img
              src="/assets/trust-hospital-logo.png"
              alt="hospital logo"
              className="w-40 cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          {view === "initial" ? (
            <div className="w-full md:w-10/12">
              <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-10 mt-6">
                <h1 className="text-2xl font-normal tracking-wide text-primary-100 text-center">
                  Forgot Password
                </h1>
                <div className="my-6 flex justify-center">
                  <img src="/assets/key.png" alt="Key" width={100} />
                </div>
                <p className="text-md text-gray-500 text-center w-full  text-sm my-3">
                  To reset, please provide your patient number below
                </p>
                <form onSubmit={initiateForgotPassword}>
                  <div>
                    <input
                      autoFocus
                      required
                      autoComplete="off"
                      placeholder="Patient Number"
                      type="text"
                      id="patientNumber"
                      onChange={handlePatientNumberChange}
                      className="h-10 px-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-md bg-white"
                    />
                    {error && (
                      <p className="my-2 text-sm text-red-600">*required</p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md hover:opacity-90"
                  >
                    Continue
                  </button>
                </form>
              </div>
              <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
                <p className="text-xs text-center">
                  Do you have an account?{" "}
                  <span className="underline text-primary-50">
                    <Link to="/signin">Sign in</Link>
                  </span>
                </p>
              </div>
            </div>
          ) : null}
          {view === "phonenumber" ? (
            <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-10 mt-6 md:w-10/12">
              <div className="flex flex-col items-center">
                <h1 className="text-2xl font-normal tracking-wide text-primary-100">
                  One Time Passcode
                </h1>
                <div className="my-6">
                  <img src="/assets/padlock.png" alt="Padlock" width={130} />
                </div>
                <p className="text-md text-[#202020] text-center w-full md:w-4/5">
                  Select which contact details should we use to Verify your account.
                </p>
              </div>
              <div className="mt-8 w-full flex flex-col">
                {contacts.map((contact, index) => (
                  <button
                    key={index}
                    className={`flex space-x-6 items-center border rounded-xl px-3 py-2 focus:border-2 focus:outline-primary-50 ${selectedContact === contact.value ? 'border-4 border-primary-50' : 'border-primary-200'
                      }`}
                    value={contact.value}
                    onClick={(e) => setSelectedContact(contact.value)}
                  >
                    <div className="bg-secondary-200 p-4 inline-flex rounded-full">
                      <BsChatLeftDotsFill className="text-primary-50" />
                    </div>
                    <div>
                      <p className="text-gray-500 text-sm md:text-base text-left">
                        via SMS:
                      </p>
                      <p className="font-medium text-sm md:text-base">
                        {contact.value}
                      </p>
                    </div>
                  </button>
                ))}
                <button
                  className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md hover:opacity-90"
                  onClick={sendOtpForResetPassword}
                  disabled={!selectedContact}
                >
                  Continue
                </button>
              </div>
            </div>
          ) : null}

          {view === "otp" ? (
            <div className="w-full md:w-10/12">
              <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-10 mt-6  items-center">
                <div className="flex flex-col items-center">
                  <h1 className="text-2xl font-normal tracking-wide text-primary-100">
                    Verify Passcode
                  </h1>
                  <div className="my-6">
                    <img
                      src="/assets/checkmark.png"
                      alt="Check mark"
                      width={100}
                    />
                  </div>
                  <p className="text-sm text-[#202020] text-center w-full">
                    Code has been sent to {selectedContact}
                  </p>
                </div>
                <div className="w-full md:w-8/12 flex justify-center items-center my-6">
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    onSubmit={validateOtpForResetPassword}
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      borderRadius: "6px",
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      outline: "#000",
                      marginRight: "0.5rem",
                    }}
                    containerStyle={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  />
                </div>
                <p className="text-center text-xs">
                  Resend code in{" "}
                  <span className="text-primary-50">{timer} s</span>
                </p>
                <button
                  className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md hover:opacity-90"
                  onClick={validateOtpForResetPassword}
                >
                  Verify
                </button>
              </div>
              <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
                <p className="text-xs text-center">
                  Don’t have an account?{" "}
                  <span className="underline">
                    <Link to="/signup">Sign up</Link>
                  </span>
                </p>
              </div>
            </div>
          ) : null}
          {view === "setpassword" ? (
            <div className="w-full md:w-10/12">
              <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-10 mt-6">
                <h1 className="text-2xl font-normal tracking-wide text-primary-100 text-center">
                  Set Password
                </h1>
                <div className="my-6 flex justify-center">
                  <img src="/assets/key.png" alt="Key" width={100} />
                </div>
                <p className="text-md text-gray-500 text-left w-full md:w-4/5 text-sm my-3">
                  Create Your New Password
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="relative">
                    <SlLock className="absolute top-3 left-3" />
                    <input
                      autoFocus
                      autoComplete="off"
                      placeholder="Password"
                      type={!showPassword ? "password" : "text"}
                      id="password"
                      aria-invalid={errors.password ? "true" : "false"}
                      className="h-10 pl-10 pr-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-md bg-white"
                      {...register("password", {
                        required: "Enter new password",
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters",
                        },
                      })}
                    />
                    <AiOutlineEyeInvisible
                      className={`absolute text-xl top-3
                      right-4 eye cursor-pointer
                      ${showPassword ? "hidden" : "visible"}`}
                      onClick={handlePasswordToggle}
                    />
                    <AiOutlineEye
                      className={`absolute text-xl top-3
                      right-4 eye cursor-pointer
                      ${showPassword ? "visible" : "hidden"}`}
                      onClick={handlePasswordToggle}
                    />
                    <p className="my-2 text-sm text-red-600">
                      {errors.password && errors.password.message}
                    </p>
                  </div>
                  <div className="relative mt-4">
                    <SlLock className="absolute top-3 left-3" />
                    <input
                      autoComplete="off"
                      placeholder="Confirm New Password"
                      type={!showConfirmPassword ? "password" : "text"}
                      id="confirmPassword"
                      aria-invalid={errors.confirmPassword ? "true" : "false"}
                      className="h-10 pl-10 pr-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-md bg-white"
                      {...register("confirmPassword", {
                        required: "Confirm your new password",
                        validate: {
                          matchesPreviousPassword: (value) => {
                            const { password } = getValues();
                            return (
                              password === value || "Passwords should match!"
                            );
                          },
                        },
                      })}
                    />
                    <AiOutlineEyeInvisible
                      className={`absolute text-xl top-3
                      right-4 eye cursor-pointer
                      ${showConfirmPassword ? "hidden" : "visible"}`}
                      onClick={handleConfirmPasswordToggle}
                    />
                    <AiOutlineEye
                      className={`absolute text-xl top-3
                      right-4 eye cursor-pointer
                      ${showConfirmPassword ? "visible" : "hidden"}`}
                      onClick={handleConfirmPasswordToggle}
                    />
                    <p className="my-2 text-sm text-red-600">
                      {errors.confirmPassword && errors.confirmPassword.message}
                    </p>
                  </div>
                  <button
                    className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md hover:opacity-90"
                    type="submit"
                  >
                    Continue
                  </button>
                </form>
              </div>
              <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
                <p className="text-xs text-center">
                  Do you have an account?{" "}
                  <span className="underline text-primary-50">
                    <Link to="/signin">Sign in</Link>
                  </span>
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="w-full flex-col hidden md:flex items-center">
          <img
            src={"/assets/doctor-slanted.svg"}
            alt="doctor"
            width={600}
            className="z-50"
          />
          <img
            src={"/assets/blue_oval.svg"}
            alt="blue oval"
            width={550}
            className="absolute -bottom-20 right-0 z-10"
          />
        </div>
      </div>
      <LoadingModal open={open} handleClose={() => setOpen(false)} isLoading={isLoading} />
    </div>
  );
};

export default ForgotPassword;
