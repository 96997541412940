import { useNavigate } from "react-router-dom";
import { useReduxDispatch } from "../redux/hooks";
import { resetSignin } from "../redux/slice/SigninSlice";
import { resetSignup } from "../redux/slice/SignupSlice";
import { resetUser } from "../redux/slice/UserSlice";

const initialState = {
  status: "idle",
  user: null,
  token: null,
  error: null,
  allAccesses: null,
};

const storageKey = "@user-session";

export const storage = {
  local: {
    get: (key: string): any => {
      if (localStorage) {
        return localStorage.getItem(key)
          ? JSON.parse(localStorage.getItem(key)!)
          : null;
      }

      return false;
    },
    set: (key: string, data: any): any => {
      if (localStorage) {
        return localStorage.setItem(key, JSON.stringify(data));
      }

      return false;
    },
    remove: (key: string): boolean => {
      if (localStorage && localStorage.getItem(key)) {
        localStorage.removeItem(key);
        return true;
      }

      return false;
    },
  },
};

export function SignUserOut(dispatch: any, navigate: any, location: string) {
  dispatch(resetSignin());
  dispatch(resetUser());
  dispatch(resetSignup());

  storage.local.remove(storageKey);
  navigate(location);
}

export function convertUTCDatetoLocalDateWithDay(date: string) {
  const newDate = new Date(date);
  const day = newDate.toLocaleString("en-us", { weekday: "long" });
  const month = newDate.toLocaleString("en-us", { month: "long" });
  const dayOfMonth = newDate.toLocaleString("en-us", { day: "2-digit" });
  // const year = newDate.toLocaleString("en-us", { year: "numeric" });
  // const time = newDate.toLocaleString("en-us", {
  // 	hour: "numeric",
  // 	minute: "numeric",
  // 	hour12: true,
  // });
  return `${day}, ${dayOfMonth} ${month}`;
}
