import React from 'react';

const Loader = () => {
    const loaderStyle = {
        border: '3px solid #FFFFFF',
        borderRadius: '50%',
        borderTop: '3px solid #FFFFFF00',
        width: '20px',
        height: '20px',
        animation: 'spin 0.5s linear infinite',
    };

    const keyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

    return (
        <div style={loaderStyle}>
            <style>{keyframes}</style>
        </div>
    );
};

export default Loader;
