import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Input } from "../components/Input";
import Label from "../components/Label";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { UserAPI } from "../middleware";
import { Alert } from "@mui/material";
import { LoadingModal } from "../components/Modal";
import {
  SignupFailed,
  SignupPending,
  SignupSuccessful,
} from "../redux/slice/SignupSlice";
import { BsChatLeftDotsFill } from "react-icons/bs";

type Inputs = {
  password: string;
  confirmPassword: string;
};

type ErrorItem = {
  message: string;
  field?: string;
};

const SetNewPasswordScreen = () => {
  const { isSignedIn } = useReduxSelector((state) => state.user);
  const { isLoading } = useReduxSelector((state) => state.signup);
  const [timer, setTimer] = React.useState<any>(null);
  const id = React.useRef<any>(null);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<ErrorItem[]>([]);
  const [password, setPassword] = useState<string>("f00b@r$$");
  const [confirmPassword, setConfirmPassword] = useState<string>("f00b@r$$");

  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const location = useLocation();
  const { state } = location;
  const { signUpResponseData, otp, user } = state;
  // const user = signUpResponseData.data[0]?.user;

  const handleClose = () => setOpen(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async () => {
    setOpen(true);
    dispatch(SignupPending());

    try {
      console.log("SIGN UP RESPONSE SET NEW PASSWORD PAGE !!!!: ", signUpResponseData);
      const response = await UserAPI.resetPasswordAfterOTP(
        signUpResponseData,
        otp,
        user,
        password,
        confirmPassword
      );
      console.log("response NUMBER!!!!: ", response);

      if (response.status === 200) {
        setOpen(false);
        navigate("/signin", {
          state: {
            signUpResponseData: signUpResponseData,
          }
        });
      } else {
        throw new Error('Failed to sign up');
      }
    } catch (error: any) {
      const errorMessage: ErrorItem[] = error?.response?.data?.errors || [{ message: "Unknown error" }];
      dispatch(SignupFailed(errorMessage));
      setOpen(false);
      setError(errorMessage);
    }
  };

  return (
    <>
      {!isSignedIn ? (
        <div className="py-8 md:py-0 px-8 md:px-14 h-screen grid grid-cols-1 md:grid-cols-2 gap-6 items-center relative overflow-x-hidden">
          <div className="flex flex-col justify-center z-10">
            <div className="flex justify-center items-center">
              <img
                src="/assets/trust-hospital-logo.png"
                alt="hospital logo"
                className="w-72 cursor-pointer"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6 max-w-xl">
              <h1 className="text-4xl font-medium text-center">Set New Password</h1>
              {error.length > 0 &&
                error.map((item, index) => (
                  <div key={index}>
                    <Alert severity="error" className="mt-2">
                      {item.message}
                    </Alert>
                  </div>
                ))}

              <div className="mt-8 w-full flex flex-col">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  {...register("password", { required: true })}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter new password"
                  className="h-10 px-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-[11px] bg-white"
                />
                {errors.password && <p className="text-red-500">Password is required</p>}

                <label htmlFor="confirmPassword" className="mt-4">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  {...register("confirmPassword", { required: true })}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                  className="h-10 px-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-[11px] bg-white"
                />
                {errors.confirmPassword && <p className="text-red-500">Confirm password is required</p>}

                <button
                  className={`w-full mt-6 h-10 rounded-2xl font-medium text-md ${password && confirmPassword ? 'bg-primary-50 text-white hover:opacity-90' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                  onClick={handleSubmit(onSubmit)}
                  disabled={!password || !confirmPassword}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <div className="h-full w-full ">
            <img
              src={"/assets/manwithbluebg.png"}
              alt="new user sign up"
              className="w-full h-full object-contain absolute left-[400px] 2xl:left-[500px] 3xl:left-[550px]"
            />
          </div>
          <LoadingModal
            open={open}
            handleClose={handleClose}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SetNewPasswordScreen;
