import {
  // useMutation,
  // UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import { access } from "fs";
import React, { useEffect, useState } from "react";
// import { Calendar } from "react-calendar";
// import "react-calendar/dist/Calendar.css";
// import { ImageComponent } from "../../components/image_component";
import { AppointmentsAPI, SpecialistAPI } from "../../middleware";
import { useReduxSelector } from "../../redux/hooks";

// import date fucntions
// import { isToday, isBefore, isAfter } from "date-fns";
import Loader from "../../components/Loader";
import clsx from "clsx";
import { Badge, Box, Button, IconButton, Modal, ToggleButton } from "@mui/material";
import {
  // TiArrowBack,
  TiArrowLeft,
  // TiArrowRight,
  // TiLocation,
  TiLocationOutline,
} from "react-icons/ti";
// import { set } from "lodash";
import { LoadingModal, ModalComponent } from "../../components/Modal";

import ButtonLoader from "../../components/ButtonLoader";
import { DateCalendar, LocalizationProvider, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import dayjs, { Dayjs } from "dayjs";
import { defaultImage } from "../../utils/dummydata";
import { useNavigate } from "react-router-dom";

// interface AppointmentData {
//   access_token: string;
//   healthFacilityId: string;
//   patientId: string;
//   appointmentBody: any; // Replace 'any' with the actual type for appointmentBody
// }

const DoctorProfile = (props: any) => {
  const {
    selectedLocation,
    selectedDoctor,
    setView,
    doctorInfo,
    value,
    onChange,
  } = props;
  const { access_token, user, isSignedIn } = useReduxSelector(
    (state: any) => state.user,
  );
  // const [dateVal, setDateVal] = React.useState<Dayjs | null>(dayjs('2022-04-17'));
  // const [selectedDate, setSelectedDate] = dayjsDate
  const hasAvailableTimes = (date: any) => {
    return availableTimesAndDaysData?.some(
      (d: any) => dayjs(d.date).format("M/D/YYYY") === dayjs(date).format("M/D/YYYY")
    );
  };

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const patientId = user.user.patient.patientId;

  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  // fakeisloading
  // const [isFakeLoading, setIsFakeLoading] = useState(false);
  // const is loading
  const [isLoading, setIsLoading] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  // function to dealy 3 seconds isfakeloading
  // const fakeLoading = () => {
  //     setIsFakeLoading(true);
  //     setTimeout(() => {
  //         setIsFakeLoading(false);
  //     }, 1000);
  // };

  // console.log("SELECTED DOCTOR check2!!!", selectedDoctor);
  // console.log("SELECTED DOCTOR check3!!!", doctorInfo);

  const fetchAvailableTimesAndDays = () =>
    AppointmentsAPI.getAvailableDatesAndTimes(
      access_token,
      1,
      patientId,
      selectedDoctor?.id,
      "null",
      "null",
      "null",
    );

  const availableTimesAndDaysObject = useQuery({
    queryKey: ["availableTimesAndDays", selectedDoctor?.id],
    queryFn: fetchAvailableTimesAndDays,
    enabled: !!selectedDoctor,
  });


  const fetchAppointmentCategoryTypes = () =>
    AppointmentsAPI.getAppointmentCategories(access_token, 1);

  const appointmentCategoryTypesObject = useQuery({
    queryKey: ["appointmentCategoryTypes"],
    queryFn: fetchAppointmentCategoryTypes,
  });

  const appointmentCategoryTypes: any =
    appointmentCategoryTypesObject.data?.data?.data;

  console.log(
    "APPOINTMENT CATEGORY TYPES!!",
    appointmentCategoryTypesObject.data?.data?.data,
  );

  const handleConfirm = async () => {
    // COnvert date time to format 1/30/2024
    const formattedDate = selectedDate?.format("M/D/YYYY");

    console.log("FORMATTED DATE", formattedDate);
    console.log("APPOINTMENT CATEGORY TYPE", selectedAppointmentCategoryType);
    console.log("SELECTED TIME", selectedTime);
    // console.log("SELECTED DOCTOR", selectedDoctor.id);
    console.log("PATIENT ID", patientId);

    setIsLoading(true);



    const appointmentBody = {
      appointmentCategoryTypeId:
        "1",
      appointmentDate: formattedDate,
      appointmentStartTimeId: selectedTime,
      gender: "MALE",
      patientId: patientId,
      specialistId: selectedDoctor?.id,
    };

    try {
      const response = await AppointmentsAPI.createAppointment(
        access_token,
        1,
        patientId,
        appointmentBody,
      );
      console.log("HANDLE CONFIRM RESPONSE CREATE RESPONSE: ", response.data);
      setShowDoneModal(true);
    } catch (error) {
      console.error("APPOINTMENT ERROR", error);
      setShowErrorModal(true);
      // throw new Error("Appointment Error");
    } finally {
      setIsLoading(false);
    }
  };


  const availableTimesAndDaysData =
    availableTimesAndDaysObject.data?.data.availableSlots;

  function getAvailableDays(availableTimesAndDaysData: any) {
    const highlightedDaysMap: any = {};

    // Group dates by month-year
    availableTimesAndDaysData.forEach((slot: any) => {
      const date = dayjs(slot.date, 'ddd, DD MMM YYYY');
      const monthYear = date.format('MM-YYYY');

      if (!highlightedDaysMap[monthYear]) {
        highlightedDaysMap[monthYear] = [];
      }

      highlightedDaysMap[monthYear].push(date.date());
    });

    return highlightedDaysMap;
  }
  // const [highlightedDaysMap, setHighlightedDaysMap] = React.useState<any>({});


  // function fakeFetch(date: Dayjs, { signal }: { signal: AbortSignal; }) {
  //   return new Promise<{ daysToHighlight: number[]; }>((resolve, reject) => {
  //     const timeout = setTimeout(() => {
  //       const daysInMonth = date.daysInMonth();
  //       const daysToHighlight = Array.from({ length: 3 }, () =>
  //         Math.floor(Math.random() * daysInMonth) + 1
  //       ); // Simulate fetching 3 random days in the month

  //       resolve({ daysToHighlight });
  //     }, 500);

  //     signal.onabort = () => {
  //       clearTimeout(timeout);
  //       reject(new DOMException('aborted', 'AbortError'));
  //     };
  //   });
  // }

  function ServerDay(props: PickersDayProps<Dayjs> & { availableDates: any[]; }) {
    const { availableDates = [], day, outsideCurrentMonth, ...other } = props;
    const hasAvailableTimes = availableDates.some(date => dayjs(date).isSame(day, 'date'));

    return (
      <PickersDay
        {...other}
        day={day}
        disabled={!hasAvailableTimes}
        outsideCurrentMonth={outsideCurrentMonth}
      />
    );
  }



  const handleMonthChange = (date: Dayjs) => {
    // Get the month and year in the format MM-YYYY
    const monthYear = date.format('MM-YYYY');

    if (!availableTimesAndDaysData) {
      // Handle the case where availableTimesAndDaysData is not loaded yet
      return;
    }

    // Get the days to highlight for the selected month
    const daysToHighlight = availableTimesAndDaysData
      .filter((slot: any) => {
        // Filter slots for the selected month
        const slotDate = dayjs(slot.date, 'ddd, DD MMM YYYY');
        return slotDate.format('MM-YYYY') === monthYear;
      })
      .map((slot: any) => {
        // Map filtered slots to day of the month
        return dayjs(slot.date, 'ddd, DD MMM YYYY').date();
      });

    // Set the highlighted days for the selected month
    setHighlightedDays(daysToHighlight);
  };






  useEffect(() => {
    setSelectedDate(null); // Reset selected date on mount or when selectedDoctor changes
    setAvailableTimes([]); // Reset available times on mount or when selectedDoctor changes
    console.log("SELECTED DOCTOR USEEFFECT", selectedDoctor);
  }, [selectedDoctor]);

  useEffect(() => {
    const formattedDate = selectedDate?.format("M/D/YYYY");

    console.log("FORMATTED DATE", formattedDate);
    console.log("SELECTED DATE", selectedDate);

    const selectedDateData = availableTimesAndDaysData?.find((d: any) => {
      const cleanDate = dayjs(d.date).format("M/D/YYYY");
      return cleanDate === selectedDate?.format("M/D/YYYY");
    });

    if (selectedDateData) {
      setAvailableTimes(selectedDateData.timeSlots);
    } else {
      setAvailableTimes([]);
    }
  }, [availableTimesAndDaysData, selectedDate]);

  // setSelectedAppointmentCategoryType
  const [selectedAppointmentCategoryType, setSelectedAppointmentCategoryType] =
    useState<any>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // show success or failure message in a modal
  const [showDoneModal, setShowDoneModal] = useState(false);

  console.log("AVAILABLE TIMES AND DAYS DATA!!!", availableTimesAndDaysData);

  // function ServerDay(props: PickersDayProps<Dayjs> & { highlightedDays?: number[]; }) {
  //   const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  //   const isSelected =
  //     !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

  //   return (
  //     <Badge
  //       key={day.toString()}
  //       overlap="circular"
  //       badgeContent={isSelected ? '🌚' : undefined}
  //     >
  //       <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
  //     </Badge>
  //   );
  // }
  // const requestAbortController = React.useRef<AbortController | null>(null);
  // const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState<number[]>([]);

  // useEffect(() => {
  //   if (selectedDoctor) {
  //     setSelectedDate(null); // Reset selected date on doctor change
  //     setAvailableTimes([]); // Reset available times on doctor change
  //   }
  // }, [selectedDoctor]);

  useEffect(() => {
    if (!availableTimesAndDaysData || availableTimesAndDaysData.length === 0) {
      // Handle the case where availableTimesAndDaysData is empty
      return;
    }

    const highlightedDaysMap: any = {};


    // Group dates by month-year
    availableTimesAndDaysData.forEach((slot: any) => {
      const date = dayjs(slot.date, 'ddd, DD MMM YYYY');
      const monthYear = date.format('MM-YYYY');

      if (!highlightedDaysMap[monthYear]) {
        highlightedDaysMap[monthYear] = [];
      }

      highlightedDaysMap[monthYear].push(date.date());
    });

    // Set the initial highlighted days based on the current month
    const currentMonthYear = dayjs().format('MM-YYYY');
    setHighlightedDays(highlightedDaysMap[currentMonthYear] || []);

    // Update highlighted days when the month changes
    // const handleMonthChange = (date: Dayjs) => {
    //   const monthYear = date.format('MM-YYYY');
    //   setHighlightedDays(highlightedDaysMap[monthYear] || []);
    // };

    // Do any initial setup here, e.g., fetch data

    // Clean up any resources, e.g., aborting fetch requests
    return () => {
      // Clean up logic here
    };
  }, [availableTimesAndDaysData]); // Dependency array ensures this effect runs when data changes

  const navigate = useNavigate();




  return (
    <div className="mt-4 font-poppins h-full ">
      <div className=" relative h-full w-full flex flex-col lg:flex-row justify-between font-light ">
        <div className="text-primary-100 lg:w-[45%] ">
          {/* Available Doctor */}
          <div className="flex items-center py-3">
            <h3 className="font-normal text-lg text-[20px]">
              Available Doctor{" "}
            </h3>
          </div>

          <div className="pt-4 pb-7 bg-white w-auto h-auto rounded-[400px] ">
            <div className="bg-gray-200 rounded-[400px] ring-[3px] ring-gray-100 shadow-2xl shadow-[#c0815769] w-fit ml-7">
              {/* <ImageComponent value={doctorInfo?.image} size={"32"} /> */}
              <img
                src={selectedDoctor?.image ?? defaultImage}
                onError={(e) => {
                  e.currentTarget.src = defaultImage; // Fallback if error occurs
                }}
                alt={`${selectedDoctor?.name}`}
                className="w-[150px] md:w-[150px] rounded-full"
              />
            </div>
          </div>

          <div className="mt-3 h-[60%] flex flex-col ">
            <div className="flex space-x-6 items-start ">
              <div className="">
                <h3 className="font-normal text-lg text-[20px]">
                  {selectedDoctor?.name}
                </h3>

                <p className="text-tertiary-400 font-normal text-[18px]">
                  {selectedDoctor?.profession}
                </p>
              </div>

              <button
                className="rounded-3xl bg-primary-100 font-thin text-white text-xs py-2 px-4"
                onClick={() => {
                  setView("Doctors");
                }}
              >
                Change Doctor
              </button>
            </div>

            <div className="space-y-3">
              <h3 className="mt-4 mb-1 font-normal text-[18px]">
                About Doctor
              </h3>
              <p className="text-sm text-[#6B779A] text-justify tracking-wide text-[14px]">
                {selectedDoctor?.about}
              </p>
            </div>

            <div className="flex items-center mt-5 space-x-2 font-normal">
              <TiLocationOutline size={20} />
              <div>
                <h3 className="text-sm pr-3">
                  {selectedLocation?.name === ""
                    ? "No location selected"
                    : selectedLocation?.name}
                </h3>
                <p className="text-sm text-gray-400">
                  {selectedLocation?.address}
                </p>
              </div>
              <button
                className="rounded-3xl bg-primary-100 font-thin text-white text-xs py-2 px-4"
                onClick={() => setView("Location")}
              >
                Change Location
              </button>
            </div>
          </div>
        </div>
        <div className="lg:w-[45%] w-full mt-8 lg:mt-0 ">
          <div className="mt-0 flex flex-col items-center h-full ">
            <div className="pb-3">
              <h3 className="text-primary-100 text-center">Available Times</h3>
            </div>

            <div className="relative w-full pb-0 bg-transparent items-center  h-full overflow-scroll">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  className="border-0 border-transparent shadow-none bg-blue-300 rounded-3xl p-0 h-9"
                  disablePast
                  showDaysOutsideCurrentMonth
                  fixedWeekNumber={4}
                  renderLoading={() => <DayCalendarSkeleton />}
                  value={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setSelectedTime(null);
                  }}
                  onMonthChange={handleMonthChange}
                  slots={{
                    day: (props) => {
                      if (!availableTimesAndDaysData) {
                        return null; // Or handle the loading state
                      }
                      return <ServerDay {...props} availableDates={availableTimesAndDaysData.map((slot: any) => slot.date)} />;
                    },
                  }}
                  slotProps={{
                    day: {
                      highlightedDays,
                    } as any,
                  }}
                />
              </LocalizationProvider>
              <div className="w-full pt-3 flex flex-row justify-center items-center">
                <button
                  onClick={() => {
                    // fakeLoading();
                    setModalContent(appointmentCategoryTypes);
                    // setIsModalOpen(true);
                    // console log all query params being passed
                    console.log(
                      "APPOINTMENT CATEGORY TYPES",
                      appointmentCategoryTypes,
                    );
                    console.log("SELECTED DOCTOR", selectedDoctor?.id);
                    console.log("PATIENT ID", patientId);
                    console.log("SELECTED DATE", selectedDate);
                    console.log("SELECTED TIME", selectedTime);
                    console.log("SELECTED LOCATION", selectedLocation);
                    // setIsModalOpen(true);
                    setShowConfirmModal(true);
                  }}
                  className={clsx(
                    "w-fit self-center bg-primary-50 hover:opacity-70 text-white py-2 px-8 flex z-10 flex-row items-center justify-center rounded-3xl transition-all duration-300 ease-in-out min-w-[210px] text-center min-h-[40px]",
                    !selectedTime &&
                    "cursor-not-allowed bg-primary-50 opacity-30 transition-all duration-300 ease-in-out",
                  )}
                  disabled={!selectedTime}
                >
                  {
                    // isModalOpen ||
                    showConfirmModal ||
                      showDoneModal ||
                      isLoading ? (
                      <ButtonLoader />
                    ) : (
                      "Book Appointment"
                    )}
                </button>
              </div>

              <div className="py-0 w-full h-full overflow-y-scroll">
                <h3 className="text-primary-100 mt-4 mb-1 font-medium text-center">
                  {/* formatted date string to look like 30 Jan, 2020*/}
                  {selectedDate?.format("MMMM D, YYYY")}
                </h3>
                {availableTimesAndDaysObject.isLoading ? (
                  <div className="w-full flex flex-row justify-center items-center h-[250px]">
                    <Loader />
                  </div>
                ) : availableTimesAndDaysObject.error ? (
                  <div className="w-full flex flex-row justify-center items-center h-[250px]">
                    Error: {availableTimesAndDaysObject.error.message}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center ">
                    {availableTimes.length === 0 ? (
                      <div className="text-primary-100 text-sm w-full text-center">
                        No available times for this date.
                      </div>
                    ) : (
                      <div className="grid grid-flow-row grid-cols-3 gap-4 mb-24">
                        {availableTimes.map((time: any, index: any) => (
                          <button
                            key={index}
                            onClick={() => {
                              console.log("Selected Time", time.split(" ")[0]);
                              console.log("Selected Date", selectedDate);
                              setSelectedTime(time.split(" ")[0]);
                            }}
                            className={clsx(
                              "hover:bg-primary-100 hover:text-white rounded-3xl border-2 border-primary-100 py-2 px-4 transition-all duration-300 ease-in-out",
                              selectedTime === time.split(" ")[0] &&
                              "bg-primary-100 text-gray-200 hover:text-white transition-all duration-300 ease-in-out",
                            )}
                          >
                            {time.split(" ")[0]}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* 
              {isModalOpen && (
                <Modal
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      height: 500,
                      scrollbarColor: "#000",
                      overflowY: "scroll",
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                    }}
                    className="md:w-[400px] lg:w-[40vw] w-10/12"
                  >
                    <h3 className="text-center py-2">
                      Select your Appointment Type
                    </h3>
                    {Array.isArray(modalContent) ? (
                      <ul>
                        {modalContent.map((type, index) => (
                          <>
                            <li
                              onClick={() => {
                                setIsModalOpen(false);
                                setSelectedAppointmentCategoryType(type);
                                setShowConfirmModal(true);
                              }}
                              className={clsx(
                                "hover:bg-primary-100 cursor-pointer hover:text-white rounded-3xl py-2 px-4 transition-all duration-200",
                                selectedAppointmentCategoryType === type &&
                                "bg-primary-100 text-gray-200 hover:text-white transition-all duration-300 ease-in-out",
                              )}
                            >
                              <button
                                key={index}
                                className="hover:text-white text-start "
                              >
                                {selectedAppointmentCategoryType === type}{" "}
                                {type.appointmentCatTypeName}
                              </button>
                            </li>
                          </>
                        ))}
                      </ul>
                    ) : (
                      modalContent
                    )}
                  </Box>
                </Modal>
              )} */}

              {showConfirmModal && (
                <Modal
                  open={showConfirmModal}
                  onClose={() => setShowConfirmModal(false)}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      // width: "md:90vw ",
                      // height: 500,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      scrollbarColor: "#000",
                      overflowY: "scroll",
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                    }}
                    className="md:w-[400px] w-10/12"
                  >
                    <div
                      className="flex items-center space-x-2 text-primary-100 text-xl cursor-pointer py-3"
                      onClick={() => {
                        setSelectedAppointmentCategoryType(null);
                        setShowConfirmModal(false);
                        // setIsModalOpen(true);
                      }}
                    >
                      <React.Fragment>
                        <TiArrowLeft className="text-primary-100 text-2xl" />
                      </React.Fragment>
                    </div>

                    <h3 className="text-center">Confirm Appointment</h3>
                    <p className="py-3">
                      You are about to book an appointment with
                      <span className="font-bold">
                        {/* {" "} */}
                        {` ${selectedDoctor?.name} `}{" "}
                      </span>
                      on
                      <span className="font-bold">
                        {` ${selectedDate?.format("M/D/YYYY")} `}
                      </span>
                      at{" "}
                      <span className="font-bold">{` ${selectedTime}? `}</span>
                    </p>
                    <button
                      // onClick={handleConfirm}
                      onClick={() => {
                        localStorage.setItem("view", "Appointments");
                        setShowConfirmModal(false);
                        // book appointment
                        handleConfirm();

                      }}
                      className="bg-primary-100 hover:opacity-70 text-white py-2 px-8 rounded-3xl transition-all duration-300 ease-in-out"
                    >
                      Confirm
                    </button>
                  </Box>
                </Modal>
              )}

              {isLoading && (
                // modal with lodaer
                <LoadingModal
                  open={isLoading}
                  // handleClose={() => setIsLoading(false)}
                  isLoading={isLoading}
                />
              )}

              {showErrorModal && (
                <Modal
                  open={showErrorModal}
                  onClose={() => setShowErrorModal(false)}
                  aria-labelledby="error-modal-title"
                  aria-describedby="error-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      // width: "md:90vw ",
                      // height: 500,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      scrollbarColor: "#000",
                      overflowY: "scroll",
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                    }}
                    className="md:w-[400px] w-10/12"
                  >
                    <h3 className="text-center">Error</h3>
                    <p className="py-3">
                      Error booking appointment. Contact support or try again
                      later
                    </p>
                    <button
                      onClick={() => setShowErrorModal(false)}
                      className="bg-primary-100 hover:opacity-70 text-white py-2 px-8 rounded-3xl transition-all duration-300 ease-in-out"
                    >
                      Close
                    </button>
                  </Box>
                </Modal>
              )}

              {showDoneModal && (
                <Modal
                  open={showDoneModal}
                  onClose={() => setShowDoneModal(false)}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      // width: "md:90vw ",
                      // height: 500,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      scrollbarColor: "#000",
                      overflowY: "scroll",
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                    }}
                    className="md:w-[400px] w-10/12"
                  >
                    <h3 className="text-center">Appointment Booked</h3>
                    <p className="py-3">
                      Your appointment has been successfully booked with
                      <span className="font-bold">
                        {" "}
                        {` ${selectedDoctor?.name} `}{" "}
                      </span>
                      on
                      <span className="font-bold">
                        {` ${selectedDate?.format("M/D/YYYY")} `}
                      </span>
                      successfully
                    </p>
                    <button
                      onClick={() => {
                        localStorage.setItem("view", "Appointments");
                        setShowDoneModal(false);
                        // navigate to visits and appointments
                        navigate("/visits-and-appointments");

                      }}
                      className="bg-primary-100 hover:opacity-70 text-white py-2 px-8 rounded-3xl transition-all duration-300 ease-in-out"
                    >
                      Done
                    </button>
                  </Box>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
