export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};
