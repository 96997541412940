import React from 'react';

const Loader = () => {
  const loaderStyle = {
    border: '5px solid #F3742F',
    borderRadius: '50%',
    borderTop: '5px solid #FFFFFF',
    width: '40px',
    height: '40px',
    animation: 'spin 0.5s linear infinite',
  };

  const keyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div style={loaderStyle}>
      <style>{keyframes}</style>
    </div>
  );
};

export default Loader;
